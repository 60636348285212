import React, { FunctionComponent, ReactElement, useState } from 'react';
import './Button.scss';
import cn from 'classnames';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

export interface ButtonProps {
  primary?: boolean;
  isSearchIcon?: boolean;
  isPlusIcon?: boolean;
  isTransparent?: boolean;
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  size?: 'small' | 'medium' | 'large';
  label: string;
  borderRadius?: string;
  overrideClassNames?: string;
  disabled?: boolean;
  icon?: string;
  isSelected?: boolean;
  hoverStyle?: boolean;
  iconBefore?: boolean;
  onClick?: () => void;
}

export const Button: FunctionComponent<ButtonProps> = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  color,
  borderColor,
  borderRadius,
  isSearchIcon,
  isPlusIcon,
  isTransparent,
  label,
  icon,
  hoverStyle = true,
  overrideClassNames,
  isSelected = false,
  iconBefore = false,
  ...props
}: ButtonProps) => {


  const [isHover, setIsHover] = useState<boolean>(false);

  const {
    RootStore: {
      configuration
    },
  } = useStores() as { RootStore: RootStore };


  const handleHover = (isHover: boolean) => {

    if(!hoverStyle){
      return;
    }

    setIsHover(isHover)
  }
  
  const mode = primary ? 'button--primary' : 'button--secondary';

  let bc = ''
  if(backgroundColor == '' || backgroundColor == null){
    
    if(isSelected){
      bc = configuration.jrny.selected_button_border_colour;
      backgroundColor = configuration.jrny.selected_button_colour;
      color = configuration.jrny.selected_button_text_colour;
    }
    else{


      if(hoverStyle){
        if(isHover){
          bc = configuration.jrny.selected_button_border_colour;
          backgroundColor = configuration.jrny.selected_button_colour;
          color = configuration.jrny.selected_button_text_colour;
        }
        else{
          bc = configuration.jrny.button_border_colour;
          backgroundColor = configuration.jrny.button_colour;
          color = configuration.jrny.button_text_colour;
        }
      }


    }
  }

  if(borderColor != null){
    bc = borderColor;
  }

  if(isTransparent){
    backgroundColor = 'transparent';
  }

  let plusCol = configuration.button_colour;

  let icoPad = {height:'25px', marginLeft: '0px', marginRight: '0px'};

  if(process.env.REACT_APP_BUILD_TARGET === 'widget'){
    if(configuration.spec == '6yk1y'){
      plusCol = '#01447b';
      icoPad = {height:'25px', marginLeft: '0px', marginRight: '0px'};
    }
  }

  let plusIco = null;
  if(isPlusIcon){
     plusIco = <svg
          xmlns='http://www.w3.org/2000/svg'
          fill={'red'}
          viewBox="0 0 50 50"
          style={{ width: 23}}
        >
          <path fill='none' d='M0 0h50v50H0z'/>
          <path fill={plusCol} stroke={plusCol} strokeMiterlimit='10' strokeWidth='4' d='M9 25h32M25 9v32'/>
        </svg>
  }


  let icoBef = null;
  let icoAf =  <span style={icoPad}>
        <img className='button--icon' src={icon} style={{height:'25px', width: '25px', maxWidth: '25px', marginLeft: '10px'}}   />
      </span>
    
  if(iconBefore){

    icoPad.marginLeft = '0px';
    icoPad.marginRight = '10px';
    icoBef = <span style={icoPad}>
    <img className='button--icon' src={icon} style={{height:'25px', width: '25px', maxWidth: '25px'}}   />
  </span>
    icoAf = <></>;

  }


  return (
    <button
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      type='button'
      className={
        overrideClassNames
          ? overrideClassNames
          : cn(
              `button button--${size}`,
              mode,
              { 'button--search': isSearchIcon },
              { 'button--transparent': isTransparent },
              { 'button--plusss': isPlusIcon },
            )
      }
      style={{
        color,
        backgroundColor,
        borderRadius,
        borderColor: bc
      }}
      {...props}
    >
      {icon && (

        <>

        {icoBef}
        <span style={{ position: 'relative' }}>
          <span className='button--label'>{label}</span>
          </span>
      
      {icoAf}
      </>
      )}
      {plusIco}
      {!icon && label}
    </button>
  );
};

export default Button;

import { noop } from 'lodash';
import dayjs from 'dayjs';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Header } from '../Header/Header';
import './BaseLayout.scss';
import { agent } from '../../hooks/useRequest';
import { getCookie, cookie } from '../../services/session'
import { ProgressBar, StepState } from '../ProgressBar/ProgressBar';
import ScrollToTop from '../../ScrollToTop';
import Footer from '../Footer/Footer';
import FooterBlockyHays from '../FooterBlockyHays/FooterBlockyHays';
import { Modal } from '../Modal';
import { useNavigate } from 'react-router-dom';
import { Agent } from '../../hooks/types';
import { SearchBar } from '../../components/SearchBar';
import { pageView } from '../../services/ggle';



interface BaseLayoutProps {
  children: ReactNode;
  searchParams?: any;
  isOverlayOpen?: boolean;
  onSearchButtonClick?: () => void;
  onSearchItemClick?: (type: string) => void;
  progressBar?: StepState[];
  isBookingConfirmation?: boolean;
  isBookingConfirmed? : number;
  isNavigationVisible?: boolean;
  userToken?: string;
  userConfiguration?: any;
  telephone?: string;
  showSearch?: boolean;
  greyMode?: boolean;
}

const BaseLayout: FunctionComponent<BaseLayoutProps> = ({
  onSearchItemClick = noop,
  onSearchButtonClick = noop,
  isOverlayOpen,
  searchParams,
  children,
  progressBar,
  isBookingConfirmation = false,
  isBookingConfirmed = 1,
  isNavigationVisible = true,
  userToken,
  userConfiguration,
  telephone='08004084048',
  showSearch = false,
  greyMode = true,
}: BaseLayoutProps) => {

  const navigate = useNavigate();
  
  const [showTimeout, setSessionTimeout] = useState<boolean>(false); 

  const [telephoneNumber, setContactNumber] = useState<string>(telephone);
  const [callMessage, setCallMessage] = useState<string>('Call to speak to one of our experts');

  const checkSession = () => {
    
    if(sessionStorage.getItem('session_cr')){

      const t = dayjs(sessionStorage.getItem('session_cr'));
      const diff = dayjs().diff(t, 'minutes');
      if(diff > 120){
        return true;
      }
    }
    return false;
  }

  const resumeSearch = () => {

    const lastHol = sessionStorage.getItem('lastHolidayPage');
    sessionStorage.removeItem('session_cr');
    if(lastHol != null){
      window.location.href = lastHol
    }else{
      navigate('/');
    }
  }

  useEffect(() => {


    async function ini() {

      setInterval(() => {
        const x = checkSession()
        if(x){
          setSessionTimeout(true);
        }
      }, 3000)

      const exA = getCookie('agt', true) as Agent;
      if(exA != null){
        setContactNumber(exA.contact_number);
        setCallMessage('Call ' + exA.first_name + ' ' + exA.second_name + ' today.');
      }
      else{
        if(searchParams != null && userToken != null){

          const agt = searchParams.get('agt');
          if(agt != null){
            const agtResult = await agent(userToken, Number(agt));
            if(agtResult.agent.result != null){
  
              setContactNumber(agtResult.agent.result.contact_number);
              setCallMessage('Call ' + agtResult.agent.result.first_name + ' ' + agtResult.agent.result.second_name + ' today.');
              const strAgent = JSON.stringify(agtResult.agent.result);
              cookie('agt', strAgent, false, 30);
            }
          }
        }
      }
    }

    pageView(location.pathname);
    
    ini();




  }, []);


  let searchForm = null;
  if(showSearch){

    let backColour = '#00437a';
    if(userConfiguration.search_style != null){
      backColour = userConfiguration.search_style.background_colour;
    }

    const sfcom = <SearchBar
    searchItemType={''}
    isBoardType={false}
    resultFilters={null}
    showInnnerLabels={false}
    isSearchPage={true}
    onClick={() => {
      // setIsSearchBarOpen(!isSearchBarOpen);
    }}/>

    searchForm = <div style={{backgroundColor: backColour, padding: '1rem 1rem 1.5rem'}}>
    {sfcom}
  </div>
  }


  let footer = <Footer userToken={userToken || ''} userConfiguration={userConfiguration} />;
  if(userConfiguration.spec == '6yk1y'){
    //Hays special footer for them only (at this time)
    footer = <FooterBlockyHays userToken={userToken || ''} userConfiguration={userConfiguration} />;
  }

  if(showTimeout){

    const lastHol = sessionStorage.getItem('lastHolidayPage');
    let lastHolEl = null;
    if(lastHol != null){
      lastHolEl = <>Tap or Click <a href={lastHol}>here</a> to resume your holiday search.<br></br><br></br></>
    }  

    let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
    if(userConfiguration != null){
      logoSrc = userConfiguration.logo_file;
    }

    let timeoutMessage = <><Modal isOpen={true} shouldShowCloseButton={false}>
        <div className={`loader-page-container`}>
          <img className='loader-page-logo' src={logoSrc} height={100} />
  
          <div className='loader-container'>
  
            <div onClick={resumeSearch} style={{padding: '50px 50px 50px 50px'}}>
                It looks like you've been away a while or your session with us has timed out.<br></br><br></br>
    
                Availability and prices can change, so we suggest starting again.<br></br><br></br>

                {lastHolEl}

            </div>
      </div>
  
      </div>
    </Modal></>;

    return (
      <>
        <ScrollToTop />
        <Header
          onSearchItemClick={onSearchItemClick}
          progressBar={progressBar}
          onSearchButtonClick={onSearchButtonClick}
          searchParams={searchParams}
          isBookingConfirmation={isBookingConfirmation}
          isBookingConfirmed={isBookingConfirmed}
          isOverlayOpen={isOverlayOpen}
          isNavigationVisible={isNavigationVisible}
          telephoneNumber={telephoneNumber}
          callMessage={callMessage}
        />

  
        {searchForm}
     

        <div style={{color:userConfiguration.text_colour}} className='content-wrapper'>{timeoutMessage}{children}</div>
        {window.location.pathname !== '/' ? footer : ''}
      </>
      )

  }
  


  let backgroundColour = 'white';
  if(greyMode){
    backgroundColour = '#f6f6f6';
  }
  
  return (
    <div style={{background: backgroundColour}}>
      <ScrollToTop />
      <Header
        onSearchItemClick={onSearchItemClick}
        progressBar={progressBar}
        onSearchButtonClick={onSearchButtonClick}
        searchParams={searchParams}
        isBookingConfirmation={isBookingConfirmation}
        isBookingConfirmed={isBookingConfirmed}
        isOverlayOpen={isOverlayOpen}
        isNavigationVisible={isNavigationVisible}
        telephoneNumber={telephoneNumber}
        callMessage={callMessage}
      />


      {searchForm}
 

      <div style={{color:userConfiguration.text_colour}} className='content-wrapper'>{children}</div>
      {window.location.pathname !== '/' ? footer : ''}
    </div>
  );
};

export default BaseLayout;

import React, { FunctionComponent } from 'react';
import './HolidayDetails.scss';
import { Rating } from '../HolidayCard/Rating';
import tripLogo from '../assets/tripadvisor_logo.svg';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

interface HolidayDetailsTopProps {
  hotelName: string;
  rating: number;
  resortName: string;
  regionName: string;
  destinationName: string;
  tripadvisorRating: number;
}

export const HolidayTopDetails: FunctionComponent<HolidayDetailsTopProps> = ({
  hotelName,
  rating,
  resortName,
  regionName,
  destinationName,
  tripadvisorRating,
}: HolidayDetailsTopProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  

  return (
    <>
      <div className='holiday-details-component-wrapper--top-information'>
        <p className='title'>{hotelName}</p>
        <div style={{color: configuration.text_colour_primary}} className='subtitle'>
          {destinationName}, {regionName}, {resortName}
        </div>
        <div className='ratings-section'>
          <Rating type={'star'} rating={rating} />
          <div className={'trip-logo'}>
            {tripadvisorRating > 0 && (
              <>
                <img className={'logo'} src={tripLogo} alt='Trip logo' />
                <Rating type={'circle'} rating={tripadvisorRating} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

import queryString from 'query-string';
import { FunctionComponent, useEffect, useState } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { noop } from 'lodash';
import './ExtrasPage.scss';
// import CarHire from '../../components/CarHire/CarHire';
import { SelectTransfer } from '../../components/SelectTransfers/SelectTransfer';
// import { SelectTransferDummy } from './SelectTransferDummy';
// import { LuggageDummy } from './LuggageDummy';
import { Luggage } from '../../components/Luggage/Luggage';
import { Basket } from '../../components/Basket/Basket';
import { observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProgressBarDefault } from '../../components/ProgressBar/ProgressBarDefaults';
import { Button } from '../../components/Button';
import { Loader } from '../../components/Loader/Loader';

import {
  getSessionSelections,
  addProduct,
  productCheckAll,
  productTransfers,
  removeProduct,  
  paymentPayMonthly,
  journeyAdd,
  sessionUpdate
} from '../../hooks/useRequest';

// import { ILuggage } from '../../components/Luggage/Interfaces';
import dayjs from 'dayjs';
import { LoadingOverlay } from '../../components/LoadingOverlay/LoadingOverlay';

import { accommodation as getAccommodation } from '../../services/acc';
import { adultCountFromRooms, childAgesFromRooms, childCountFromRooms, roomsFromPaxString } from '../../services/pax';
import { sessionGetSearchParams } from '../../services/search';
import { getBasketDP } from '../../services/basket';

import { Room, Flight, Transfer, Extra, Basket as BasketData, Accommodation, SearchSelectionRoom } from '../../hooks/types';

interface ExtrasPageProps {
  
  onLogin?: () => void;
  openMenu?: () => void;
}

const ExtrasPage: FunctionComponent<ExtrasPageProps> = ({ onLogin = noop, openMenu = noop }) => {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const s = searchParams.get('session');
  const selectedSearchParams = sessionGetSearchParams();

  // if(selectedSearchParams == null){
  //   return null;
  // }

  // const adults = adultCountFromRooms(selectedSearchParams.rooms);
  // const children = childCountFromRooms(selectedSearchParams.rooms);
  // const childrenAges = childAgesFromRooms(selectedSearchParams.rooms);

  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);

  const [extrasData, setExtrasData] = useState<Extra[]>([])

  const [transferData, setTransferData] = useState<Transfer[]>([]);

  const [gotExtras, setExtrasLoaded] = useState<boolean>(false);

  const [everythingLoaded, setEverythingLoaded] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [pricePlan , setPaymentPlan] = useState<any>(null);
  const [sessionId] = useState<string>(s || '');


  const [stepperEnabled, setStepperEnabled] = useState<boolean>(true);

  const [accommodation, setAccommodation] = useState<Accommodation>();

  const [basket, setBasket] = useState<BasketData>();
  const [basketRooms, setBasketRooms] = useState<Room[]>();
  const [basketFlight, setBasketFlight] = useState<Flight>();
  const [basketTransfers, setBasketTransfers] = useState<Transfer | null>(null);

  const [selectedRooms, setSelectedRooms] = useState<SearchSelectionRoom[]>([]);

  const loadBasket = async () => {
    if (sessionId) {
      const basketDp =await getBasketDP(userToken, sessionId);

      if(basketDp == null){
        return null;
      }

      setBasket(basketDp.basket);
      setBasketRooms(basketDp.rooms);
      if(basketDp.flight != null){
        setBasketFlight(basketDp.flight);
      }
     
      setBasketTransfers(basketDp.transfer);
      

      return basketDp;
    }
  };


  useEffect(() => {

    async function ini() {



      const selectionsResult = await getSessionSelections(userToken, sessionId);
      const sels = selectionsResult.session.result.selections;
      const aid = sels.accommodation_id;

      if(aid == null){
        return null;
      }


      const selectedRooms = roomsFromPaxString(sels.rooms) as SearchSelectionRoom[];

      setSelectedRooms(selectedRooms);

      const adults = adultCountFromRooms(selectedRooms);
      const children = childCountFromRooms(selectedRooms);
      const childAges = childAgesFromRooms(selectedRooms);

   



    
      const jse = sessionStorage.getItem('js2');
      if(jse == null){
        journeyAdd(Number(aid), adults, children, selectedRooms.length, '', 'Extras', 2, sessionId || '', sels.trip_type, userToken);
        sessionStorage.setItem('js2', '1');

      }

      await productCheckAll(userToken, sessionId);
      
      const basketDp = await loadBasket()
      
      if(basketDp == null){
        return null;
      }

      if(basketDp.flight == null){
        return null;
      }

      const flight = basketDp.flight;

      if(flight == null){
        return
      }

      const departing = dayjs(flight.departing).format('YYYY-MM-DD HH:mm');
      const returning = dayjs(flight.return_departing).format('YYYY-MM-DD HH:mm');

      setExtrasData(flight.extras);
      

      const transfersResponse = await productTransfers(userToken, sessionId, adults, childAges, departing, returning, Number(aid), flight.arrival_airport.code,
        flight.departure_airport.code, flight.number, flight.return_number);

      if(transfersResponse.transfers.result != null){
        setTransferData(transfersResponse.transfers.result);
      }
      setExtrasLoaded(true);
      updatePricePlan(sessionId, basketDp.basket.price_deposit_diff);

      let providerID = 4;
      for(const x of basketDp.basket.products){
        if(x.product_type.id == 0){
          providerID = x.provider_id;
        }
      }

      const acc = await getAccommodation(userToken, Number(aid), providerID);
      if(acc != null){
        setAccommodation(acc);
      }


    }

    if (sessionId) {
      ini();
    }


  }, []);

  ProgressBarDefault.forEach((progress) => {
    progress.isActive = false;
    progress.isCompleted = false;
  });

  ProgressBarDefault[0].isCompleted = true;
  ProgressBarDefault[1].isActive = true;

  let fadeOut = false;
  if (gotExtras) {
    fadeOut = true;
    setTimeout(() => setEverythingLoaded(true), 1000);
  }

  const onSelectTransfer = async (transfer: Transfer | null | undefined) => {
    // if (!sessionId) {
    //   return null;
    // }
    // const basketResponse = await getBasket(userToken, sessionId);
    // setBasket(basketResponse.basket.result);

    const basketdP = await loadBasket();
    updatePricePlan(sessionId, basketdP?.basket.price_deposit_diff);

    // if(transfer != null){
    //   holiday.transfer = transfer;
    // }
    // else{
    //   holiday.transfer = null;
    // }

    // setHoliday(holiday);
  };

  const onSelectLuggage = async (value:number, direction: number, luggage: Extra) => {

    setStepperEnabled(false);
    if(direction == 1){
      await addProduct(userToken, sessionId, luggage.id, luggage.product_type.id, false);
    }
    else{
      await removeProduct(userToken, sessionId, luggage.id, luggage.product_type.id);
    }           

    const basketdP = await loadBasket();
    updatePricePlan(sessionId, basketdP?.basket.price_deposit_diff);
    setStepperEnabled(true);
  }

  const updatePricePlan  = (sessionId: any, basketPrice: any) => {
    paymentPayMonthly(userToken, 1, sessionId, basketPrice).then((pp) => {
      if(pp == null){
        return null;
      }
      if(pp.basket_payment_plan == null){
        return null;
      }
      if(pp.basket_payment_plan.result == null){
        return null;
      }
      setPaymentPlan(pp.basket_payment_plan.result)
    });
  }


  let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
  if(configuration != null){
    logoSrc = configuration.logo_file;
  }

  if(basket == null){
    return (
      <div className={`loader-page-container ${fadeOut ? 'loader-page-fadeout' : ''}`}>
        <img className='loader-page-logo' src={logoSrc} height={100} />
        <Loader
          gridTemplate='loader-grid-auto'
          disable={{ extras: false, rooms: true, flights: true }}
          loading={{
            extras: everythingLoaded,
          }}
        />
      </div>
    );
  }

  if(accommodation == null){
    return (
      <div className={`loader-page-container ${fadeOut ? 'loader-page-fadeout' : ''}`}>
        <img className='loader-page-logo' src={logoSrc} height={100} />
        <Loader
          gridTemplate='loader-grid-auto'
          disable={{ extras: false, rooms: true, flights: true }}
          loading={{
            extras: everythingLoaded,
          }}
        />
      </div>
    );
  }




  if (!everythingLoaded) {
    return (
      <div className={`loader-page-container ${fadeOut ? 'loader-page-fadeout' : ''}`}>
        <img className='loader-page-logo' src={logoSrc} height={100} />
        <Loader
          gridTemplate='loader-grid-auto'
          disable={{ extras: false, rooms: true, flights: true }}
          loading={{
            extras: everythingLoaded,
          }}
        />
      </div>
    );
  }

  let transferSection = null;
  if (transferData != null) {
    transferSection = (
      <SelectTransfer
        sessionId={sessionId}
        options={transferData}
        selectedTransfer={basketTransfers}
        basketData={basket}
        onClick={(transfer) => {
          if (sessionId) onSelectTransfer(transfer);
        }}
      />
    );
  }

  let bask = null;
  let baskDesk = null;

  if(accommodation == null){
    return null;
  }

  bask = <Basket basketData={basket} pricePlan={pricePlan} selectedFlight={basketFlight || null} selectedRooms={basketRooms || []} selectedTransfer={basketTransfers} sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={selectedRooms} />;
  baskDesk = <Basket basketData={basket}  pricePlan={pricePlan} selectedFlight={basketFlight || null} selectedRooms={basketRooms || []} selectedTransfer={basketTransfers} sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={selectedRooms} >
    <Button
          label={'Passengers'}
          primary={true}
          isTransparent={false}
          onClick={() => {
            setLoading(true);

            if(sessionId != null){
              const so = sessionStorage.getItem('urf' + sessionId);
              if(so != null){ 
                const jso = JSON.parse(so);
                if(jso.steps.indexOf(2) == -1){
                  jso.steps.push(2);
                  sessionStorage.setItem('urf' + sessionId, JSON.stringify(jso))
                  sessionUpdate(userToken, sessionId, 2, 'Extras', '', '', '', jso.type);
                }
              }
            }

            navigate(`/passengers${location.search}`);
          
          }}
          backgroundColor={configuration.button_colour}
          borderColor={configuration.button_colour}
        />
</Basket>

  return (
    <BaseLayout
      progressBar={ProgressBarDefault}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
      isNavigationVisible={false}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='extras-page-container'>
        <div className='extras-page-col extras-page-col-basket extras-page-col-basket-mobile'>
          {bask}
        </div>

        <div className='extras-page-col'>
          {extrasData.length > 0 && (
            <Luggage
              enabled={stepperEnabled}
              onChange={(value: number, direction: number, luggage: Extra) => {
                onSelectLuggage(value, direction, luggage)
              }}
              options={extrasData}
              basketData={basket}
            />
          )}
          <div className='extras-page-divider'></div>
          {transferSection}

          <div className='extras-page-divider'></div>

          {/* Car hire to be develop on in the future leaving a reference to it here */}
          {/* <CarHire {...CarHireDummy} /> */}
          {/* <div className='extras-page-divider'></div> */}

          <div className='extras-page-continue-mobile'>
            <Button
              label={'Passengers'}
              primary={true}
              isTransparent={false}
              borderColor={configuration.button_colour}
              onClick={() => {
                setLoading(true);

                setTimeout(() => {

                  if(sessionId != null){
                    const so = sessionStorage.getItem('urf' + sessionId);
                    if(so != null){ 
                      const jso = JSON.parse(so);
                      if(jso.steps.indexOf(2) == -1){
                        jso.steps.push(2);
                        sessionStorage.setItem('urf' + sessionId, JSON.stringify(jso))
                        sessionUpdate(userToken, sessionId, 2, 'Extras', '', '', '', jso.type);
                      }
                    }
                  }

                  navigate(`/passengers${location.search}`);
                }, 1000);
              }}
              backgroundColor={configuration.button_colour}
            />
          </div>
        </div>
        <div className='extras-page-col extras-page-col-basket'>
          {baskDesk}
        </div>
      </div>

      {loading && <LoadingOverlay />}
    </BaseLayout>
  );
};

export default observer(ExtrasPage);

import { GraphQLClient, gql } from 'graphql-request';

import { stringToGQL } from '../services/pax';

import {
  AvailableAccommodationResult,
  AvailableDestinationResult,
  AvailableRegionResult,
  AvailableResortResult,
  AddressLookupResults,
  AvailableDaysResults,
  AvailableDeparturePointsResult,
  AvailableMonthsResults,
  AvailablePaxResult,
  GetAccommodationOffersByMonthResult,
  GetAvailableBoardsResult,
  GetPaymentInformationResult,
  GetAvailableOffersResult,
  GetAvailableRatingsResult,
  GetAvailableTripRatingsResult,
  GetBasketFlightsResult,
  GetBasketResult,
  GetBasketRoomResult,
  GetBasketRoomsResult, 
  GetBasketTransferResult,
  GetBasketProductsResult, 
  GetBasketPackageProductsResult,
  GetFlightsResult,
  GetOffersByIdResult,
  GetRoomsResult,
  GetTransferResult,
  GetSessionFromBookingResult,
  GetSessionNewResult,
  GetSessionResult,
  GetSessionSelectionsResult,
  RestoreSessionResult,
  PaymentResults,
  bookingFlightResult,
  bookingRoomResult,
  bookingAllRoomResult,
  bookingTransferResult,
  bookingPackageResult,
  bookingPayMadeResult,
  GetBasketApplyDiscountResult,
  GetBaskeRemoveDidscountResult,
  PackageOfferResult,
  PackageFilterResult,
  PackageAvailableOptions,
  PackageByIdResult,
  BasketPackageRoomResult,
  BasketPackageRoomsResult,
  AccommodationResult,
  GetAgentResult,
  SessionUpdateSelectionResult,
} from './types';
import { take } from 'lodash';
import { departuerDate, duration } from '../services/search';

const getClient = (clientToken: string) => {
  const gqlCli = new GraphQLClient(process.env.REACT_APP_GRAPHQL_URL as string, {
    headers: {
      Authorization: `Bearer ${clientToken}`,
    },
  });
  return gqlCli;
}

export async function getAvailableDeparturePoints(
  clientToken: string,
  destinations: number[],
  regions: number[],
  resorts: number[],
  departureDate: string | null,
  duration: number,
  rooms: string,
  departureType: number,
  boards: number[],
  ratings: number[],
  tripRatings: number[],
  tripType: number,
  accommodationId: number | null,
) {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const availableDeparturePoints = await gqlCli.request<AvailableDeparturePointsResult>(gql`
      query ($rooms:[String],$duration:Int,$destinations:[Int],$regions:[Int],$resorts:[Int],$boards:[Int],
        $ratings:[Int],$trip_ratings:[Float],$trip_type:Int,$accommodation_id: Int,$departure_date:String,$departure_date_type:Int){
        available_departure_points(
          rooms: $rooms
          duration: $duration
          destinations: $destinations
          regions: $regions
          resorts: $resorts
          boards: $boards
          ratings: $ratings
          trip_ratings: $trip_ratings
          trip_type: $trip_type
          accommodation_id: $accommodation_id
          departure_date: $departure_date
          departure_date_type: $departure_date_type) {
          result {
            name
            code
            departure_point_region {
              id
              name
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `, {
      rooms: gqlRooms,
      duration: duration,
      boards: boards,
      ratings: ratings,
      trip_ratings: tripRatings,
      departure_date: departureDate,
      departure_date_type : departureType,
      destinations: destinations,
      regions: regions,
      resorts: resorts,
      trip_type: tripType,
      accommodation_id: accommodationId});

  return availableDeparturePoints;
}

// export async function getAvailableDestinationsRegionsResortsAcc(  
//   clientToken: string,
//   searchParam: string,
//   departurePoints: string,
//   departureDate: string,
//   duration: number,
//   rooms: string,
//   departureType: string,
//   boards: string,
//   ratings: string,
//   tripRatings: string,
//   tripType: number,
// ) {

//   const gqlCli = getClient(clientToken);

//   const availableDestinations = await gqlCli.request<AvailableDestRegResAccResult>(gql`
//     query {
//       find_available_destinations_regions_resorts_acc(
//         search_param: "${searchParam}"
//         departure_points: ${departurePoints},
//         duration: ${duration},
//         boards: ${boards}
//         ratings: ${ratings}
//         trip_ratings: ${tripRatings}
//         trip_type: ${tripType}
//         rooms: ${rooms}
//         ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
//         ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
//         ) {
//         result {
//           destinations{
//             id
//             name
//           }
//           regions{
//             id
//             name
//           }
//           resorts{
//             id
//             name
//           }
//           accommodation{
//             id
//             name
//             resort{
//               id
//               name
//             }
//           }
//         }
//         error {
//           ... on Unexpected_Error {
//             message
//           }
//         }
//       }
//     }
//     `);
//   return availableDestinations;
// }

// export async function getAvailableDestinations(  
//   clientToken: string,
//   destination: string,
//   departurePoints: string,
//   departureDate: string,
//   duration: number,
//   rooms: string,
//   departureType: string,
//   boards: string,
//   ratings: string,
//   tripRatings: string,
//   tripType: number,  
// ) {

//   const gqlCli = getClient(clientToken);

//   const availableDestinations = await gqlCli.request<AvailableDestinationsResult>(gql`
//     query {
//       find_available_destinations(
//         destination_name: "${destination}"
//         departure_points: ${departurePoints},
//         duration: ${duration},
//         boards: ${boards}
//         ratings: ${ratings}
//         trip_ratings: ${tripRatings}
//         trip_type: ${tripType}
//         rooms: ${rooms}
//         ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
//         ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
//         ) {
//         result {
//           id
//           name
//         }
//         error {
//           ... on Unexpected_Error {
//             message
//           }
//         }
//       }
//     }
//     `);

//   return availableDestinations;
// }

// export async function getAvailableRegions(
//   clientToken: string,
//   region: string,
//   departurePoints: string,
//   departureDate: string,
//   duration: number,
//   rooms: string,
//   departureType: string,
//   boards: string,
//   ratings: string,
//   tripRatings: string,
//   tripType: number,
// ) {

//   const gqlCli = getClient(clientToken);

//   const availableRegions = await gqlCli.request<AvailableRegionsResult>(gql`
//     query {
//       find_available_regions(
//         region_name: "${region}",
//         departure_points: ${departurePoints},
//         duration: ${duration},
//         boards: ${boards}
//         ratings: ${ratings}
//         trip_ratings: ${tripRatings}
//         trip_type: ${tripType}
//         rooms: ${rooms}
//         ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
//         ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}) {
//         result {
//           id
//           name
//         }
//         error {
//           ... on Unexpected_Error {
//             message
//           }
//         }
//       }
//     }`);
//   return availableRegions;
// }


// export async function getAvailableResorts(
//   clientToken: string,
//   resort: string,
//   departurePoints: string,
//   departureDate: string,
//   duration: number,
//   rooms: string,
//   departureType: string,
//   boards: string,
//   ratings: string,
//   tripRatings: string,
//   tripType: number,
// ) {

//   const gqlCli = getClient(clientToken);

//   const availableResorts = await gqlCli.request<AvailableResortsResult>(gql`
//   query {
//     find_available_resorts(
//       resort_name: "${resort}",
//       departure_points: ${departurePoints},
//       duration: ${duration},
//       boards: ${boards}
//       ratings: ${ratings}
//       trip_ratings: ${tripRatings}
//       trip_type: ${tripType}
//       rooms: ${rooms}
//       ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
//       ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
//       ) {
//       result {
//         id
//         name
//       }
//       error {
//         ... on Unexpected_Error {
//           message
//         }
//       }
//     }
//   }`);
//   return availableResorts;
// }

// export async function getAvailableAccommodation(
//   clientToken: string,
//   accommodationName: string,
//   departurePoints: string,
//   departureDate: string,
//   duration: number,
//   rooms: string,
//   departureType: string,
//   boards: string,
//   ratings: string,
//   tripRatings: string,
//   tripType: number,
// ) {

//   const gqlCli = getClient(clientToken);

//   const availableResorts = await gqlCli.request<AvailableAccommodationResult>(gql`
//   query {
//     find_available_accommodation(
//       accommodation_name: "${accommodationName}",
//       departure_points: ${departurePoints},
//       duration: ${duration},
//       boards: ${boards}
//       ratings: ${ratings}
//       trip_ratings: ${tripRatings}
//       trip_type: ${tripType}
//       rooms: ${rooms}
//       ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
//       ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
//       ) {
//       result {
//         id
//         name
//         resort{
//           id
//           name
//         }
//       }
//       error {
//         ... on Unexpected_Error {
//           message
//         }
//       }
//     }
//   }`);
//   return availableResorts;
// }

export async function availablePax(
  clientToken: string,
  departurePoints: string[],
  duration: number,
  departureDate: string | null,
  departureType: number,
  destinations: number[],
  regions: number[],
  resorts: number[],
  boards: number[],
  ratings: number[],
  tripRatings: number[],
  tripType: number,
  accommodationId: number | null,
) {

  const gqlCli = getClient(clientToken);


  const availablePax = await gqlCli.request<AvailablePaxResult>(gql`
    query available_pax ($duration: Int,$departure_points:[String],$boards:[Int],$ratings:[Int],
      $trip_ratings:[Float],$destinations:[Int],$regions:[Int],$resorts:[Int]$trip_type:Int,$accommodation_id:Int,$departure_date:String,$departure_date_type:Int) {
      available_pax(
      duration: $duration
      departure_points: $departure_points
      boards: $boards
      ratings: $ratings
      trip_ratings: $trip_ratings
      departure_date: $departure_date
      departure_date_type: $departure_date_type
      destinations: $destinations
      regions: $regions
      resorts: $resorts,
      trip_type: $trip_type,
      accommodation_id: $accommodation_id)
      {
        result {
          adults
          children
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `, {
    duration: duration,
    departure_points : departurePoints,
    boards: boards,
    ratings: ratings,
    trip_ratings: tripRatings,
    departure_date: departureDate,
    departure_date_type : departureType,
    resorts: resorts,
    destinations: destinations,
    accommodation_id: accommodationId,
    trip_type: tripType,
  });
  return availablePax;
}

export async function availableDuration(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  departureDate: string | null,
  departureType: number,
  destinations: number[],
  regions: number[],
  resorts: number[],
  boards: number[],
  ratings: number[],
  tripRating: number[],
  tripType: number,
  accommodationId: number | null,
) {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const availableDuration = await gqlCli.request(gql`
    query available_durations ($rooms:[String],$departure_points:[String],$boards:[Int],$ratings:[Int],$trip_ratings:[Float],
      $destinations:[Int],$regions:[Int],$resorts:[Int],$trip_type:Int, $accommodation_id: Int,$departure_date:String,$departure_date_type:Int){
      available_durations(
      rooms: $rooms
      departure_points: $departure_points
      boards: $boards
      ratings: $ratings
      trip_ratings: $trip_ratings
      departure_date: $departure_date
      departure_date_type: $departure_date_type
      destinations: $destinations
      regions: $regions
      resorts: $resorts
      trip_type: $trip_type
      accommodation_id: $accommodation_id)
      {
        result
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `,{
    rooms: gqlRooms,
    departure_points : departurePoints,
    boards: boards,
    ratings: ratings,
    trip_ratings: tripRating,
    departure_date: departureDate,
    departure_date_type : departureType,
    destinations: destinations,
    regions: regions,
    resorts: resorts,
    trip_type: tripType,
    accommodation_id: accommodationId
  });
  return availableDuration;
}

export async function availableDestinations(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  duration: any,
  boards: string,
  ratings: string,
  tripRating: string,
  departureDate: string,
  departureType: string,
  regions: number[],
  resorts: number[],
  tripType: number,
) {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const availableDestinations = await gqlCli.request<AvailableDestinationResult>(gql`
       query ($rooms:[String],$duration: Int,$departure_points:[String],$boards:[Int],$ratings:[Int],$trip_ratings:[Float],$regions:[Int],
        $resorts:[Int],$trip_type:Int,$departure_date:String,$departure_date_type:Int){
        available_destinations(
          rooms: $rooms
          duration: $duration
          departure_points: $departure_points
          boards: $boards
          ratings: $ratings
          trip_ratings: $trip_ratings
          departure_date: $departure_date
          departure_date_type: $departure_date_type
          regions: $regions
          resorts: $resorts
          trip_type: $trip_type
        ) {
          result {
            result
            count
            title
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
      `,{
        rooms: gqlRooms,
        duration: duration,
        departure_points : departurePoints,
        boards: boards,
        ratings: ratings,
        trip_ratings: tripRating,
        departure_date: departureDate,
        departure_date_type : departureType,
        regions: regions,
        resorts: resorts,
        trip_type: tripType,
      });
  return availableDestinations;
}

export async function availableRegions(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  duration: number,
  boards: number[],
  ratings: number[],
  tripRating: number[],
  departureDate: string,
  departureType: string,
  destinations: number[],
  resorts: number[],
  tripType: number,
) {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const availableRegions = await gqlCli.request<AvailableRegionResult>(gql`
       query ($rooms:[String],$duration: Int,$departure_points:[String],$boards:[Int],$ratings:[Int],
        $trip_ratings:[Float],$destinations:[Int],$resorts:[Int],$trip_type:Int,$departure_date:String,$departure_date_type:Int){
        available_regions(
          rooms: $rooms
          duration: $duration
          departure_points: $departure_points
          boards: $boards
          ratings: $ratings
          trip_ratings: $trip_ratings
          departure_date: $departure_date
          departure_date_type: $departure_date_type
          destinations: $destinations
          resorts: $resorts
          trip_type: $trip_type
        ) {
          result {
            result
            count
            title
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
      `,{
        rooms: gqlRooms,
        duration: duration,
        departure_points : departurePoints,
        boards: boards,
        ratings: ratings,
        trip_ratings: tripRating,
        departure_date: departureDate,
        departure_date_type : departureType,
        resorts: resorts,
        destinations: destinations,
        trip_type: tripType,
      });
  return availableRegions;
}

export async function availableResorts(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  duration: number,
  boards: number[],
  ratings: number[],
  tripRatings: number[],
  departureDate: string,
  departureType: string,
  destinations: number[],
  regions: number[],
  tripType: number,
) {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const availableResorts = await gqlCli.request<AvailableResortResult>(gql`
       query ($rooms:[String],$duration: Int,$departure_points:[String],$boards:[Int],$ratings:[Int],$trip_ratings:[Float],
        $destinations:[Int],$regions:[Int],$trip_type:Int,$departure_date:String,$departure_date_type:Int){
        available_resorts(
          rooms: $rooms
          duration: $duration
          departure_points: $departure_points
          boards: $boards
          ratings: $ratings
          trip_ratings: $trip_ratings
          departure_date: $departure_date
          departure_date_type: $departure_date_type
          destinations: $destinations
          regions: $regions
          trip_type: $trip_type
        ) {
          result {
            result
            count
            title
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
      `,{
        rooms: gqlRooms,
        duration: duration,
        departure_points : departurePoints,
        boards: boards,
        ratings: ratings,
        trip_ratings: tripRatings,
        departure_date: departureDate,
        departure_date_type : departureType,
        regions: regions,
        destinations: destinations,
        trip_type: tripType,
      });
  return availableResorts;
}

export async function availableAccommodation(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  duration: number,
  boards: number[],
  ratings: number[],
  tripRatings: number[],
  departureDate: string,
  departureType: string,
  tripType: number,
) {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);
  
  const availableAccommodation = await gqlCli.request<AvailableAccommodationResult>(gql`
       query ($rooms:[String],$duration: Int,$departure_points:[String],$boards:[Int],$ratings:[Int],
        $trip_ratings:[Float],$trip_type:Int,$departure_date:String,$departure_date_type:Int){
        available_accommodation(
          rooms: $rooms
          duration: $duration
          departure_points: $departure_points
          boards: $boards
          ratings: $ratings
          trip_ratings: $trip_ratings
          departure_date: $departure_date
          departure_date_type: $departure_date_type
          trip_type: $trip_type
        ) {
          result {
            result
            count
            title
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
      `,{
        rooms: gqlRooms,
        duration: duration,
        departure_points : departurePoints,
        boards: boards,
        ratings: ratings,
        trip_ratings: tripRatings,
        departure_date: departureDate,
        departure_date_type : departureType,
        trip_type: tripType,
      });
  return availableAccommodation;
}


export async function availableRatings(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  duration: number,
  boards: number[],
  tripRating: number[],
  departureDate: string,
  departureType: string,
  regions: number[],
  resorts: number[],
  destinations: number[],
  tripType: number,
) {

  const gqlCli = getClient(clientToken);
  
  const gqlRooms = stringToGQL(rooms);

  const availableRatings = await gqlCli.request<GetAvailableRatingsResult>(gql`
       query ($rooms:[String],$duration: Int,$departure_points:[String],$boards:[Int],$trip_ratings:[Float]
        $destinations:[Int],$regions:[Int],$resorts:[Int],$trip_type:Int,$departure_date:String,$departure_date_type:Int){
        available_ratings(
          rooms: $rooms
          duration: $duration
          departure_points: $departure_points
          boards: $boards
          trip_ratings: $trip_ratings
          departure_date: $departure_date
          departure_date_type: $departure_date_type
          destinations: $destinations
          regions: $regions
          resorts: $resorts
          trip_type: $trip_type
        ) {
          result {
            result
            count
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
      `,{
        rooms: gqlRooms,
        duration: duration,
        departure_points : departurePoints,
        boards: boards,
        trip_ratings: tripRating,
        departure_date: departureDate,
        departure_date_type : departureType,
        regions: regions,
        destinations: destinations,
        resorts: resorts,
        trip_type: tripType,
      });
  return availableRatings;
}

export async function availableBoards(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  duration: number,
  ratings: number[],
  tripRating: number[],
  departureDate: string,
  departureType: string,
  regions: number[],
  resorts: number[],
  destinations: number[],
  tripType: number,
  accommodationId: number | null,
) {
  {
    const gqlCli = getClient(clientToken);

    const gqlRooms = stringToGQL(rooms);

    const availableBoards = await gqlCli.request<GetAvailableBoardsResult>(gql`
        query ($rooms:[String],$duration: Int,$departure_points:[String],$ratings:[Int],
          $trip_ratings:[Float],$destinations:[Int],$regions:[Int],$resorts:[Int],$trip_type:Int,$accommodation_id:Int,$departure_date:String,$departure_date_type:Int){
          available_boards(
            rooms: $rooms
            duration: $duration
            departure_points: $departure_points
            ratings: $ratings
            trip_ratings: $trip_ratings
            departure_date: $departure_date
            departure_date_type: $departure_date_type
            destinations: $destinations
            regions: $regions
            resorts: $resorts
            trip_type: $trip_type
            accommodation_id: $accommodation_id
          ) {
            result {
              result
              count
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
      `,
      {
        rooms: gqlRooms,
        duration: duration,
        departure_points : departurePoints,
        ratings: ratings,
        trip_ratings: tripRating,
        departure_date: departureDate,
        departure_date_type : departureType,
        regions: regions,
        resorts: resorts,
        destinations: destinations,
        trip_type: tripType,
        accommodation_id: accommodationId
      });
    return availableBoards;
  }
}

export async function availableTripRatings(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  duration: number,
  boards: number[],
  ratings: string,
  departureDate: string,
  departureType: string,
  regions: number[],
  resorts: number[],
  destinations: number[],
  tripType: number,
) {
  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const availableTripRatings = await gqlCli.request<GetAvailableTripRatingsResult>(gql`
    query ($rooms:[String],$duration: Int,$departure_points:[String],$boards:[Int],$ratings:[Int],$destinations:[Int],$regions:[Int],$resorts:[Int],$trip_type:Int,$departure_date:String,$departure_date_type:Int){
      available_trip_ratings(
        rooms: $rooms
        duration: $duration
        departure_points: $departure_points
        boards: $boards
        ratings: $ratings
        departure_date: $departure_date
        departure_date_type: $departure_date_type
        destinations: $destinations
        regions: $regions
        resorts: $resorts
        trip_type: $trip_type
      ) {
        result {
          result
          count
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `,{
    rooms: gqlRooms,
    duration: duration,
    departure_points : departurePoints,
    boards: boards,
    ratings: ratings,
    departure_date: departureDate,
    departure_date_type : departureType,
    destinations: destinations,
    regions: regions,
    resorts: resorts,
    trip_type: tripType,
  });
  return availableTripRatings;
}

export async function availableDays(
  clientToken: string,
  rooms: string,
  month: number,
  year: number,
  departurePoints: string[],
  duration: number,
  destinations: number[],
  regions: number[],
  resorts: number[],
  boards: number[],
  ratings: number[],
  tripRatings: number[],
  tripType: number,
) {
  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const availableDays = await gqlCli.request<AvailableDaysResults>(gql`
    query available_days ($rooms:[String],$duration:Int,$month:Int!,$year:Int!,$departure_points:[String],$destinations:[Int],$regions:[Int],$resorts:[Int],
      $boards:[Int],$ratings:[Int],$trip_ratings:[Float],$trip_type:Int) {
      available_days(
        rooms: $rooms
        duration: $duration
        month: $month,
        year: $year,
        departure_points: $departure_points
        destinations: $destinations
        regions: $regions
        resorts: $resorts
        ratings: $ratings
        trip_ratings: $trip_ratings
        boards: $boards
        trip_type: $trip_type) {
          result
          error {
            ... on Unexpected_Error {
              message
            }
          }
      }
    }
  `,{
    rooms: gqlRooms,
    duration: duration,
    departure_points : departurePoints,
    boards: boards,
    ratings: ratings,
    trip_ratings: tripRatings,
    destinations: destinations,
    regions: regions,
    resorts: resorts,
    trip_type: tripType,
    month: month,
    year: year,
  });
  return availableDays;
}

export async function availableMonths(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  duration: number,
  destinations: number[],
  regions: number[],
  resorts: number[],
  boards: number[],
  ratings: number[],
  tripRatings: number[],
  tripType: number,
) {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const availableMonths = await gqlCli.request<AvailableMonthsResults>(gql`
    query available_months ($rooms:[String],$duration: Int,$departure_points:[String],$boards:[Int],$ratings:[Int],
      $trip_ratings:[Float],$destinations:[Int],$regions:[Int],$resorts:[Int],$trip_type:Int){
    available_months(
      rooms: $rooms
      duration: $duration
      departure_points: $departure_points
      boards: $boards
      ratings: $ratings
      trip_ratings: $trip_ratings
      destinations: $destinations
      regions: $regions
      resorts: $resorts
      trip_type: $trip_type)
      {
      result  {
        enabled
        month
        month_text
        year
      }
      error {
        ... on Unexpected_Error {
          message
        }
      }
    }
  }
  `,{
    rooms: gqlRooms,
    duration: duration,
    departure_points : departurePoints,
    boards: boards,
    ratings: ratings,
    trip_ratings: tripRatings,
    destinations: destinations,
    regions: regions,
    resorts: resorts,
    trip_type: tripType,
  });
  return availableMonths;
}

export async function availableMonthsHoliday(
  clientToken: string,
  rooms: string,
  departurePoints: string[],
  duration: number,
  boards: number[],
  accommodationId: number,
  tripType: number,
) {
  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const availableMonths = await gqlCli.request<AvailableMonthsResults>(gql`
      query available_months ($rooms:[String],$duration:Int,$departure_points:[String],$boards:[Int],$accommodation_id:Int,$trip_type:Int){
      available_months(
        rooms: $rooms
        duration: $duration
        departure_points: $departure_points
        boards: $boards
        accommodation_id: $accommodation_id
        trip_type: $trip_type)
        {
        result  {
          enabled
          month
          month_text
          year
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `,{
      rooms: gqlRooms,
      duration: duration,
      departure_points : departurePoints,
      boards: boards,
      accommodation_id: accommodationId,
      trip_type: tripType,
    });
  return availableMonths;
}

export async function getAddress(clientToken: string,searchTerm: string) {

    const gqlCli = getClient(clientToken);

    const address = await gqlCli.request<AddressLookupResults>(gql`
    query ($search:String) {
      address_lookup(search_term: $search) {
        result {
          addressline1
          addressline2
          addressline3
          addressline4
          posttown
          postcode
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }`,{
      search: searchTerm
    });
    return address;
  
}

export async function offerPaymentPlan( clientToken: string,id: string, rooms: any, desiredDay=1) {

  const gqlCli = getClient(clientToken);
  const gqlRooms = stringToGQL(rooms);

  const paymentPlan = await gqlCli.request<any>(gql`
      query ($id:String!,$desired_day:Int,$rooms:[String]){
        offer_payment_plan(id: $id, desired_day: $desired_day, rooms: $rooms) {
          result {
            id
            desired_day
            departure_date
            outstanding_balance
            plan {
              date_due
              amount
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,{
      id: id,
      desired_day: desiredDay,
      rooms: gqlRooms,
    });
  return paymentPlan;
}

export async function cheapestAccOfferBase(
  clientToken: string,
  tripType: number ,
  accommodationId: number,
  duration: number, 
  rooms: string,
)  {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const cheapeestAccOffID = await gqlCli.request<any>(gql`
    query {
      offers(accommodation_id: $accommodation_id,take: 1, trip_type: $trip_type, duration: $duration, rooms: $rooms)
        {
        result {
          id
          adults
          children
          duration
          flights{
            departing
          }
        }
        count
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `,{
      rooms: gqlRooms,
      duration: duration,
      accommodation_id: accommodationId,
      trip_type: tripType,
    });
  return cheapeestAccOffID;
}

export async function offers(
  clientToken: string,
  tripType: number,
  departurePoints: string[],
  destinations: number[],
  regions: number[],
  resorts: number[],
  departureDate: string | null,
  duration: number,
  rooms: string,
  index: number,
  ratings: number[],
  boards: number[],
  departureType: number,
  tripRatings: number[],
  favAcc: number[],
  sortIndex: number,
  accommodationId?: number,
  cAccList?: number,
  take=25,
){

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  if(departureDate == ''){
    departureDate = null;
  }

  const availableOffers = await gqlCli.request<GetAvailableOffersResult>(gql`
    query ($rooms:[String],$duration:Int,$departure_points:[String],$boards:[Int],$ratings:[Int],$trip_ratings:[Float],
      $destinations:[Int],$regions:[Int],$resorts:[Int],$trip_type:Int, $accommodation_id: Int,$departure_date:String,$departure_date_type:Int,
      $client_list_id: Int, $fav_accommodations:[Int],$start_index:Int,$sort:Int,$take:Int){
      offers (
        rooms: $rooms
        duration: $duration
        departure_points: $departure_points
        destinations: $destinations
        regions: $regions
        resorts: $resorts
        ratings: $ratings
        trip_ratings: $trip_ratings
        boards: $boards
        departure_date: $departure_date
        departure_date_type: $departure_date_type
        client_sort_list_id: $client_list_id
        trip_type: $trip_type
        fav_accommodations: $fav_accommodations
        accommodation_id: $accommodation_id
        start_index: $start_index
        sort: $sort
        take: $take)
        {
        result {
          found_in_list
          id
          price
          price_per_person
          deposit_price
          deposit_price_per_person
          deposit_due
          payment_plan_available
          discount
          duration
          rooms{
            id
            description
            board
            board_description
            room_number
          }
          flights{
            id
            departing
            arrival
            return_departing
            return_arrival
            number
            return_number
            airline
            departure_airport {
              code
              name
            }
            arrival_airport{
              code
              name
            }
          }
          supplements {
            description
          }
          accommodation {
            id
            lists
            name
            address
            lat
            long
            resort{
              id
              name
              regions{
                name
                detail
                destinations{
                  name
                }
              }
            }
            images {
              url
            }
            trip_advisor_rating
            trip_advisor_num_reviews
            rating
          }
        }
        count
        found_lists
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `,{
      rooms: gqlRooms,
      duration: duration,
      departure_points : departurePoints,
      boards: boards,
      ratings: ratings,
      trip_ratings: tripRatings,
      departure_date: departureDate,
      departure_date_type : departureType,
      destinations: destinations,
      regions: regions,
      resorts: resorts,
      trip_type: tripType,
      accommodation_id: accommodationId,
      start_index: index,
      sort: sortIndex, 
      fav_accommodations: favAcc,
      client_list_id: cAccList,
      take: take
    });
  return availableOffers;
}

export async function productRooms(clientToken: string, sessionId: string, accommodationId: number,
  date: string, duration: number, rooms: string, desiredBoard: number) {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  const query = await gqlCli.request<GetRoomsResult>(
    gql`
      query rooms ($hwt_session:String!,$arrival:String!,$duration:Int,$accommodation_id:Int!,$rooms:[String],$desired_board:Int) {
        rooms(
          session: $hwt_session
          arrival: $arrival
          duration: $duration
          accommodation_id: $accommodation_id
          rooms: $rooms
          desired_board: $desired_board
        ) {
          result {
            adults
            children
            rooms {
              id
              cancellation_policy
              deposit_price
              deposit_price_per_person
              board
              board_description
              price
              description
              price_per_person
              room_number
              accommodation_id
              adults
              children
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { hwt_session: sessionId, arrival: date, duration: duration, rooms: gqlRooms, desired_board: desiredBoard, accommodation_id: accommodationId },
  );

  return query;
}

// not a hook because we need the data nested in a query.
export async function productFlights(clientToken: string, sessionId: string, duration: number, arrival: string, 
  departure: string, date: string, adults: number, children: number[]) {
  const gqlCli = getClient(clientToken);

  const query = await gqlCli.request<GetFlightsResult>(
    gql`
      query flights($hwt_session: String!,$departure_date:String!,$duration:Int,$departure_airport_iata:String,
        $arrival_airport_iata: String,$adults:Int,$children:[Int]) {
        flights(
          session: $hwt_session
          departure_date: $departure_date
          duration: $duration
          departure_airport_iata: $departure_airport_iata
          arrival_airport_iata: $arrival_airport_iata
          adults: $adults
          children: $children
        ) {
          result {
            id
            airline
            price
            original_price
            discount
            deposit_price
            deposit_price_per_person
            departure_airport {
              name
              code
            }
            arrival_airport {
              name
              code
            }
            departing
            arrival
            number
            return_number
            price_per_person
            discount
            return_arrival
            return_departing
            return_airline
            supplier_code
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { hwt_session: sessionId, departure_date: date, duration: duration, departure_airport_iata: departure, arrival_airport_iata: arrival, adults: adults, children: children  },
  );

  return query;
}
export async function getSessionPeople(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);

  return await gqlCli.request<any>(
    gql`
      query ($hwt_session: String!) {
        session(reference: $hwt_session) {
          result {
            basket {
              people {
                id
                title
                first_name
                second_name
                gender
                date_of_birth
                adult
                age
              }
              lead_person {
                id
                title
                first_name
                second_name
                email_address
                date_of_birth
                contact_telephone
                post_code
                marketing_permission
                special_requests
                address1
                address2
                address3
                address4
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function getLeadPaxAddress(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);

  return await gqlCli.request<any>(
    gql`
      query ($hwt_session: String!) {
        session(reference: $hwt_session) {
          result {
            basket {
              lead_person {
                address1
                address2
                address3
                address4
                post_code
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { hwt_session: sessionId },
  );
}

export async function getBasket(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);

  return await gqlCli.request<GetBasketResult>(
    gql`
      query ($hwt_session: String!) {
        basket(session: $hwt_session) {
          result {
            customer_reference
            deposit_due
            deposit_price
            deposit_price_per_person
            price
            price_per_person
            price_deposit_diff
            discounts {
              code
              type
              amount
              calculated_amount
            }
            products {
              id
              provider_id
              original_price
              price
              price_per_person
              promo_discount
              check_time
              description
              product_type {
                id
                description
                name
              }
              booking_component {
                reference
              }
            }
            supplements {
              description
              amount
              price
            }
            booking {
              id
              outstanding_balance
              previous_balance
              cost
              live_cost_difference
              status
              adults
              children
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { hwt_session: sessionId },
  );
}
// JH HERE
export async function removeProduct(clientToken: string, sessionId: string, id: number, productType: number) {
  const gqlCli = getClient(clientToken);

  return await gqlCli.request(
    gql`
      mutation ($hwt_session: String!,$id:Int!,$product_type:Int!) {
        basket_remove_product(session: $hwt_session, product_id: $id, product_type: $product_type) {
          result {
            products {
              id
              price
              provider_id
              product_type {
                id
                description
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { hwt_session: sessionId, id: id, product_type: productType },
  );
}

export async function applyBasketDiscount(clientToken: string, sessionId: string, discountCode: string) {
  const gqlCli = getClient(clientToken);

  return await gqlCli.request<GetBasketApplyDiscountResult>(
    gql`
      mutation ($hwt_session: String!, $discount_code: String!) {
        basket_apply_discount(session: $hwt_session, discount_code: $discount_code) {
          result {
            discounts {
              code
              calculated_amount
              amount
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { hwt_session: sessionId, discount_code: discountCode },
  );
}

export async function removeBasketDiscount(clientToken: string, sessionId: string, discountCode: string) {
  const gqlCli = getClient(clientToken);

  return await gqlCli.request<GetBaskeRemoveDidscountResult>(
    gql`
      mutation ($hwt_session: String!, $discount_code: String!) {
        basket_remove_discount(session: $hwt_session, discount_code: $discount_code) {
          result {
            deposit_due
            deposit_price
            deposit_price_per_person
            price
            price_per_person
            price_deposit_diff
            discounts {
              code
              type
              amount
              calculated_amount
            }
            products {
              id
              original_price
              price
              price_per_person
              check_time
              description
              product_type {
                id
                description
                name
              }
              booking_component {
                reference
              }
            }
            supplements {
              description
              amount
              price
            }
            booking {
              id
              outstanding_balance
              previous_balance
              cost
              live_cost_difference
              status
              adults
              children
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { hwt_session: sessionId, discount_code: discountCode },
  );
}

export async function clearProducts(clientToken: string, sessionId: string) {  
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation ($hwt_session: String!) {
        basket_clear_products(session: $hwt_session) {
          result {
            products {
              id
              price
              provider_id
              description
              product_type {
                id
                description
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function addProducts(
  clientToken: string,
  sessionId: string,
  productIds: number[],
  productType: number,
  clear = false,
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation ($hwt_session: String!, $product_ids: [Int]!, $product_type: Int!, $clear: Int) {
        basket_add_products(
          session: $hwt_session
          product_ids: $product_ids
          product_type: $product_type
          clear: $clear
        ) {
          result {
            products {
              id
              price
              provider_id
              description
              product_type {
                id
                description
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    {
      hwt_session: sessionId,
      product_ids: productIds,
      product_type: productType,
      clear: !clear ? 0 : 1,
    },
  );
}

export async function addProduct(
  clientToken: string,
  sessionId: string,
  id: number,
  productType: number,
  shouldReplace = true,
  idToRemove?: number,
) {
  const gqlCli = getClient(clientToken);

  return await gqlCli.request(
    gql`
      mutation ($hwt_session: String!,$replace:Int!,$remove:Int,$id:Int!,$product_type:Int!) {
        basket_add_product(session: $hwt_session, product_id: $id, product_type: $product_type, replace: $replace, remove: $remove) {
          result {
            products {
              id
              price
              provider_id
              description
              product_type {
                id
                description
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { hwt_session: sessionId, replace: !shouldReplace ? 0 : 1, remove: idToRemove, id: id, product_type: productType },
  );
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getSessionSelections(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);

  return await gqlCli.request<GetSessionSelectionsResult>(
    gql`
      query GetSession($id: String!) {
        session(reference: $id) {
          result {
            selections{
              duration
              departing
              arrival_code
              departure_code
              accommodation_id
              rooms
              board
              custom
              trip_type
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { id: sessionId },
  );
}

export async function getSession(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);

  return await gqlCli.request<GetSessionResult>(
    gql`
      query GetSession($id: String!) {
        session(reference: $id) {
          result {
            session
            
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { id: sessionId },
  );
}


export async function sessionNew(clientToken: string, accommodationId?: number, departureCode?: string, arrivalCode?: string, departing?: string, duration?: number, board?: number, rooms?: string, tripType?: number, customVar?: string, agentId?: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetSessionNewResult>(gql`
    query SessionNew ($accommodation_id: Int, $departure_code: String, $arrival_code: String, $departing: String, $duration: Int, $board: Int, $rooms: String, $trip_type: Int, $custom_var: String, $agent_id: Int) {
      session_new (accommodation_id: $accommodation_id, departure_code: $departure_code, arrival_code: $arrival_code, departing: $departing, duration: $duration, board: $board, rooms: $rooms, trip_type: $trip_type, custom_var: $custom_var, agent_id: $agent_id) {
        result {
          session
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `,
  { accommodation_id: accommodationId, departure_code: departureCode, arrival_code: arrivalCode, departing, duration, board, rooms, trip_type: tripType, custom_var: customVar, agentId },);
}

export async function sessionUpdateSelections(clientToken: string, reference: string, accommodationId?: number, departureCode?: string, arrivalCode?: string, departing?: string, duration?: number, board?: number, rooms?: string, tripType?: number, customVar?: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<SessionUpdateSelectionResult>(gql`
    mutation session_update_selections ($accommodation_id: Int, $departure_code: String, $arrival_code: String, $departing: String, $duration: Int, $board: Int, $rooms: String, $trip_type: Int, $custom_var: String, $reference: String!) {
      session_update_selections (reference: $reference, accommodation_id: $accommodation_id, departure_code: $departure_code, arrival_code: $arrival_code, departing: $departing, duration: $duration, board: $board, rooms: $rooms, trip_type: $trip_type, custom_var: $custom_var) {
        result {
          session
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `,
  { accommodation_id: accommodationId, departure_code: departureCode, arrival_code: arrivalCode, departing, duration, board, rooms, trip_type: tripType, custom_var: customVar, reference},);
}

export async function sessionSave(clientToken: string, sessionId: string, type: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<string>(gql`
    mutation ($session_id:String!,$type:Int) {
      session_save(session: $session_id
      type: $type) {
        result
        error {
          ... on Not_Found_Error {
          message
          }
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `, {session_id: sessionId, type: type});
}

export async function sessionUpdate(clientToken: string, sessionId: string, step: number, stepName: string, emailAddress='', contactNumber='', leadName='', type: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<string>(gql`
    mutation ($session_id:String!,$step:Int,$step_name:String,$email_address:String,
      $contact_number:String,$lead_name:String,$type:Int){
      session_update(session:$session_id
      step: $step
      step_name:$step_name
      email_address:$email_address
      contact_number: $contact_number
      lead_name: $lead_name
      type: $type) {
        result
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `, {session_id: sessionId, step: step, step_name: stepName, email_address: emailAddress, contact_number: contactNumber, lead_name: leadName, type: type});
}

export async function sessionHandOff(clientToken: string, sessionId: string, emailAddress='', contactNumber='', leadName='', type: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<string>(gql`
    mutation ($session_id:String!,$email_address:String,$contact_number:String,$lead_name:String,$type:Int){
      session_hand_off(session:$session_id
        email_address:$email_address
        contact_number: $contact_number
        lead_name: $lead_name
        type: $type) {
        result
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `,{session_id: sessionId, email_address: emailAddress, contact_number: contactNumber, lead_name: leadName, type: type});
}

export async function sessionSetBooked(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<string>(gql`
    mutation ($session_id:String!){
      session_set_booked(session:$session_id) {
        result
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `,{session_id: sessionId});
}

export async function sessionRemove(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<string>(gql`
    mutation ($session_id:String){
      session_remove(reference: $session_id) {
        result
        error {
          ... on Not_Found_Error {
          message
          }
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `,{session_id: sessionId});
}

export async function blockSessionComms(clientToken: string, id: number, reference: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<string>(gql`
    mutation ($id:Int!, $reference:String!){
      block_session_comms(id: $id, reference: $reference) {
        result
        error {
          ... on Not_Found_Error {
          message
          }
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `,{id, reference});
}

export async function markSessionStopComms(clientToken: string, id: number, reference: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<string>(gql`
    mutation ($id:Int!, $reference:String!){
      mark_no_send_session_comms(id: $id, reference: $reference) {
        result
        error {
          ... on Not_Found_Error {
          message
          }
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `,{id, reference});
}


export async function sessionRestore(clientToken: string, sessionId: string, customerReference: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<RestoreSessionResult>(gql`query session_restore ($session_id: String!, $reference: String!) {
	session_restore(session:$session_id,  reference: $reference){
		result{
      session
      selections{
				trip_type
			}
			basket{
			  products{
					product_type{
						id
					}
					id
				}
				lead_person{
					id
					first_name
					second_name
					date_of_birth
					address1
					address2
					address3
					address4
					email_address
					contact_telephone
          post_code
				}
				people{
					id
					title
					first_name
					second_name
					date_of_birth
				}
			}
		}
	}
}`, {session_id: sessionId, reference: customerReference });
}

export async function getOfferById(clientToken:string, id: string, rooms: string) {
  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  return await gqlCli.request<GetOffersByIdResult>(
    gql`
      query GetOfferById($id:String!, $rooms:[String]) {
        offer_by_id(id: $id, , rooms: $rooms) {
          result {
            id
            duration
            original_price
            price
            price_per_person
            duration
            supplement_total
            supplements {
              description
              price
            }
            deposit_due
            deposit_price
            deposit_price_per_person
            payment_plan_available
            accommodation {
              id
              name
              lists
              resort {
                id
                name
                regions {
                  name
                  detail
                  destinations {
                    name
                  }
                }
              }
              images {
                url
              }
              lat
              long
              address
              rating
              trip_advisor_rating
            }

            flights {
              id
              airline
              arrival
              departing
              return_departing
              check_time
              adults
              number
              children
              price
              departure_airport {
                name
                code
                departure_point_region {
                  id
                  name
                }
              }
              booking_component {
                description
              }
              return_departing
              return_arrival
              return_number
              arrival_airport {
                name
                code
              }
            }
            rooms {
              id
              price
              adults
              children
              description
              board
              board_description
              room_number
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { id, rooms: gqlRooms },
  );
}

export async function paymentPayMonthly(  clientToken: string, day: number, sessionId: string, amount: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<any>(
    gql`
          query ($hwt_session:String!,$day:Int!,$amount:Float!) {
            basket_payment_plan(session: $hwt_session, desired_day: $day, amount: $amount) {
              result {
                  id
                  outstanding_balance,
                  plan {
                    date_due,
                    amount
                  }
              }
              error {
                ... on Unexpected_Error {
                  message
                }
              }
            }
          }
        `,
    // eslint-disable-next-line
    { hwt_session: sessionId, day, amount },
  );
}

export async function checkProduct(  clientToken: string, sessionId: string, id: number, productType: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      query ($hwt_session:String!,$id:Int,$product_type:Int) {
        product_check(product_id: $id, product_type: $product_type, session: $hwt_session) {
          result {
            id
            price
            original_price
            discount
            deposit_price
            deposit_price_per_person
            check_time
            product_type {
              id
              description
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, id, product_type: productType },
  );
}

export async function productCheckAll(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<any>(
    gql`
      query ($hwt_session: String!) {
        product_check_all(session: $hwt_session) {
          result {
            id
            price
            original_price
            discount
            deposit_price
            deposit_price_per_person
            check_time
            product_type {
              id
              description
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function productToleranceCheck(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<any>(
    gql`
      query product_tolerance_check ($hwt_session: String!) {
        product_tolerance_check(session: $hwt_session) {
          result
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function checkProductDetails(  
  clientToken: string,
  sessionId: string,
  productType: number,
  productId: number,
) {
  return await checkProduct(clientToken,sessionId, productId, productType);
}

export async function getTripAdvsiorData(clientToken: string, accommodationId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<any>(
    gql`
      query accommodation ($accommodation_id:Int!){
        accommodation(accommodation_id:$accommodation_id) {
          result {
            trip_advisor
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `, {accommodation_id: accommodationId}
  );
}

export async function getAccommodation(clientToken: string, accommodationId: number, providerId=4) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<AccommodationResult>(
    gql`
      query accommodation ($accommodation_id:Int!, $provider_id: Int){
        accommodation(accommodation_id:$accommodation_id, provider_id:$provider_id) {
          result {
            id
            name
            address
            lat
            long
            resort{
              id
              name
              regions{
                name
                detail
                destinations{
                  name
                }
              }
            }
            images {
              url
            }
            detail
            trip_advisor
            trip_advisor_rating
            trip_advisor_num_reviews
            rating
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,{accommodation_id: accommodationId, provider_id: providerId}
  );
}

export async function getAccommodationDetail(clientToken: string, accommodationId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<AccommodationResult>(
    gql`
      query accommodation ($accommodation_id:Int!){
        accommodation(accommodation_id:$accommodation_id) {
          result {
            detail
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,{accommodation_id: accommodationId}
  );
}

export async function getAccommodationNameLocation(clientToken: string,accommodationId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<AccommodationResult>(
    gql`query accommodation ($accommodation_id:Int!){
      accommodation(accommodation_id:$accommodation_id) {
        result {
          name
          resort{
            name
            regions{
              name
              destinations{
                name
              }

            }
          }
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `,{accommodation_id: accommodationId}
  );
}

export async function getAllRoomsFromBasket(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetBasketRoomsResult>(
    gql`
      query BasketRooms($hwt_session: String!) {
        basket_rooms(session: $hwt_session) {
          result {
            id
            adults
            children
            board
            check_time
            board_description
            description
            room_number
            duration
            accommodation_id
            people {
              id
              first_name
              second_name
              date_of_birth
            }
            price
            price_per_person
            product_type {
              id
              description
            }

          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function getBasketAllProducts(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetBasketProductsResult>(
    gql`
      query BasketProducts($hwt_session: String!) {
        basket_products(session: $hwt_session) {
          result {
            basket{
                  customer_reference
                  deposit_due
                  deposit_price
                  deposit_price_per_person
                  price
                  price_per_person
                  price_deposit_diff
                  discounts {
                    code
                    type
                    amount
                    calculated_amount
                  }
                  products {
                    id
                    original_price
                    price
                    price_per_person
                    promo_discount
                    check_time
                    description
                    provider_id
                    product_type {
                      id
                      description
                      name
                    }
                    booking_component {
                      reference
                    }
                  }
                  supplements {
                    description
                    amount
                    price
                  }
                  booking {
                    id
                    outstanding_balance
                    previous_balance
                    cost
                    live_cost_difference
                    status
                    adults
                    children
                  }
              }
            transfers{
                  id
                  price
                  price_per_person
                  duration
                  description
                  product_type {
                    id
                    description
                  }
                  vehicle_type
            }
            flights{
                  id
                  airline
                  departure_airport {
                    code
                    name
                  }
                  arrival_airport {
                    code
                    name
                  }
                  return_airline
                  arrival
                  return_departing
                  return_arrival
                  departing
                  price
                  number
                  return_number
                  original_price
                  discount
                  deposit_price
                  deposit_price_per_person
                  product_type {
                    id
                    description
                  }
                  extras {
                    id
                    code
                    price
                    description
                    sub_description
                    our_description
                    product_type {
                      id
                      description
                    }
                    quantity_available
                  }
              }
            rooms{
                  id
                  adults
                  children
                  board
                  check_time
                  board_description
                  description
                  room_number
                  duration
                  accommodation_id
                  people {
                    id
                    first_name
                    second_name
                    date_of_birth
                  }
                  price
                  price_per_person
                  product_type {
                    id
                    description
                  }
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function getBasketPackageAllProducts(
  clientToken: string,
  sessionId: string,
) {
  const variables = {
    session: sessionId
  };
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetBasketPackageProductsResult>(
    gql`
      query basket_package_products($session: String!) {
        basket_package_products(session: $session) {
          result {

              basket{
                  customer_reference
                  deposit_due
                  deposit_price
                  deposit_price_per_person
                  price
                  price_per_person
                  price_deposit_diff
                  discounts {
                    code
                    type
                    amount
                    calculated_amount
                  }
                  products {
                    id
                    original_price
                    price
                    price_per_person
                    promo_discount
                    check_time
                    description
                    provider_id
                    product_type {
                      id
                      description
                      name
                    }
                    booking_component {
                      reference
                    }
                  }
                  supplements {
                    description
                    amount
                    price
                  }
                  booking {
                    id
                    outstanding_balance
                    previous_balance
                    cost
                    live_cost_difference
                    status
                    adults
                    children
                  }
              }
              rooms{
                  id
                  adult_capacity
                  child_capacity
                  infant_capacity
                  board
                  board_description
                  description
                  deposit_price
                  deposit_price_per_person
                  deposit_due
                  price
                  price_per_person
                  discount
                  original_price
                  room_number
                  adults
                  children
                  infants
                  free_child_places
              }
              package{
                  id
                  rooms {
                    id
                    adult_capacity
                    child_capacity
                    infant_capacity
                    board
                    board_description
                    description
                    deposit_price
                    deposit_price_per_person
                    deposit_due
                    price
                    price_per_person
                    discount
                    original_price
                    room_number
                    adults
                    children
                    infants
                  }
                  duration
                  flight {
                    outbound_departure
                    outbound_arrival
                    inbound_departure
                    inbound_arrival
                    airline
                    inbound_operated_by
							      outbound_operated_by
                    inbound_number
                    outbound_number
                    departure_point {
                      name
                      code
                    }
                    arrival_airport {
                      name
                      code
                    }
                  }
              accommodation {
                      id
                      name
                      lat
                      long
                      detail
                      trip_advisor
                      rating
                      images {
                        url
                      }
                      resort {
                        name
                        regions {
                          name
                          destinations {
                            name
                          }
                        }
                      }
                    }
                }  
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    variables,
  );
}

export async function getPackageRoomsFromBasket(
  clientToken: string,
  sessionId: string,
) {
  const variables = {
    session: sessionId
  };
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<BasketPackageRoomsResult>(
    gql`
      query basket_package_rooms($session: String!) {
        basket_package_rooms(session: $session) {
          result {
              id
              adult_capacity
              child_capacity
              infant_capacity
              board
              board_description
              description
              deposit_price
              deposit_price_per_person
              deposit_due
              price
              price_per_person
              discount
              original_price
              room_number
              adults
              children
              infants
              free_child_places
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    variables,
  );
}

export async function getPackageRoomFromBasket(
  clientToken: string,
  sessionId: string,
  productId: number,
) {
  const variables = {
    session: sessionId,
    productId: productId,
  };
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<BasketPackageRoomResult>(
    gql`
      query basket_package($session: String!, $productId: Int!) {
        basket_package(session: $session, product_id: $productId) {
          result {
           
              id
              adult_capacity
              child_capacity
              infant_capacity
              board
              board_description
              description
              deposit_price
              deposit_price_per_person
              deposit_due
              price
              price_per_person
              discount
              original_price
              room_number
              adults
              children
              infants
            
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    variables,
  );
}


export async function productTransfers(clientToken: string, sessionId: string, 
  adults: number, children: number[], arrival: string, returning: string, accommodationId: number, 
  arrivalAirportIata: string, returnAirportIata: string, outboundFlightNumber: string, inboundFlightNumber: string) {
  
  const gqlCli = getClient(clientToken);

  return await gqlCli.request<GetTransferResult>(
    gql`
      query ($hwt_session: String!, $arrival:String!,$returning:String!,$accommodation_id:Int,$arrival_airport_iata:String,$return_airport_iata:String,
        $outbound_flight_number:String,$inbound_flight_number:String,$adults:Int,$children:[Int]) {
        transfers(
          session: $hwt_session
          arrival: $arrival
          returning: $returning
          accommodation_id: $accommodation_id
          arrival_airport_iata: $arrival_airport_iata
          return_airport_iata: $return_airport_iata
          outbound_flight_number: $outbound_flight_number
          inbound_flight_number: $inbound_flight_number
          adults: $adults
          children: $children
        ) {
          result {
            id
            description
            vehicle_type
            check_time
            price_per_person
            product_type {
              id
              name
              description
            }
            duration
            price
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    { hwt_session: sessionId, arrival, returning, accommodation_id: accommodationId, arrival_airport_iata: arrivalAirportIata,
      return_airport_iata: returnAirportIata, outbound_flight_number: outboundFlightNumber, inbound_flight_number: inboundFlightNumber, adults, children},
  );
}

// no hook version
export async function AccommodationOffersByMonth(
  clientToken: string,
  acommodationId: number,
  departureDate: string,
  rooms: string,
  duration: number,
  departurePoints?: string[],
  boards?: number[],
) {

  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  return await gqlCli.request<GetAccommodationOffersByMonthResult>(gql`
      query ($accommodation_id:Int!, $departure_date:String!, $rooms:[String],$duration:Int,$departure_points:[String],$boards:[Int]){
        accommodation_offers_by_month(
          accommodation_id: $accommodation_id
          departure_date: $departure_date
          duration: $duration
          rooms: $rooms
          departure_points: $departure_points
          boards: $boards
        ) {
          result {
            id
            flights{
              departing
            }
            price
            price_per_person
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
    }
    `, {accommodation_id: acommodationId,departure_date: departureDate, duration, rooms:gqlRooms, departure_points: departurePoints, boards});
}

export async function makeBooking(clientToken: string, sessionId: string, paymentOption: number, agentId=-1) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation basket_new_booking($hwt_session: String!, $payment_option:Int, $agent_id:Int) {
        basket_new_booking(session: $hwt_session, payment_option: $payment_option, agent_id: $agent_id) {
          result {
            booking {
              id
              cost
              customer {
                id
                first_name
              }
            }
            session_reference
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, payment_option: paymentOption, agent_id: agentId },
  );
}

export async function bookProduct(
  clientToken: string,
  sessionId: string,
  bookindId: number,
  prodcutId: number,
  productTypeId: number,
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation booking_book_product($hwt_session: String!, $boking_id:Int, $product_id:Int, $product_Type:Int) {
        booking_book_product(session: $hwt_session, booking_id: $booking_id, product_id: $product_id, product_type: $product_type) {
          result {
            id
            provider_id
            product_type {
              id
              description
            }
            booking_component {
              id
              reference
              cost
              success
              description
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, booking_id: bookindId,  product_id: prodcutId, product_type: productTypeId},
  );
}


export async function applyProductDiscount(clientToken: string, productID: number, productTypeId: number, sessionId: string, discountCode: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation ($product_id: Int!, $product_type: Int!, $hwt_session: String!, $discount_code: String!) {
        product_apply_discount(product_id: $product_id, product_type: $product_type, session: $hwt_session, discount_code: $discount_code) {
          result {
            promo_code
            promo_discount
            price
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { product_id: productID, product_type: productTypeId, hwt_session: sessionId, discount_code: discountCode },
  );
}

export async function bookAllProducts(clientToken: string, sessionId: string, bookindId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation booking_book_product_all($hwt_session: String!,$booking_id:Int!) {
        booking_book_product_all(session: $hwt_session, booking_id: $booking_id) {
          result {
            id
            provider_id
            product_type {
              id
              description
            }
            booking_component {
              id
              reference
              cost
              success
              description
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, booking_id: bookindId },
  );
}

export async function handOffBooking(clientToken: string, sessionId: string, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation booking_hand_off ($booking_id:Int!,$session_id:String!){
        booking_hand_off(booking_id: $booking_id, session_reference: $session_id) {
          result
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,{booking_id:bookingId, session_id: sessionId}
  );
}

export async function bookingMailOut(clientToken: string, sessionId: string, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation booking_mailout ($booking_id:Int!,$session_id:String!){
        booking_mailout(booking_id: $booking_id, session_reference: $session_id) {
          result
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,{booking_id:bookingId, session_id: sessionId}
  );
}

export async function bookingUpdateType(clientToken: string, bookingId: number,type: number, sessionId: string ) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation booking_update_type ($booking_id:Int!,$type:Int!,$session_id:String!) {
        booking_update_type(booking_id: $booking_id, type: $type, session_reference: $session_id) {
          result{
            id
          }
          error {
            ... on Unexpected_Error {
              message
            }			
            ... on Not_Found_Error{
              message
            }
          }
        }
      }
    `,{booking_id:bookingId, type: type, session_id: sessionId}
  );
}

export async function setupPaymentPlan(
  clientToken: string,
  sessionId: string,
  bookingId: number,
  planId: number,
  customerName: string,
  customerAddress: string,
  accountNumber: string,
  sortCode: string,
  bankName: string,
  bankAddress: string,
  bankPostcode: string,
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation booking_setup_payment_plan(
        $sessionId: String!
        $bookingId: Int!
        $planId: Int!
        $customerName: String!
        $customerAddress: String!
        $accountNumber: String!
        $sortCode: String!
        $bankName: String!
        $bankAddress: String!
        $bankPostcode: String!
      ) {
        booking_setup_payment_plan(
          session: $sessionId
          booking_id: $bookingId
          plan_id: $planId
          customer_account_name: $customerName
          customer_address: $customerAddress
          bank_account_number: $accountNumber
          sort_code: $sortCode
          bank_name: $bankName
          bank_address: $bankAddress
          bank_postcode: $bankPostcode
        )
      }
    `,
    {
      sessionId,
      bookingId,
      planId,
      customerName,
      customerAddress,
      accountNumber,
      sortCode,
      bankName,
      bankAddress,
      bankPostcode,
    },
  );
}

export async function paymentStepOne(
  clientToken: string,

  cardNumber: string,
  name: string,
  expMonth: number,
  expYear: number,
  securityNo: string,

  paymentType: number,
  bookingId: number,
  sessionId: string,
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<PaymentResults>(
    gql`
      mutation booking_pay ($booking_id:Int,$payment_type:Int,$card_number:String!,$name_on_card:String!,
        $exp_month:Int!,$exp_year:Int!,$security_no:String!,$session_id:String!){
        booking_pay(
          booking_id: $booking_id
          payment_amount_type: $payment_type
          card_number: $card_number
          name_on_card: $name_on_card
          exp_month: $exp_month
          exp_year: $exp_year
          security_no: $security_no
          session: $session_id
          payment_session: ""
          data: ""
        ) {
          result {
            step
            pgw_response
            payment_session
            completed
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,{booking_id: bookingId, payment_type: paymentType, card_number: cardNumber, name_on_card: name, exp_month: expMonth, exp_year: expYear, security_no: securityNo, 
      session_id: sessionId}
  );
}

export async function paymentStepTwo(
  clientToken: string,
  
  cardNumber: string,
  name: string,
  expMonth: number,
  expYear: number,
  securityNo: string,

  paymentType: number,
  bookingId: number,
  paymentSession: string,
  dccSession: string,
  sessionId: string,
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<PaymentResults>(
    gql`
      mutation booking_pay ($booking_id:Int,$payment_type:Int,$card_number:String!,$name_on_card:String!,
        $exp_month:Int!,$exp_year:Int!,$security_no:String!,$payment_session:String,$session_id:String!) {
        booking_pay(
          booking_id: $booking_id
          payment_amount_type: $payment_type
          card_number: $card_number
          name_on_card: $name_on_card
          exp_month: $exp_month
          exp_year: $exp_year
          security_no: $security_no
          payment_session: $payment_session
          session: $session_id
          data: "{\\"dcc_session\\":\\"\\"}"
        ) {
          result {
            step
            pgw_response
            payment_session
            completed
            payment_result
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,{booking_id: bookingId, payment_type: paymentType, card_number: cardNumber, name_on_card: name, exp_month: expMonth, exp_year: expYear, security_no: securityNo, 
      payment_session: paymentSession, session_id: sessionId}
  );
}

export async function getBookingDetails(clientToken: string, sessionId: string, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetSessionFromBookingResult>(
    gql`
      query session_from_booking($hwt_session: String, $booking_id:Int!) {
        session_from_booking(
          booking_id: $booking_id
          session_reference: $hwt_session
        ) {
          result {
            session
            selections{
              duration
              departing
              arrival_code
              departure_code
              accommodation_id
              rooms
              board
            }
            basket {
              price
              booking {
                id
                reference
					      date_of_booking
                confirmed
                payment_option
				      }
              products {
                id
                check_time
                start_date
                end_date
                provider_id
                product_type {
                  id
                  description
                }
                booking_component {
                  id
                  reference
                  cost
                  success
                  description
                }
                price
                price_per_person
                original_price
                discount
                deposit_price
                deposit_price_per_person
              }
              people {
                id
                first_name
                second_name
              }
              lead_person {
                title
                first_name
                second_name
                contact_telephone
              }
              payment_plans{
                plan{
                  date_due
                  amount
                }
              }
            }
          }
          error {
            ... on Not_Found_Error {
              message
            }
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, reference: sessionId, booking_id: bookingId },
  );
}

export async function paymentAuth(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation ($session: String!) {
        payment_auth(payment_session: $session) {
          result {
            payment_result
            completed
          }
        }
      }
    `,
    {
      session: sessionId,
    },
  );
}

export async function getBookingPayMade(clientToken: string, sessionId: string, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<bookingPayMadeResult>(
    gql`
      query booking_pay_made($hwt_session: String!,$booking_id:Int!) {
        booking_pay_made(
          booking_id: $booking_id
          session_reference: $hwt_session
        ) {
          result
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, reference: sessionId, booking_id: bookingId},
  );
}

export async function flightFromBooking(clientToken: string, sessionId: string, productId: number, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<bookingFlightResult>(
    gql`
      query flight_from_booking($hwt_session: String!, $product_id:Int!,$booking_id:Int!) {
        flight_from_booking(session_reference: $hwt_session, product_id: $product_id, booking_id: $booking_id) {
          result {
            id
            airline
            departure_airport {
              code
              name
            }
            arrival_airport {
              code
              name
            }
            return_airline
            arrival
            return_departing
            return_arrival
            departing
            price
            price_per_person
            number
            return_number
            original_price
            discount
            deposit_price
            deposit_price_per_person
            product_type {
              id
              description
            }
            extras {
              id
              code
              price
              description
              sub_description
              our_description
              product_type {
                id
                description
              }
              quantity_available
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, product_id:productId, booking_id: bookingId},
  );
}

export async function roomsFromBooking(clientToken: string, sessionId: string, productId: number, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<bookingRoomResult>(
    gql`
      query room_from_booking($hwt_session: String!,$product_id:Int,$booking_id:Int) {
        room_from_booking(session_reference: $hwt_session, product_id: $product_id, booking_id: $booking_id) {
          result {
            id
            board
            check_time
            board_description
            description
            accommodation_id
            people {
              id
              first_name
              second_name
              date_of_birth
            }
            price

            product_type {
              id
              description
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, product_id:productId, booking_id: bookingId},
  );
}

export async function allRoomsFromBooking(clientToken: string, sessionId: string, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<bookingAllRoomResult>(
    gql`
      query rooms_from_booking($hwt_session: String!,$booking_id:Int!) {
        rooms_from_booking(session_reference: $hwt_session, booking_id: $booking_id) {
          result {
            id
            room_number
            board
            adults
            children
            check_time
            board_description
            description
            accommodation_id
            people {
              id
              first_name
              second_name
              date_of_birth
            }
            price
            price_per_person
            product_type {
              id
              description
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, booking_id: bookingId},
  );
}

export async function packageFromBooking(clientToken: string, sessionId: string, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<bookingPackageResult>(
    gql`
      query package_from_booking ($hwt_session: String!, $booking_id:Int!) {
        package_from_booking(booking_id: $booking_id, session_reference: $hwt_session){
          result{
            id
            accommodation {
              name
              resort {
                name
                regions {
                  name
                  destinations {
                    name
                  }
                }
              }
            }
            flight {
              outbound_departure
              outbound_arrival
              inbound_departure
              inbound_arrival
              airline
              inbound_operated_by
							outbound_operated_by
              inbound_number
              outbound_number
              departure_point {
                name
                code
              }
              arrival_airport {
                name
                code
              }
              bag_count
            }
            rooms{
              id
              description
              board
              board_code
              board_description
              adult_capacity
              child_capacity
              room_number
              adults
              children
            }

          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, booking_id: bookingId },
  );
}

export async function transferFromBooking(clientToken: string, sessionId: string, productId: number, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<bookingTransferResult>(
    gql`
        query transfer_from_booking ($hwt_session: String!,$product_id:Int!,$booking_id:Int!) {
          transfer_from_booking(product_id:$product_id, booking_id: $booking_id, session_reference: $hwt_session){
            result{
              id
              vehicle_type
              description
              price
              price_per_person
              original_price
              margin
              discount
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, product_id:productId, booking_id: bookingId},
  );
}

export async function getBasketFlights(clientToken: string, sessionId: string, productId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetBasketFlightsResult>(
    gql`
      query basket_flight($hwt_session: String!, $product_id:Int!) {
        basket_flight(session: $hwt_session, product_id: $product_id) {
          result {
            id
            airline
            departure_airport {
              code
              name
            }
            arrival_airport {
              code
              name
            }
            return_airline
            arrival
            return_departing
            return_arrival
            departing
            price
            number
            return_number
            original_price
            discount
            deposit_price
            deposit_price_per_person
            product_type {
              id
              description
            }
            extras {
              id
              code
              price
              description
              sub_description
              our_description
              product_type {
                id
                description
              }
              quantity_available
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, product_id: productId },
  );
}

export async function getBasketRoom(clientToken: string, sessionId: string, productId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetBasketRoomResult>(
    gql`
      query basket_room($hwt_session: String!, $product_id:Int) {
        basket_room(session: $hwt_session, product_id:$product_id) {
          result {
            id
            board
            check_time
            board_description
            description
            room_number
            accommodation_id
            people {
              id
              first_name
              second_name
              date_of_birth
            }
            price
            price_per_person
            product_type {
              id
              description
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, product_id: productId },
  );
}

export async function getBasketTransfer(clientToken: string, sessionId: string, productId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetBasketTransferResult>(
    gql`
      query basket_transfer($hwt_session: String!, $product_id:Int!) {
        basket_transfer(session: $hwt_session, product_id:$product_id) {
          result {
            id
            price
            price_per_person
            duration
            description
            product_type {
              id
              description
            }
            vehicle_type
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, product_id: productId },
  );
}

export async function getPaymentInformation(clientToken: string, sessionId: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetPaymentInformationResult>(
    gql`
      query payment_information($hwt_session: String!) {
        basket_important_information(session: $hwt_session) {
          result
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function addLeadPerson(clientToken: string, sessionId: string, title: string, firstName: string, secondName: string, dob: string, address1: string, address2: string, address3: string,
  address4: string, postCode: string, contactTelephone: string, emailAddress: string, specialRequests: string, gender: number, marketingPermission: number){
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
    mutation ($hwt_session: String!, $title: String!, $first_name: String!, $second_name: String!, $date_of_birth: String!, $address1: String!, $address2: String, $address3: String, $address4: String,
      $post_code:String!, $contact_telephone:String!, $email_address: String!, $special_requests: String, $gender: Int, $marketing_permission: Int){
      session_add_lead_person(
        title: $title
        first_name: $first_name
        second_name: $second_name
        date_of_birth: $date_of_birth
        address1: $address1
        address2: $address2
        address3: $address3
        address4: $address4
        post_code: $post_code
        email_address: $email_address
        contact_telephone: $contact_telephone
        marketing_permission: $marketing_permission
        gender: $gender
        special_requests: $special_requests
        session: $hwt_session
      ) {
        result {
          id
        }
        error {
          ... on Not_Found_Error {
            message
          }
          ... on Validation_Error {
            type
            message
            paramater
          }
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, title, first_name: firstName, second_name: secondName, date_of_birth: dob, address1, address2, address3, address4, 
      post_code: postCode, contact_telephone: contactTelephone, email_address: emailAddress,
      special_requests: specialRequests, gender, marketing_permission: marketingPermission },
  );
  // });
}

export async function addAdditionalPerson(clientToken: string, sessionId: string, title: string, firstName: string, secondName: string, dob: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
    mutation ($hwt_session: String!, $title: String!, $first_name: String!, $second_name: String!, $date_of_birth: String!,) {
      session_add_person(
        title: $title
        first_name: $first_name
        second_name: $second_name
        date_of_birth: $date_of_birth
        gender: 0
        session: $hwt_session
      ) {
        result {
          id
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, title, first_name: firstName, second_name: secondName, date_of_birth: dob,},
  );
  // });
}

export async function clearPeople(clientToken: string, sessionId?: string) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      mutation ($hwt_session: String!) {
        session_clear_people(session: $hwt_session) {
          result {
            id
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function checkAge(clientToken: string, sessionId: string, dob: string, age: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      query ($hwt_session: String!, $dob: String!, $age: Int!) {
        session_check_person_age(session: $hwt_session, dob: $dob, age: $age) {
          result
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, age: age, dob: dob },
  );
}

export async function checkAgeType(clientToken: string, sessionId: string, dob: string, age: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      query ($hwt_session: String!, $dob: String!, $age: Int!) {
        session_check_person_age_type(session: $hwt_session, dob: $dob, age: $age) {
          result
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, age: age, dob: dob },
  );
}

export async function bookingMailDone(clientToken: string, bookingId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      query booking_maildone ($booking_id: Int!) {
        booking_maildone(booking_id: $booking_id) {
          result
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { booking_id: bookingId },
  );
}

export async function agent(clientToken: string, agentId: number) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<GetAgentResult>(
    gql`
      query agent ($id: Int!) {
        agent(id: $id) {
          result {
            id
            abta_number
            first_name
            second_name
            contact_number
            email_address
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { id: agentId },
  );
}

export async function packageAvailableOptions(
  clientToken: string,
  sessionId: string,
  boards?: number[],
  ratings?: number[],
  tripRatings?: number[],
  resorts?: number[],
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<PackageAvailableOptions>(
    gql`
        query package_available_options ($hwt_session:String, $boards: [Int], $ratings: [Int], $trip_ratings: [Float], $resorts: [Int]) {
          package_available_options (session:$hwt_session, boards: $boards, ratings: $ratings, trip_ratings:$trip_ratings,resorts:$resorts) {
            result {
              boards {
                result
                count
              }
              ratings {
                result
                count
              }
              trip_ratings{
                result
                count
              }
              resorts{
              result
              title
              count
              }
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
    }`,{hwt_session: sessionId, boards, ratings, trip_ratings: tripRatings, resorts}
  );
}

export async function packageAvailableResorts(
  clientToken: string,
  sessionId: string,
  ratings?: number[],
  tripRatings?: number[],
  boards?: number[],
) {

  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
        query package_available_resorts ($hwt_session:String, $boards: [Int], $ratings: [Int], $trip_ratings: [Float]) {
          package_available_resorts (session:$hwt_session, boards: $boards, ratings: $ratings, trip_ratings:$trip_ratings) {
            result {
              result
              count
              title
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
    }`,{hwt_session: sessionId, boards, ratings, trip_ratings: tripRatings}
  );
}

export async function packageAvailableBoards(
  clientToken: string,
  sessionId: string,
  ratings?: number[],
  tripRatings?: number[],
  resorts?: number[],
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
        query package_available_boards ($hwt_session:String, $boards: [Int], $ratings: [Int], $trip_ratings: [Float], $resorts: [Int]) {
          package_available_boards (session:$hwt_session, boards: $boards, ratings: $ratings, trip_ratings:$trip_ratings,resorts:$resorts) {
            result {
              result
              count
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
    }`, {hwt_session: sessionId, ratings, trip_ratings: tripRatings, resorts}
  );
}

export async function packageAvailableRatings(
  clientToken: string,
  sessionId: string,
  boards?: number[],
  tripRatings?: number[],
  resorts?: number[],
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
        query package_available_ratings ($hwt_session:String, $boards: [Int], $trip_ratings: [Float], $resorts: [Int]) {
          package_available_ratings (session:$hwt_session, boards: $boards, trip_ratings:$trip_ratings,resorts:$resorts){
            result {
              result
              count
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
    }`, {hwt_session: sessionId, boards, trip_ratings: tripRatings, resorts}
  );
}

export async function packageAvailableTripRatings(
  clientToken: string,
  sessionId: string,
  boards?: number[],
  ratings?: number[],
  resorts?: number[],
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
        query package_available_trip_ratings ($hwt_session:String, $boards: [Int], $ratings: [Int], $resorts: [Int]){
          package_available_trip_ratings(session:$hwt_session, boards: $boards, ratings:$ratings,resorts:$resorts){
            result {
              result
              count
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }`, {hwt_session: sessionId, boards, trip_ratings: ratings, resorts}
  );
}

// export async function packageAvailableFeatures(clientToken: string, sessionId?: string) {
//   const gqlCli = getClient(clientToken);
//   return await gqlCli.request(
//     gql`
//       query package_available_features($hwt_session: String!) {
//         package_available_features(session: $hwt_session) {
//           result {
//             result
//             count
//           }
//           error {
//             ... on Unexpected_Error {
//               message
//             }
//           }
//         }
//       }
//     `,
//     // eslint-disable-next-line
//     { hwt_session: sessionId },
//   );
// }

export async function packageAvailableDurations(
  clientToken: string,
  regions?: number[],
  resorts?: number[],
  departureAirport?: string | null,
  departureDate?: string | null,
  tripType?: number | null,
) {
  let queryParams = '';
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
        query package_available_durations ($resorts: [Int], $regions: [Int], $departure_date: String, $departure_airport:String, $trip_type: Int) {
          package_available_durations (regions: $regions,resorts:$resorts, departure_date: $departure_date, departure_airport: $departure_airport, trip_type: $trip_type) {
            result
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
    `, { resorts, regions, departure_date: departureDate, departure_airport: departureAirport, trip_type: tripType},
  );
}


export async function packageAvailableResortsRegions(
  clientToken: string,
  duration?: number,
  departureAirport?: string | null,
  departureDate?: string | null,
  tripType?: number | null,
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<any>(
    gql`
        query package_available_resorts_regions ($duration: Int, $departure_airport: String, $departure_date: String, $trip_type: Int) {
          package_available_resorts_regions (duration: $duration, departure_airport: $departure_airport, departure_date: $departure_date, trip_type: $trip_type) {
            result
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
    `, {duration, departure_airport: departureAirport, departure_date: departureDate, trip_type: tripType}
  );
}

export async function packageAvailableDeparturePoints(
  clientToken: string,
  duration?: number,
  regions?: number[],
  resorts?: number[],
  departureDate?: string | null,  
  tripType?: number | null,
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<any>(
    gql`
        query package_available_departure_points ($duration: Int, $resorts: [Int], $regions: [Int], $departure_date: String, $trip_type: Int){
          package_available_departure_points (duration: $duration, resorts: $resorts, regions: $regions, departure_date: $departure_date, trip_type: $trip_type){
            result{
              name
              code
              departure_point_region {
                id
                name
              }
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
    `, {duration, resorts, regions, departure_date: departureDate, trip_type: tripType}
  );
}

export async function packageAvailableDays(
  clientToken: string,
  month: number,
  year: number,
  duration?: number,
  regions?: number[],
  resorts?: number[],
  departureAirport?: string,
  tripType?: number | null,
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<any>(
    gql`
        query package_available_days ($month: Int, $year: Int, $duration: Int, $resorts: [Int], $regions: [Int], $departure_airport: String, $trip_type: Int){
          package_available_days (month: $month, year: $year, duration: $duration, resorts: $resorts, regions: $regions, departure_airport: $departure_airport, trip_type: $trip_type) {
            result
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
    `,{month, year, duration, resorts, regions, departure_airport: departureAirport, trip_type: tripType}
  );
}

export async function packageAvailableMonths(
  clientToken: string,
  duration?: number,
  regions?: number[],
  resorts?: number[],
  departureAirport?: string,
  tripType?: number | null,
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
        query package_available_months ($duration: Int, $resorts: [Int], $regions: [Int], $departure_airport: String, $trip_type: Int) {
          package_available_months (duration: $duration, resorts: $resorts, regions: $regions, departure_airport: $departure_airport, trip_type: $trip_type) {
            result{
              enabled
              month
              month_text
              year
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
    `,{duration, resorts, regions, departure_airport: departureAirport, trip_type: tripType}
  );
}

export async function packageSearch(
  clientToken: string,
  sessionId: string,
  rooms: string,
  departureAirport: string,
  departureDate: string,
  duration: number,
  sort: number,
  regions?: number[],
  resorts?: number[],
) {
  const gqlCli = getClient(clientToken);

  const gqlRooms = stringToGQL(rooms);

  return await gqlCli.request<PackageOfferResult>(
    gql`
        query package_search ($hwt_session:String!, $rooms:[String], $duration: Int, $departure_airport: String, $resorts: [Int], $regions: [Int], $departure_date: String!, $sort: Int) {
          package_search (session: $hwt_session, rooms: $rooms, departure_airport_iata: $departure_airport, duration: $duration, resorts: $resorts, regions: $regions, departure_date: $departure_date, sort: $sort) {
            result {
              id
              duration
              flight {
                outbound_departure
                outbound_arrival
                departure_point{
									name
									code
								}
                inbound_departure
                inbound_arrival
                arrival_airport{
									name
									code
								}
              }
              rooms{
                id
                adult_capacity
                child_capacity
                infant_capacity
                board
                board_description
                description
                deposit_price
                deposit_price_per_person
                deposit_due
                payment_plan_available
                price
                price_per_person
                discount
                original_price
                room_number
                adults
                children
                infants
              }
              accommodation {
                id
                name
                address
                lat
                long
                resort{
                  id
                  name
                  regions{
                    name
                    detail
                    destinations{
                      name
                    }
                  }
                }
                images {
                  url
                }
                trip_advisor_rating
                trip_advisor_num_reviews
                rating
              }
	          }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
    `, {hwt_session: sessionId, rooms: gqlRooms, duration , departure_airport: departureAirport, resorts, regions, departure_date: departureDate, sort}
  );
}

export async function packageFilter(
  clientToken: string,
  sessionId: string,
  sort: number,
  boards?: number[],
  ratings?: number[],
  tripRatings?: number[],
  resorts?: number[],
) {
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<PackageFilterResult>(
    gql`
        query package_filter ($hwt_session:String!, $boards: [Int], $ratings: [Int], $trip_ratings: [Float], $resorts: [Int], $sort: Int){
          package_filter (session:$hwt_session, boards: $boards, ratings: $ratings, trip_ratings:$trip_ratings,resorts:$resorts, sort: $sort) {
            result {
              id
              duration
              flight {
                outbound_departure
                outbound_arrival
                departure_point{
									name
									code
								}
                inbound_departure
                inbound_arrival
                arrival_airport{
									name
									code
								}
              }
              rooms{
                id
                adult_capacity
                child_capacity
                infant_capacity
                board
                board_description
                description
                deposit_price
                deposit_price_per_person
                deposit_due
                price
                price_per_person
                discount
                original_price
                room_number
                adults
                children
                infants
                payment_plan_available
              }
              accommodation {
                id
                name
                address
                lat
                long
                resort{
                  id
                  name
                  regions{
                    name
                    detail
                    destinations{
                      name
                    }
                  }
                }
                images {
                  url
                }
                trip_advisor_rating
                trip_advisor_num_reviews
                rating
              }
	          }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
    `, {hwt_session: sessionId, sort, boards, ratings, trip_ratings: tripRatings, resorts}
  );
}

export async function getPackageDetails(
  clientToken: string,
  sessionId: string,
  packageId: number,
  productIds: number[],
) {
  const variables = {
    session: sessionId,
    packageId: packageId,
    productIds: productIds,
  };
  const gqlCli = getClient(clientToken);
  return await gqlCli.request<PackageByIdResult>(
    gql`
      query package($session: String!, $packageId: Int!, $productIds: [Int!]!) {
        package(session: $session, package_id: $packageId, product_ids: $productIds) {
          result {
            id
            rooms {
              id
              adult_capacity
              child_capacity
              infant_capacity
              board
              board_description
              description
              deposit_price
              deposit_price_per_person
              deposit_due
              price
              price_per_person
              discount
              original_price
              room_number
              adults
              children
              infants
              free_child_places
            }
            duration
            flight {
              outbound_departure
              outbound_arrival
              inbound_departure
              inbound_arrival
              airline
              inbound_operated_by
							outbound_operated_by
              inbound_number
              outbound_number
              departure_point {
                name
                code
              }
              arrival_airport {
                name
                code
              }
            }
            accommodation {
              id
              name
              lat
              long
              detail
              trip_advisor
              rating
              images {
                url
              }
              resort {
                name
                regions {
                  name
                  destinations {
                    name
                  }
                }
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    variables,
  );
}

export async function packagePaymentPlan(
  clientToken: string,
  sessionId: string,
  packageId: number,
  productIds: number[],
) {
  const variables = {
    hwtsession: sessionId,
    packageId: packageId,
    productIds: productIds,
  };
  const gqlCli = getClient(clientToken);
  return await gqlCli.request(
    gql`
      query package_payment_plan($hwtsession: String!, $packageId: Int!, $productIds: [Int]) {
        package_payment_plan(
          session: $hwtsession
          package_id: $packageId
          product_ids: $productIds
          desired_day: 1
        ) {
          result {
            id
            desired_day
            outstanding_balance
            plan {
              date_due
              amount
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    variables,
  );
}

export const accommodationAdd = (accommodationId: number, sessionRef: string, type: number) => {
  try{
    if(process.env.REACT_APP_TEST != null){
      if(process.env.REACT_APP_TEST == '1'){
        return
      }
    }
  
    const d = {
      'accommodtion_id': accommodationId,
      'session_ref': sessionRef,
      'type': type
    }
    api('/accommodation_add/', d, 'POST', () => { return}, () => {return}, process.env.REACT_APP_GRAPHQL_BEARER || '', process.env.REACT_APP_STATS_URL || '')
  }
  catch (error) {
    console.log(error);
  }


}

export const searchAdd = (departurePoints: number[], destinations: number[], regions: number[], resorts: number[], duration:number, adults: number, children: number, 
  rooms: number, departing: string, sessionRef: string, type: number, userToken: string) => {



  try{
    if(process.env.REACT_APP_TEST != null){
      if(process.env.REACT_APP_TEST == '1'){
        return
      }
    }

    const d = {
      'departure_points': departurePoints,
      'destinations': destinations,
      'regions': regions,
      'resorts': resorts,
      'departing': departing,
      'duration': duration,
      'adults': adults,
      'children': children,
      'rooms': rooms,
      'type': type,
      'session_ref': sessionRef
    }

    api('/search_add/', d, 'POST', () => { return}, () => {return}, userToken || process.env.REACT_APP_GRAPHQL_BEARER || '', process.env.REACT_APP_STATS_URL || '')
  }
  catch (error) {
    console.log(error);
  }
}

export const journeyAdd = (accommodationId: number, adults: number, children: number, rooms: number, customerRef: string, step: string, stepNumber: number, sessionRef: string, type: number, userToken: string) => {



  try{
    if(process.env.REACT_APP_TEST != null){
      if(process.env.REACT_APP_TEST == '1'){
        return
      }
    }
  
    const d = {
      'accommodtion_id': accommodationId,
      'adults': adults,
      'children': children,
      'rooms': rooms,
      'customer_reference': customerRef,
      'step': step,
      'step_number': stepNumber,
      'session_ref': sessionRef,
      'type': type
    }
    api('/journey_add/', d, 'POST', () => { return}, () => {return}, userToken || process.env.REACT_APP_GRAPHQL_BEARER || '', process.env.REACT_APP_STATS_URL || '')
  }
  catch (error) {
    console.log(error);
  }
}

export async function siteSign(){  
  const tok = await apiA('/site/sign_h', null, 'GET', '', process.env.REACT_APP_HTTP_URL || '')
  if(tok == null){
    return ''
  }
  else{
    return await tok.json();
  }
}

export async function siteConfiguration(configurationID: string, clientToken: string){
  const tok = await apiA('/client_get_configuration/' + configurationID, null, 'GET', clientToken, process.env.REACT_APP_HTTP_URL || '')
  if(tok == null){
    return ''
  }
  else{
    return await tok.json();
  }
}

export async function clientConfiuration(clientToken: string){
  const tok = await apiA('/site_configuration', null, 'GET', clientToken, process.env.REACT_APP_HTTP_URL || '')
  if(tok == null){
    return ''
  }
  else{
    return await tok.json();
  }
}

export const siteLog = (clientToken: string, end_point: string, request: string, response: string, responseCode:number, responseTime: number, logFormat: number, sessionReference: string, notes: string) => {
  try{
  
    const d = {
      "end_point": end_point,
      "request": request,
      "response": response,
      "response_code": responseCode,
      "response_time": responseTime,
      "format": logFormat,
      "session_reference": sessionReference,
      "notes": notes,
    }

    api('site/log', d, 'POST', () => { return },() => {return}, clientToken, process.env.REACT_APP_HTTP_URL || '')
  }
  catch (error) {
    console.log(error);
  }
}

export async function apiA(url:string, data : any, method = 'GET', token: string, endPoint: string){

  let config: any = null;

	config = {
		headers: {
      'Authorization' : '',
			'Content-Type': 'application/json',
		},
		method,
		body: method.toLowerCase() == 'post' ? JSON.stringify(data) : null,
	};

  if(token != null){
		config = {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json',
			},
			method,
			body: method.toLowerCase() == 'post' ? JSON.stringify(data) : null,
		};
	}
	const res = await fetch(endPoint + url, config)

  if (!res.ok) {
    // Try Log error in DB
    try {
      console.log(res.statusText);
      // DB error log call here
      // res.json().then(err => this.LogError(err));
    } catch (error) {
      // Failed to log into DB console log it and maybe do something else
      console.log(res.statusText);
    }
    return
  }
  return res;
};

export const api = (url:string, data : any, method = 'GET', callback : any, errcallback : any, token: string, endPoint: string) => {

  let config: any = null;

	config = {
		headers: {
      'Authorization' : '',
			'Content-Type': 'application/json',
		},
		method,
		body: method.toLowerCase() == 'post' ? JSON.stringify(data) : null,
	};

  if(token != null){
		config = {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json',
			},
			method,
			body: method.toLowerCase() == 'post' ? JSON.stringify(data) : null,
		};
	}
	fetch(endPoint + url, config)
		.then((res) => {
			if (!res.ok) {
				// Try Log error in DB
				try {
					console.log(res.statusText);
					// DB error log call here
					// res.json().then(err => this.LogError(err));
				} catch (error) {
					// Failed to log into DB console log it and maybe do something else
					console.log(res.statusText);
				}
				errcallback();
			} else {
				callback(res);
			}
		})
		.catch((err) => (errcallback != null ? errcallback(err) : null));
};

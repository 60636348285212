import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'

import './HolidayCardPrice.scss';
import calendarLines from '../../assets/calendarLines.svg';
import closeIcon from '../../assets/close-icon-white.svg';
import ddLogo from '../../../direct-debit-logo.svg';
import ddLogob from '../../../direct-debit-blue.svg';


import { useNavigate } from 'react-router-dom';
import { PriceDatepicker } from '../../PriceDatepicker';

import {
  AccommodationOffersByMonth,
  availableMonthsHoliday,
  offerPaymentPlan,
} from '../../../hooks/useRequest';

import { GetAccommodationOffersByMonthResult, Offer } from '../../../hooks/types';
import { useStores } from '../../../hooks/useStore';
import { RootStore } from '../../../stores/RootStore';

import { useOutsideClick } from '../../../hooks/useOutsideClick';

import { roomsToString } from '../../../services/pax'

import { getBoardParamsString, getRoomsParamsString, getDurationParamString, getDeparturePointsParamsString } from '../../../services/search';

interface HolidayCardProps {
  id: string;  
  offer: Offer;
  searchParams: URLSearchParams;
  isTotalPriceFlag?: boolean;
  selectedBoards: any;
  visible: boolean;
  onOfferClick: (id: string) => void;
  onShowOffers: (id: string) => void;
}

const HolidayCardPrice: FunctionComponent<HolidayCardProps> = ({
  id,
  offer,
  searchParams,
  isTotalPriceFlag,
  selectedBoards,
  visible,
  onOfferClick,
  onShowOffers,
}: HolidayCardProps) => {

  const {
    RootStore: {
      userToken,
      configuration,
      
    },
  } = useStores() as { RootStore: RootStore };

  dayjs.extend(AdvancedFormat)

  const navigate = useNavigate();

  let salePrice = `£${Math.round(offer.price)}`;
  if(!isTotalPriceFlag){
    salePrice = `£${Math.round(offer.price_per_person)}${!isTotalPriceFlag ? 'pp' : ''}`
  }


  const [calendarSelectedDate, setCalendarSelectedDate] = useState<dayjs.Dayjs>(dayjs(offer.flights[0].departing));

  const [isCalendarLoading, setIsCalendarLoading] = useState<boolean>(false);

  // const [accommodationResponse, setAccommodationResponse] = useState<Offer[]>([]);
  const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(visible);

  const [isDepositVisible, setIsDepositVisible] = useState<boolean>(false);
  const [isDepositVisibleMOnthly, setIsDepositVisibleMonthly] = useState<boolean>(false);
  const [paymentPlanData, setPaymentPlanData] = useState<any>(null);
  const [depositInfoReady, setIsDepositInfoRedy] = useState<boolean>(false);

  const [datepickerData, setDatepickerData] = useState<any>({
    totalPrices: {},
    prices: {},
    ids: {},
  });

  const [availableDatePickerMonths, setDatePickerMonths] = useState<any>(null);


  let rooms = searchParams.get('rooms') || '2-';

  let type = 0;
  if(searchParams.get('type') != null){
    type = Number(searchParams.get('type'));
  }

  let duration = 7;
  if(searchParams.get('duration') != null){
    duration = Number(searchParams.get('duration'));
  }

  
  let selBoards: number[] = [];
  const qsBaords = searchParams.get('boards');
  if(qsBaords != null){
    for(const x of qsBaords.split('-')){
      selBoards.push(Number(x))
    }
  }
  let selDeparturePoints: string[] = [];
  const qsDepPoints = searchParams.get('departurePoints');
  if(qsDepPoints != null){
    for(const x of qsDepPoints.split('-')){
        selDeparturePoints.push(x)
    }
  }


  const outsideClickCallback = (e : any) => {

    if(e.target.id == 'deposit_st' || e.target.id == 'deposit_st_a' || e.target.id == 'deposit_st_b'){
      return;
    }
    if(e.target.id == 'deposit_month' || e.target.id == 'deposit_month_a' || e.target.id == 'deposit_month_b' || e.target.id == 'deposit_month_c' || e.target.id == 'deposit_month_d'){
      return;
    }

    // setIsCalendarVisible(false);
    setIsDepositVisible(false);
    setIsDepositVisibleMonthly(false);
  };



  interface AccommodationOffers {
    id: string;
    flights: [
      {
        departing: string;
      },
    ];
    price: number;
    price_per_person: number;
  }

  interface DatepickerDataStructure {
    [key: string]: number;
  }

  interface DatepickerIdsStructure {
    [key: string]: string;
  }

  const generateDatepickerData = (offers: any) => {
    const addedDays: any[] = [];

    const datepickerStructure: {
      totalPrices: DatepickerDataStructure;
      prices: DatepickerDataStructure;
      ids: DatepickerIdsStructure;
    } = {
      prices: {},
      ids: {},
      totalPrices: {},
    };
    offers &&
      offers.forEach((offer: AccommodationOffers) => {
        const key: string = offer.flights[0].departing.split(' ')[0];


        if(addedDays.indexOf(key) == -1)
        {
          datepickerStructure.totalPrices[key] = Number(offer['price'].toFixed(0));
          datepickerStructure.prices[key] = Number(offer['price_per_person'].toFixed(0));
          datepickerStructure.ids[key] = offer.id;
          addedDays.push(key);
        }



      });

    return datepickerStructure;
  };

  // useEffect(() => {

  //   alert(selectedMonthAndYear)

  // }, [selectedMonthAndYear])

  const setSelectedMonthAndYear = async (date: dayjs.Dayjs) => {
    const month = date.month() + 1;
    const strSelectedmonth = date.year() + '-' + month + '-1 00:00';

    // const searchOptions = searchParams;
    // const bbArr = getSelectedBoardArray();
    // if (bbArr.length > 0) {
    //   searchOptions['boardType'] = bbArr;
    // }

    const accommodationOffersByMonth = await AccommodationOffersByMonth(
      userToken,
      offer.accommodation.id,
      strSelectedmonth,
      rooms,
      duration,
      selDeparturePoints,
      selBoards
    );

    loadGrid(accommodationOffersByMonth);

    if(accommodationOffersByMonth.accommodation_offers_by_month.result.length > 0){
      setCalendarSelectedDate(dayjs(accommodationOffersByMonth.accommodation_offers_by_month.result[0].flights[0].departing));
    }

    console.log('jason ' + date);
  };

  const calendarDisplay = async (isVisisble: boolean) => {

    if (!isVisisble) {
      setIsCalendarVisible(isVisisble);
      return;
    }
    // onShowOffers(id);

    setIsCalendarVisible(isVisisble);
    setIsCalendarLoading(true);

    const offerDate = dayjs(offer.flights[0].departing);

    const hotelId = offer.accommodation.id;

    const strSelectedmonth = offerDate.year() + '-' + (offerDate.month() + 1)  + '-1 00:00';
    // const searchOptions = searchParams;
    // const bbArr = getSelectedBoardArray();
    // if (bbArr.length > 0) {
    //   searchOptions['boardType'] = bbArr;
    // }

    // get price data etc and show the cal
    const accommodationOffersByMonth = await AccommodationOffersByMonth(
      userToken,
      offer.accommodation.id,
      strSelectedmonth,
      rooms,
      duration,
      selDeparturePoints,
      selBoards
    );



    const availMonths = await availableMonthsHoliday(
      userToken,
      rooms,
      selDeparturePoints,
      duration,
      selBoards,
      hotelId,
      type,
    );

    loadGrid(accommodationOffersByMonth);
    setDatePickerMonths(availMonths.available_months.result);
    setIsCalendarLoading(false);

    setCalendarSelectedDate(offerDate);
    
    
    return;

  };

  const loadGrid = (accommodationOffersByMonth: GetAccommodationOffersByMonthResult) => {
    const response =
      accommodationOffersByMonth && accommodationOffersByMonth.accommodation_offers_by_month.result;

    const prices = generateDatepickerData(response);

    // setAccommodationResponse(response);
    const combinedTotal = { ...(datepickerData.totalPrices || {}), ...prices.totalPrices };
    const combinedPrices = { ...(datepickerData.prices || {}), ...prices.prices };
    const combinedIds = { ...(datepickerData.ids || {}), ...prices.ids };
    setDatepickerData({ totalPrices: combinedTotal, prices: combinedPrices, ids: combinedIds });
  };

  const selectCalOffer = (date: dayjs.Dayjs) => {
    if (Array.isArray(date)) {
      date = date[0];
    }

    if (date == null) {
      return;
    }

    const month = date.month() + 1;
    let strMonth = month.toString();
    if (month < 10) {
      strMonth = '0' + strMonth.toString();
    }
    const day = date.date();
    let strDay = day.toString();
    if (day < 10) {
      strDay = '0' + strDay;
    }

    const strOfferDate = date.year() + '-' + strMonth + '-' + strDay;
    selectOfferGrid(strOfferDate);
    
  };

  const calendarRef = useRef<HTMLDivElement>(null);

  const offerDepositDetail = useRef<HTMLDivElement>(null);
  const offerDepositDetailSpan = useRef<HTMLDivElement>(null)

  const offerDepositDetailMonthly = useRef<HTMLDivElement>(null);
  const offerDepositDetailMonthlySpan = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (offerDepositDetail.current) {
      offerDepositDetail.current.scrollIntoView({ behavior: 'smooth',  block: 'center', inline: 'center' });
    }
  }, [offerDepositDetail.current, isDepositVisible, isDepositVisibleMOnthly]);

  useEffect(() => {
    if (offerDepositDetailMonthly.current) {
      offerDepositDetailMonthly.current.scrollIntoView({ behavior: 'smooth',  block: 'center', inline: 'center' });
    }
  }, [offerDepositDetailMonthly.current, isDepositVisibleMOnthly]);

  // const priceWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    if(!isDepositVisibleMOnthly && !isDepositVisible){
      return;
    }
    if(paymentPlanData != null){
      return
    }

    // const rms = JSON.stringify(roomsToString(rooms));
  
    if(offer.payment_plan_available){
      offerPaymentPlan(userToken, id, rooms).then((opd) => {
        setPaymentPlanData(opd.offer_payment_plan.result);
        setIsDepositInfoRedy(true);
      })
    }else{
      setIsDepositInfoRedy(true);
    }


   
  }, [isDepositVisible, isDepositVisibleMOnthly]);


  // useOutsideClick([calendarRef, priceWrapperRef], outsideClickCallback);
  useEffect(() => {
    setIsCalendarVisible(visible);
  }, [visible]);

  useEffect(() => {
    if (calendarRef.current && isCalendarVisible) {
      calendarRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [calendarRef.current, isCalendarVisible]);

  useOutsideClick([offerDepositDetail, offerDepositDetailSpan], (e) => outsideClickCallback(e))
  useOutsideClick([offerDepositDetailMonthly, offerDepositDetailMonthlySpan], (e) => outsideClickCallback(e))



  const showDeposit = (e: any) => {
        setIsDepositVisible(!isDepositVisible)
        setIsDepositVisibleMonthly(false);
    return
  }

  const showDepositMonth = (e: any) => {
    
      setIsDepositVisibleMonthly(!isDepositVisibleMOnthly)
      setIsDepositVisible(false);
    return

  }
  const onPriceChanged = (value: any) => {
    return true;
  };


  const selectOfferGrid = (offerDate: string) => {
    
    const strRooms = getRoomsParamsString(searchParams, false);
    const strDuration = getDurationParamString(searchParams);
    const strDeparturePoints = getDeparturePointsParamsString(searchParams);
    const strBoards = getBoardParamsString(searchParams);
 
    const url_dest = offer.accommodation.resort.regions[0].destinations[0].name.replaceAll(' ', '-').toLowerCase();
    const url_region = offer.accommodation.resort.regions[0].name.replaceAll(' ', '-').toLowerCase();
    const url_resort = offer.accommodation.resort.name.replaceAll(' ', '-').toLowerCase();
    const acc_name = offer.accommodation.name.replaceAll(' ', '-').toLowerCase();

    const url_i = `/flight-and-hotel/${url_dest}/${url_region}/${url_resort}/${acc_name}/${offer.accommodation.id}`;

    onOfferClick(offer.id)

    navigate(`${url_i}?${strRooms}${strDuration}${strDeparturePoints}${strBoards}&departing=${offerDate}&departingType=1&type=${type}`);
  };

  const selectOffer = (id: string) => {

    const strRooms = getRoomsParamsString(searchParams, false);
    const strDuration = getDurationParamString(searchParams);
    const strDeparturePoints = getDeparturePointsParamsString(searchParams);
    const strBoards = getBoardParamsString(searchParams);
 
    const url_dest = offer.accommodation.resort.regions[0].destinations[0].name.replaceAll(' ', '-').toLowerCase();
    const url_region = offer.accommodation.resort.regions[0].name.replaceAll(' ', '-').toLowerCase();
    const url_resort = offer.accommodation.resort.name.replaceAll(' ', '-').toLowerCase();
    const acc_name = offer.accommodation.name.replaceAll(' ', '-').toLowerCase();

    const url_i = `/flight-and-hotel/${url_dest}/${url_region}/${url_resort}/${acc_name}/${offer.accommodation.id}`;

    const uriDate = dayjs(offer.flights[0].departing).format('YYYY-MM-DD')

    onOfferClick(offer.id)
    
    navigate(`${url_i}?${strRooms}${strDuration}${strDeparturePoints}${strBoards}&departing=${uriDate}&departingType=1&type=${type}`);
  };

  const LoaderSpinner = () => {

    let bs = configuration.button_colour;
    if(configuration.spec == '6yk1y'){;
        bs = '#01447b';
    }
  
    return (
      <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
        <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
      </div>
    );
  };

  const getBoardTypes = () => {
    if (selectedBoards == null) {
      return '';
    }

    const bbParams = [];
    for (const x of selectedBoards) {
      switch (x) {
        case 5:
          bbParams.push({ label: 'All inclusive', value: 5 });
          break;
        case 4:
          bbParams.push({ label: 'Full board', value: 4 });
          break;
        case 3:
          bbParams.push({ label: 'Half board', value: 3 });
          break;
        case 2:
          bbParams.push({ label: 'Bed & breakfast', value: 2 });
          break;
        case 1:
          bbParams.push({ label: 'Self catering', value: 1 });
          break;
        case 0:
          bbParams.push({ label: 'Room only', value: 0 });
          break;
      }
    }
    return bbParams;
  };


  const getSelectedBoardArray = () => {
    if (selectedBoards == null) {
      return [];
    }

    if (selectedBoards.boardType != null) {
      const bbParams = [];
      for (const x of selectedBoards.boardType) {
        switch (x.title.toLowerCase()) {
          case 'all inclusive':
            bbParams.push({ label: 'All inclusive', value: 5 });
            break;
          case 'full board':
            bbParams.push({ label: 'Full board', value: 4 });
            break;
          case 'half board':
            bbParams.push({ label: 'Half board', value: 3 });
            break;
          case 'bed & breakfast':
            bbParams.push({ label: 'Bed & breakfast', value: 2 });
            break;
          case 'self catering':
            bbParams.push({ label: 'Self catering', value: 1 });
            break;
          case 'room only':
            bbParams.push({ label: 'Room only', value: 0 });
            break;
        }
      }
      return bbParams;
    }
    return [];
  };

  let calClass = 'holiday-card-price-calendar';

  if(offer.deposit_price == -1){
    calClass = 'holiday-card-price-calendar nopayops'
  }

  let calView = null;

  if(isCalendarVisible && datepickerData != null){
    calView = <div className={calClass} ref={calendarRef}>
        <PriceDatepicker
          searchType={0}
          value={calendarSelectedDate}
          disable={false}
          datePrices={isTotalPriceFlag ? datepickerData.totalPrices : datepickerData.prices}
          availableMonths={availableDatePickerMonths}
          onChange={(val) => selectCalOffer(val)}
          onMonthChange={(val) => {
            setSelectedMonthAndYear(val);
          }}
        />

        <button
          onClick={() => setIsCalendarVisible(false)}
          className='holiday-card-price-calendar__close'
          aria-label='Close Datepicker'
        >
          <img src={closeIcon} alt='Close Datepicker' width={15} />
        </button>
      </div>;


  }



  if(isCalendarLoading){
    calView = <div className={calClass} ref={calendarRef}>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '350px'}}>
      {LoaderSpinner()}
    </div></div>;
  }

  let depositSection = null;
  let depositSectiondesk = null;
  let priceBtnSecClass = 'price-section-buttons only'
  let priceBtnClass = 'button price-btn';
  let wrapper = 'price-wrapper price-wrapper-s';

  if(offer.deposit_price > 0){
    priceBtnSecClass = 'price-section-buttons only'
    priceBtnClass = 'button price-btn';
    wrapper = 'price-wrapper';
    let depOverlay = null;
    let depOverlayMonthly = null;

    let arrowDepOff = 'arrow';
    let borderDep = configuration.button_colour_tertiary;
    if(isDepositVisible){
      borderDep = configuration.border_colour;
      arrowDepOff = 'arrowon';
      depOverlay = <div className='offer-deposit-detail' ref={offerDepositDetail}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px'}}>
            {LoaderSpinner()}
          </div>
      </div>

      if(depositInfoReady){

        let monthlySection = null;

        if(paymentPlanData != null && offer.payment_plan_available){
            const avgAmount = paymentPlanData.outstanding_balance / paymentPlanData.plan.length;
            monthlySection = <div className='offer-deposit-section'>
            <label>Pay monthly by Direct Debit</label>
            <div className='offer-deposit-line'>
                <span>Pay today</span>
                <span>£{offer.deposit_price_per_person.toFixed(0)} pp</span>
            </div>
            <div className='offer-deposit-line'>
                <span>{paymentPlanData.plan.length} x monthly payments</span>
                <span>£{avgAmount.toFixed(0)}</span>
            </div>
        </div>
        }

        let cn = 'offer-deposit-detail';
        if(monthlySection == null){
          cn = 'offer-deposit-detail offer-deposit-detail-s'
        }

        depOverlay =  <div className={cn} ref={offerDepositDetail}>
        <div className='offer-deposit-section'>
            <label>Pay Standard deposit</label>
            <div className='offer-deposit-line'>
                <span>Pay today</span>
                <span>£{offer.deposit_price_per_person.toFixed(0)} pp</span>
            </div>
            <div className='offer-deposit-line'>
                <span>Pay final balance</span>
                <span>{dayjs(offer.deposit_due).format('Do MMM YYYY')}</span>
            </div>
        </div>

        {monthlySection}

        <div className='offer-deposit-section'>
            <label>Pay full amount</label>
            <div className='offer-deposit-line'>
                <span>Balance</span>
                <span>£{offer.price_per_person.toFixed(0)} pp</span>
            </div>
        </div>
      </div>;
      }
    }

    let arrowDepMonthOff = 'arrow';
    let borderDepmonth = configuration.button_colour_tertiary;
    if(isDepositVisibleMOnthly){
      borderDepmonth = configuration.border_colour;
      arrowDepMonthOff = 'arrowon';
      depOverlayMonthly = <div className='offer-deposit-detail monthly' ref={offerDepositDetailMonthly}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px'}}>
        {LoaderSpinner()}
      </div>
  </div>

      if(depositInfoReady){

    let monthlySection = null;

    if(paymentPlanData != null && offer.payment_plan_available){
        const avgAmount = paymentPlanData.outstanding_balance / paymentPlanData.plan.length;
        monthlySection = <div className='offer-deposit-section'>
        <label>Pay monthly by Direct Debit</label>
        <div className='offer-deposit-line'>
            <span>Pay today</span>
            <span>£{offer.deposit_price_per_person.toFixed(0)} pp</span>
        </div>
        <div className='offer-deposit-line'>
            <span>{paymentPlanData.plan.length} x monthly payments</span>
            <span>£{avgAmount.toFixed(0)}</span>
            </div>
        </div>
        }
      depOverlayMonthly = <div className='offer-deposit-detail monthly' ref={offerDepositDetailMonthly}>{monthlySection}</div>;
      }
    }

    let ddico = ddLogo;
    if(configuration.icon_set == 'blue'){
      ddico = ddLogob;
    }

    const depositSectionChilds = [];
    const depositSectiondeskChilds = [];

    depositSectionChilds.push(<div id='deposit_st' ref={offerDepositDetailSpan} onClick={(e) => showDeposit(e)} style={{borderColor: borderDep, display: 'flex', alignItems: 'center', marginBottom: '10px', height:'38px'}} className='rounded-border'>
        <span id='deposit_st_a' className='deposit_price_text'>£{offer.deposit_price_per_person.toFixed(0)} pp deposit</span>
        <i id='deposit_st_b' className={arrowDepOff} style={{borderColor: configuration.border_colour}} title="arrow icon"></i>
        {depOverlay}
      </div>)

depositSectiondeskChilds.push(<div  id='deposit_st' ref={offerDepositDetailSpan} onClick={(e) => showDeposit(e)}  style={{borderColor: borderDep, display: 'flex', alignItems: 'center', marginRight: '20px'}} className='rounded-border'>
  <span id='deposit_st_a' className='deposit_price_text'>£{offer.deposit_price_per_person.toFixed(0)} pp deposit</span>
  <i id ='deposit_st_b' className={arrowDepOff} style={{borderColor: configuration.border_colour}} title="arrow icon"></i>
  {depOverlay}
</div>)

    if(offer.payment_plan_available){

      depositSectionChilds.push(<div id='deposit_month' ref={offerDepositDetailMonthlySpan} onClick={(e) => showDepositMonth(e)}  style={{borderColor: borderDepmonth, display: 'flex', alignItems: 'center', height:'38px'}} className='rounded-border'>
      <span id='deposit_month_a' className='deposit_price_text'>Pay monthly by</span>
      <span id='deposit_month_b'><img id='deposit_month_c' src={ddico} style={{width: '75px', height:'26px'}}  alt='Direct Debit Guarantee' /></span>
      <i id ='deposit_month_d' className={arrowDepMonthOff} style={{borderColor: configuration.border_colour}} title="arrow icon"></i>
      {depOverlayMonthly}
    </div>)

    depositSectiondeskChilds.push(<div id='deposit_month' ref={offerDepositDetailMonthlySpan} onClick={(e) => showDepositMonth(e)} style={{borderColor: borderDepmonth, display: 'flex', alignItems: 'center'}} className='rounded-border'>
    <span id='deposit_month_a' className='deposit_price_text'>Pay monthly by</span>
    <span id='deposit_month_b'><img id='deposit_month_c' src={ddico} style={{width: '75px', height:'26px'}}  alt='Direct Debit Guarantee' /></span>
    <i id ='deposit_month_d' className={arrowDepMonthOff} style={{borderColor: configuration.border_colour}} title="arrow icon"></i>
    {depOverlayMonthly}
    </div>)

    }


    depositSection = <div className='deposit_mobile price-wrapper'>
      {depositSectionChilds}
    </div>

  depositSectiondesk = <div className='deposit_desk price-wrapper'>
      {depositSectiondeskChilds}
    </div>


  }

  return (
    <>
    <div className='price-section'>
      <div className={wrapper}>
        {offer.discount ? <span className='price'>{`£${offer.price.toFixed(2)} pp`}</span> : null}

        <span className='sale-price'>{salePrice}</span>

        {depositSectiondesk}
      </div>


      {depositSection}


      <div className={priceBtnSecClass}>
        <button
          type='button'
          className={priceBtnClass}
          onClick={() => calendarDisplay(!isCalendarVisible)}
          style={{borderColor: configuration.button_colour_tertiary, backgroundColor: configuration.button_colour_tertiary, color: configuration.button_text_tertiary_colour}}
        >
          <img src={calendarLines} alt='calendar' width={15} />
          Best prices
        </button>

        {calView}


          <button
          type='button'
          className={priceBtnClass}
          onClick={() => selectOffer(id)}
          style={{borderColor: configuration.button_colour, backgroundColor: configuration.button_colour, color: configuration.button_text_colour}}
        >

          View deal
        </button>

      </div>

    </div>
    {/* <div className='price-section' style={{justifyContent: 'center'}}>
      <div className='price-wrapper'>

      </div>
    </div> */}
    </>
  );
};

export default HolidayCardPrice;

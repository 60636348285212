import { useEffect, useState, FunctionComponent } from 'react';
import './FooterBlockyHays.scss';
import abtaImg from '../assets/footerBlocky/ABTA_White.svg'
import atolImg from '../assets/footerBlocky/atol_white.svg';
import facebookImg from '../assets/footerBlocky/facebook.svg';
import instaImg from '../assets/footerBlocky/instagram.svg';
import mapImg from '../assets/footerBlocky/map-pin.svg';
import personImg from '../assets/footerBlocky/person.svg';
import phoneImg from '../assets/footerBlocky/phone.svg';
import tikImg from '../assets/footerBlocky/tiktok.svg';
import tubeImg from '../assets/footerBlocky/youtube.svg';
import whatsAppImg from '../assets/footerBlocky/whatsapp.svg';
import xTwitImg from '../assets/footerBlocky/x.svg';

import ezyLogoImg from '../assets/footerBlocky/easy-jet-landscape.svg';
import tuiLogoImg from '../assets/footerBlocky/tui-wo.svg';
import royalLogoImg from '../assets/footerBlocky/royal-caribbean.svg';
import jet2LogoImg from '../assets/footerBlocky/jet2-holidays-wo.svg';
import poLogoImg from '../assets/footerBlocky/pno-logo-wo.svg';

interface FooterBlockyHaysProps {
  userToken: string
  userConfiguration: any
}

const FooterBlockyHays:  FunctionComponent<FooterBlockyHaysProps> = ({
  userToken,
  userConfiguration
}) => {


    return(<footer className="hays-footer">
        {/* <div className="hays-footer__banner">
          <div className="hays-footer__banner-container">
            <form className="hays-footer__signup-form">
              <div className="hays-footer__signup-text">
                <h2>GET EXCLUSIVE OFFERS AND MUCH MORE</h2>
                <div>Sign up to your emails today...</div>
              </div>
  
              <button type="submit" className="hays-footer__button">Sign up</button>
            </form>
          </div>
        </div> */}

        <div className="hays-footer__main-block">
          <div className="hays-footer__container">

            <div className="hays-footer__grid">

              <div className="hays-footer__info-column">
                <h2 className="hays-footer__info-title">Speak to one of our expert advisors</h2>
  
                <div className="hays-footer__info-content">
                  <p>
                    Whether you're look for a short UK Break, a sunny Package
                    Holiday, All Inclusive Getaway or something else entirely, our
                    team will help you book the holiday of your dreams.
                  </p>
                </div>
  
                <div className="hays-footer__contact-details">
                  <ul>
                    <li>
                      <div className="hays-footer__icon-wrap">
                        <img
                          className="hays-footer__info-icon"
                          src={phoneImg}
                          alt="Telephone"
                          width="24"
                          height="24"
                        />
                      </div>
                      <a href="tel:08004084048">0800 408 4048 </a>
                    </li>
  
                    <li>
                      <div className="hays-footer__icon-wrap">
                        <img
                          className="hays-footer__info-icon"
                          src={mapImg}
                          alt="Map Pin"
                          width="24"
                          height="24"
                        />
                      </div>
                      <a href="https://www.haystravel.co.uk/branches/">Branch Finder </a>
                    </li>
  
                    <li>
                      <div className="hays-footer__icon-wrap">
                        <img
                          className="hays-footer__info-icon"
                          src={personImg}
                          alt="Person"
                          width="24"
                          height="24"
                        />
                      </div>
                      <a href="https://www.haystravel.co.uk/personal-travel-consultants/">Find a Travel Consultant </a>
                    </li>
                  </ul>
                </div>
   
              </div>
  
              <div className="hays-footer__links-column">
                <h2 className="hays-footer__link-title">Hays Travel</h2>
                <ul className="hays-footer__links">
                  <li><a href="https://www.haystravel.co.uk/about-us/">About Us</a></li>
                  <li><a href="https://www.haystravel.co.uk/about-us/awards/">Awards</a></li>
                  <li><a href="https://www.haystravel.co.uk/contact-us/">Contact Us</a></li>
                  <li><a href="https://www.haystravel.co.uk/about-us/why-hays-travel/">Why Hays Travel</a></li>
                  <li>
                    <a href="https://www.haystravel.co.uk/blog" target="">Hays Travel Blog</a>
                  </li>
                  <li>
                    <a href="https://www.haystravel.co.uk/foundation/home">Hays Foundation</a>
                  </li>
                  <li>
                    <a href="https://www.haystravel.co.uk/about-us/financial-protection/">Financial Protection</a>
                  </li>
                  <li>
                    <a href="https://www.haystravel.co.uk/business-travel">Business Travel</a>
                  </li>
                  <li><a href="https://careers.haystravel.co.uk/">Careers</a></li>
                  <li>
                    <a
                      href="https://uk.trustpilot.com/review/www.haystravel.co.uk">Customer Reviews</a>
                  </li>
                  <li><a href="https://www.haystravel.co.uk/support/">Support</a></li>
                </ul>
              </div>

              <div className="hays-footer__links-column">
                <h2 className="hays-footer__link-title">Policies</h2>
                <ul className="hays-footer__links">
                  <li><a href="https://www.haystravel.co.uk/about-us/package-rights/">Package Rights</a></li>
                  <li><a href="https://www.haystravel.co.uk/about-us/booking-conditions/">Booking Conditions</a></li>
                  <li><a href="https://www.haystravel.co.uk/about-us/privacy-policy//">Privacy Policy</a></li>
                  <li><a href="https://www.haystravel.co.uk/about-us/cookies-policy/">Cookies Policy</a></li>
                  <li>
                    <a
                      href="https://www.haystravel.co.uk/mediaLocal/gzcljpjs/modern-slavery-statement.pdf"
                      target="_blank"
                      >Modern Slavery Policy</a>
                  </li>
                  <li>
                    <a
                      href="https://www.haystravel.co.uk/mediaLocal/fwfmr1sd/605ab66a-6754-11ee-8519-14481fe59f9c.pdf"
                      >Safeguarding Policy</a>
                  </li>
                  <li>
                    <a
                      href="https://www.gov.uk/foreign-travel-advice"
                      target="_blank"
                      >Foreign Travel Advice</a>
                  </li>
                  <li>
                    <a href="https://travelaware.campaign.gov.uk" target="_blank"
                      >Advice for traveling abroad</a>
                  </li>
                </ul>
              </div>
  
              <div className="hays-footer__info-column">
                <h2 className="hays-footer__info-title">Total peace of mind</h2>
  
                <div className="hays-footer__info-icons">
                  <img
                    className="hays-footer__info-icon"
                    src={abtaImg}
                    alt="ABTA"
                    width="144"
                    height="40"
                  />
  
                  <img
                    className="hays-footer__info-icon"
                    src={atolImg}
                    alt="ATOL"
                    width="40"
                    height="40"
                  />
                </div>
  
                <div className="hays-footer__info-content">
                  <p>
                    Packages that include a flight element are financially
                    protected by the ATOL scheme. The ATOL protection does not
                    apply to all holiday and travel services. Please ask us to
                    confirm what protection may apply to your booking. If you do
                    not receive an ATOL certificate, then the booking will not be
                    ATOL protected. If you do receive an ATOL Certificate but all
                    the parts of your trip are not listed on it, those parts will
                    not be ATOL protected.
                  </p>
  
                  <p>
                    For more information about financial protection and the ATOL
                    Certificate visit: <a href="http://www.atol.org.uk">http://www.atol.org.uk</a>
                  </p>
                </div>
              </div>
            </div>
  

            <div className="hays-footer__our-partners">
            <span className="hays-footer__our-partners-title">Our partners</span>

            <img
              className="hays-footer__our-partners-icon"
              src={ezyLogoImg}
              alt="EasyJet"
            />

            <img
              className="hays-footer__our-partners-icon"
              src={tuiLogoImg}
              alt="TUI"
            />

            <img
              className="hays-footer__our-partners-icon"
              src={royalLogoImg}
              alt="Royal Caribbean"
            />

            <img
              className="hays-footer__our-partners-icon"
              src={jet2LogoImg}
              alt="Jet2"
            />

            <img
              className="hays-footer__our-partners-icon"
              src={poLogoImg}
              alt="P&O Cruises"
            />
          </div>

            <div className="hays-footer__legal-and-social">
              <div className="hays-footer__social-links">
                <a href="https://www.facebook.com/HaysTravel/">
                  <img
                    className="hays-footer__social-icon"
                    src={facebookImg}
                    alt="Facebook"
                  />
                </a>
  
                <a href="https://www.instagram.com/haystravel/">
                  <img
                    className="hays-footer__social-icon"
                    src={instaImg}
                    alt="Instagram"
                  />
                </a>
  
                <a href="https://www.tiktok.com/@hays_travel">
                  <img
                    className="hays-footer__social-icon"
                    src={tikImg}
                    alt="TikTok"
                  />
                </a>
  
                <a href="https://www.youtube.com/@HaysTravelLtd">
                  <img
                    className="hays-footer__social-icon"
                    src={tubeImg}
                    alt="YouTube"
                  />
                </a>

                <a href="https://api.whatsapp.com/send/?phone=448002155961&text=Subscribe&type=phone_number&app_absent=0">
                <img
                  className="hays-footer__social-icon"
                  src={whatsAppImg}
                  alt="WhatsApp"
                />
              </a>

              <a href="https://x.com/HaysTravel">
                <img
                  className="hays-footer__social-icon"
                  src={xTwitImg}
                  alt="X"
                />
              </a>
  

              </div>
  
              <div className="hays-footer__legal">
                <p>
                  Hays Travel is a trading name of Hays Travel Limited and is
                  registered with UK Companies House with registered number
                  01990682 Gilbridge House, Keel Square, Sunderland, Tyne and Wear
                  SR1 3HA © 2024
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>);


}

export default FooterBlockyHays;
/* eslint-disable camelcase */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './BookingConfirmed.scss';
import { SummaryCard } from '../../components/SummaryCard/SummaryCard';
import { FlightsCard } from '../../components/SummaryCard/FlightsCard/FlightsCard';
import { ExtrasCard } from '../../components/SummaryCard/ExtrasCard/ExtrasCard';

import docIconDefault from '../../components/assets/icons/default/09/noun-policies-2774558-222222.svg';
import docIcon from '../../components/assets/document.svg';

import hotelIconDefault from '../../components/assets/icons/default/09/noun-hotel-3918347-222222.svg';
import hotelIcon from '../../components/assets/hotel.svg';

import planeDefault from '../../components/assets/icons/default/09/noun-plane-4216230-222222.svg';
import plane from '../../components/assets/plane.svg';

import plusDefault from '../../components/assets/icons/default/09/noun-plus-2178969-222222.svg';
import plus from '../../components/assets/plus.svg';

import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';

import { fbqPurchase } from '../../hooks/fb';

import { sessionGetSearchParams } from '../../services/search';
import { adultCountFromRooms, childCountFromRooms, childCountFromRoomsExInfants } from '../../services/pax';

import { purchaseObject, purchase } from '../../services/ggle';
import { getCookie, cookie } from '../../services/session';

import { Room, Flight, Transfer, Session, SearchSelectionRoom } from '../../hooks/types';

import {
  flightFromBooking,
  allRoomsFromBooking,
  transferFromBooking,
  getAccommodation,
  bookingMailDone,
  siteLog,
  bookingMailOut
} from '../../hooks/useRequest';
import dayjs from 'dayjs';

// @ts-ignore
import gtag from 'ga-gtag'

interface BookingConfirmedProps {
  bookingData: Session;
  searchedRooms: SearchSelectionRoom[];
}

const DynamicBookingConfirmed: FunctionComponent<BookingConfirmedProps> = ({
  bookingData,
  searchedRooms
}) => {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [searchParams] = useSearchParams();
  const [bookingRequested, setBookingRequest] = useState<boolean>(false);

  const navigate = useNavigate();
  
  const [bookingState, setBookingState] = useState<any>(false);

  const [accNameLoc, setAcc] = useState<any>(false);

  const [roomsState, setRooms] = useState<Room[]>([]);
  const [flightState, setFlightState] = useState<Flight>();
  const [transfer, setTransfer] = useState<Transfer>();

  const [luggageState, setLuggageState] = useState<any>(false);

  useEffect(() => {
    sessionStorage.removeItem('session_cr');
    if (!bookingRequested) {

      const getHotelData = async () => {
        const roomData: Room[] = [];
        const sessionId = searchParams.get('session');
        const bookingId = searchParams.get('bid');

        if (sessionId == null) {
          return roomData;
        }

        if (bookingId == null) {
          return roomData;
        }

        const nBId = Number(bookingId);
        
        // const hotelData = await getAccommodationNameLocation()

        let accId = 0;  
        
     
        const results = await allRoomsFromBooking(userToken, sessionId, nBId);
        if (results?.rooms_from_booking?.result) {
          const basketRooms = results?.rooms_from_booking?.result;

          for (const x of basketRooms) {
            accId = x.accommodation_id;
            roomData.push(x);
          }

          let acc = null;
          if (accId != 0) {
            const accResult = await getAccommodation(userToken, accId);
            if (accResult.accommodation != null) {
              acc = accResult.accommodation.result;
              const accName = acc.name;
              const accLoc =
                acc.resort.name +
                ', ' +
                acc.resort.regions[0].name +
                ', ' +
                acc.resort.regions[0].destinations[0].name;
              setAcc({ accName, accLoc });
            }
          }

          setRooms(roomData);
        }  
        
        return roomData;
      };

      const getFlightsData = async (productId: number, extras: Record<number, number>) => {
        
        let fd: Flight | null = null;
        const sessionId = searchParams.get('session');
        const bookingId = searchParams.get('bid');

        if (sessionId == null) {
          return fd;
        }
        if (bookingId == null) {
          return fd;
        }

        const nBId = Number(bookingId);

        const results = await flightFromBooking(userToken, sessionId, productId, nBId);

        if (results?.flight_from_booking?.result) {
          const basketFlight = results.flight_from_booking.result;

          setFlightState(basketFlight);

          const extrasState: any = [];
          if (Object.keys(extras).length > 0) {
            Object.keys(extras).forEach((k: any) => {
              const found = basketFlight.extras.find((i: any) => {
                return Number(i.id) === Number(k);
              });

              if (found) {
                extrasState.push({
                  title: found.description,
                  quanity: extras[k],
                });
              }
            });

            setLuggageState(extrasState);
          }

          fd = results?.flight_from_booking?.result;
        }
        return fd;
      };

      const getTransferData = async (productId: number) => {

        let td: Transfer | null = null;
        const sessionId = searchParams.get('session');
        const bookingId = searchParams.get('bid');

        if (sessionId == null) {
          return td;
        }

        if (bookingId == null) {
          return td;
        }
        const nBId = Number(bookingId);
        const results = await transferFromBooking(userToken, sessionId, productId, nBId);
        if (results?.transfer_from_booking?.result) {
          setTransfer(results.transfer_from_booking.result);
          td = results?.transfer_from_booking?.result;
        }

        return td;
      };
     
      const getBookingData = async () => {
        // if (!userSession.sessionId) {
        //   return navigate('/');
        // }

        const sessionId = searchParams.get('session');
        const bookingId = searchParams.get('bid');

        if (sessionId == null) {
          return navigate('/');
        }
        if (bookingId == null) {
          return navigate('/');
        }

          const passengers = bookingData.basket?.people
            ?.map((passenger: any) => {
              return `${passenger.first_name} ${passenger.second_name}`;
            })
            .join('\n');

          let bookingRef = null;
          if (bookingData.basket != null) {
            bookingRef = bookingData.basket.booking?.reference;
            if (bookingRef == '') {
              bookingRef = bookingData.basket.booking?.id;
            }
          }
      

          setBookingState({
            reference: bookingRef,
            bookingDate: dayjs(bookingData.basket?.booking?.date_of_booking).format(
              'DD/MM/YYYY',
            ),
            passengerNames: passengers,
          });

          let flightProductID = 0;
          let hotelProductID = 0;
          let transferProductID = 0;
          let [hotelStartDate, hotelEndDate] = '';

          const extras: Record<number, number> = {};

   
          bookingData.basket?.products?.forEach((product: any) => {
            if (product.product_type.id === 1) {
              flightProductID = product.id;
            } else if (product.product_type.id === 0) {
              hotelProductID = product.id;
              hotelStartDate = product.start_date;
              hotelEndDate = product.end_date;
            } else if (product.product_type.id === 2) {
              if (extras[product.id]) {
                extras[product.id] = extras[product.id] + 1;
              } else {
                extras[product.id] = 1;
              }
            } else if (product.product_type.id == 3) {
              transferProductID = product.id;
            }
          });

          let fd: Flight|null = null;
          if (flightProductID != 0){
            fd = await getFlightsData(flightProductID, extras);
          }
          let rd: Room[] = [];
          if (hotelProductID != 0){
            rd = await getHotelData();
          }

          let td: Transfer|null = null;
          if (transferProductID != 0){
            td = await getTransferData(transferProductID);
          } 

          let aid = 0;
          for(const x of rd){
            aid = x.accommodation_id
          }
    
          let acc = null;
          if(aid != null){
            const accresponse = await getAccommodation(userToken, Number(aid));
            if(accresponse.accommodation.result != null){
              acc = accresponse.accommodation.result;
            }
          }

          if(rd != null && fd != null){
            const cin = dayjs(fd.arrival).format('YYYY-MM-DD');
            const cout = dayjs(fd.return_departing).format('YYYY-MM-DD');

            if(searchedRooms != null && acc != null){
              const adults = adultCountFromRooms(searchedRooms);
              const children = childCountFromRoomsExInfants(searchedRooms);

              fbqPurchase(acc.resort.name, acc.resort.regions[0].name, acc.resort.regions[0].destinations[0].name, cin, cout, acc.id, adults , children, 
                bookingData.basket.price);
            }

            if (bookingData.basket.booking != null && acc != null){

              await bookingMailOut(userToken, sessionId, bookingData.basket.booking.id);

              if(bookingData.basket.booking.confirmed == 1){

                let doGoogle = false;
                // const val = await bookingMailDone(userToken, bookingData.basket.booking.id) as any;
                // if(val == null){
                //   doGoogle = false;
                // }
                // if(val.booking_maildone.result != null){
                //   if(val.booking_maildone.result == 1){
                //     doGoogle = false;
                //   }
                // }
                const ggle = getCookie('ggl' + bookingId, false);
                if(ggle == null){
                  doGoogle = true;
                }

                if(doGoogle){
                  const po = purchaseObject(bookingData.basket, acc, rd, fd, td);
                  const strPo = JSON.stringify(po);
                  siteLog(userToken, 'GA4', strPo, '',-1, -1, 0, sessionId, 'sending ga4 dp');
                  purchase(bookingData.basket, acc, configuration ,rd, fd, td);
                  cookie('ggl' + bookingId, 1, false, 5);
                }

              }
            }


          }
        
        setBookingRequest(true);
      };

      getBookingData();
    }
  }, []);



let docIco = docIconDefault;
let hotelIco = hotelIconDefault;
let flightIco = planeDefault;
let plusIco = plusDefault;
if(configuration.icon_set == 'blue'){
  docIco = docIcon;
  hotelIco = hotelIcon;
  flightIco = plane;
  plusIco = plus;
}

  let extraSec = null;
  if (luggageState || transfer != null) {
    extraSec = (
      <div className='booking-summary-list--item'>
        <ExtrasCard title={'Extras'} icon={plusIco} luggage={luggageState} transfer={transfer} />
      </div>
    );
  }

  let accSec = null;
  if (roomsState != null) {
    const rData = [];
    for (const r of roomsState) {
      let strAdlt = r.adults + ' adults';
      if (r.adults == 1) {
        strAdlt = r.adults + ' adult';
      }
      let strChild = '';
      if (r.children > 0) {
        strChild = ' ' + r.children + ' children';
        if (r.children == 1) {
          strChild = ' ' + r.children + ' child';
        }
      }

      rData.push(
        <div className='summary-card-list--item'>
          <span>
            Room {r.room_number} ({strAdlt}
            {strChild})
          </span>
          <span className='summary-card-value'>
            {r.description} ({r.board_description})
          </span>
        </div>,
      );
    }

    accSec = (
      <div style={{borderColor: configuration.border_colour_2}} className='summary-card-wrapper'>
        <div className='summary-card-wrapper--header'>
          <img src={hotelIco} alt='Section icon' />
          <span className={'card-title'}>Accommodation</span>
        </div>

        <div className='summary-card-wrapper--body'>
          <div className='summary-card-list'>
            <div className='summary-card-list--item'>
              <span>Name</span>
              <span>{accNameLoc.accName}</span>
            </div>
            <div className='summary-card-list--item'>
              <span>Destination</span>
              <span>{accNameLoc.accLoc}</span>
            </div>

            {rData}
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className='booking-confirmed-page-wrapper--body'>
      <div className='booking-summary-list'>
        {bookingState && (
          <div className='booking-summary-list--item'>
            <SummaryCard title={'Booking Summary'} icon={docIco} data={bookingState} />
          </div>
        )}
        {<div className='booking-summary-list--item'>{accSec}</div>}
        {flightState && (
          <div className='booking-summary-list--item'>
            <FlightsCard title={'Flights'} icon={flightIco} flight={flightState} />
          </div>
        )}
        {extraSec}
      </div>
    </div>
  );
};

export default DynamicBookingConfirmed;

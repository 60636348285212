  export function clearSessionKeys(keys: string[]){
    for(const x of keys){
        sessionStorage.removeItem(x);
    }
  }

  export function checkSessionKeyExists(key: string){
    const x = sessionStorage.getItem(key);
    if(x == null){
        return false;
    }
    return true;
  }


  export function cookie(name:string,value:any,isJson: boolean,exdays:number){

	if(isJson)
	{
		value = JSON.stringify(value);
	}

	var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
	var expires = "expires="+ d.toUTCString();
	document.cookie = name + "=" + value + ";" + expires + "; path=/";
}

export function expire_cookie(name:string){
	var d = new Date();
	var expires = "expires="+ d.toUTCString();
	document.cookie = name + "=-1;" + expires + "; path=/";
}

export function getCookie(cname:string,isJson: boolean){
	var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {

			try {
				if(isJson)
				{
					return JSON.parse(c.substring(name.length, c.length));
				}
				else{
					return c.substring(name.length, c.length);
				}
			}
			catch(err){
				console.log(err);
			}

        }
    }
    return null;
}
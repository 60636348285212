import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import './PaymentOptions.scss';

import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

type PaymentOptionsProps = {
  offerId?: string;
  depositPrice: number | null;
  depositPerPerson: number | null
  depositDue: string | null;
  pricePlan: any
  live: boolean
};

type Options = { title: string; items: { title: string; value: string }[] }[];

export function PaymentOptions({ depositPrice, depositPerPerson, depositDue, pricePlan, live=false}: PaymentOptionsProps) {

  dayjs.extend(AdvancedFormat)
  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const [options, setOptions] = useState<Options>([]);
  
  useEffect(() => {
   

      const plans: any[] = [];

      let tf = 0;
      if(live){
        tf = 2;
      }

      if (depositPrice !== -1) {
        
        if(depositPrice != 0){
          plans.push({

            title: 'Pay standard deposit',
            items: [
              {
                title: 'Due now',
                value: `£${depositPerPerson?.toFixed(tf)} pp`,
              },
              {
                title: 'Pay final balance',
                value: depositDue
                  ? dayjs(depositDue).format('Do MMMM YYYY')
                  : '-',
              },
            ],
          })
        }


        if(pricePlan != null){

            const avgMonth = pricePlan.outstanding_balance / pricePlan.plan.length

            const option = {
              title: 'Pay monthly by Direct Debit',
              items: [
                {
                  title: 'Pay today',
                  value: `£${depositPerPerson?.toFixed(tf)} pp`,
                }
              ],
            };
            option.items.push({title: `${pricePlan.plan.length} x monthly payments`, value: `£${avgMonth.toFixed(tf)}`})
            plans.push(option);
        }

      } 

      setOptions(plans);
  }, [pricePlan]);

  if(options.length == 0){
    return null;
  }


  return (
    <div style={{borderColor: configuration.jrny.basket.border_colour}}  className='payment-options'>


        <div className='payment-options__content'>
          {options.map((option) => {
            return (
              <div className='payment-options__option' key={option.title}>
                <b className='payment-options__option-title'>{option.title}</b>

                {option.items.map((item) => {
                  return (
                    <div className='payment-options__option-item' key={item.title}>
                      <span>{item.title}</span>
                      <span>{item.value}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

    </div>
  );
}

import React, { FunctionComponent, useState, useEffect } from 'react';
import { getAddress } from '../../hooks/useRequest';
import { add, noop } from 'lodash';
import Select, { StylesConfig } from 'react-select';
import PassengerInformation from './PassengerInformation';
import { Option } from '../Autocomplete';
import { Button } from '../Button';

import personIcon from '../assets/person.svg';
import personDefault from '../assets/icons/default/07/Passengers.svg';

import attention from '../assets/attention.svg';
import attentionDefault from '../assets/icons/default/07/Attention.svg';

import './LeadPassengerInformation.scss';

import { isStringEmpty, match } from '../../services/str';

import { newLeadCustomer, capturePersonEmail } from '../../services/pax';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { LeadCustomer, Person } from '../../hooks/types';




interface LeadPassengerInformationProps {
  sessionId: string;
  isFormValid?: boolean;
  leadCustomer: LeadCustomer | null;
  formHasBeenSubmit: boolean
  onUpdate: (leadPax: LeadCustomer) => void;
}

export interface Address {
  addressline1: string;
  addressline2: string;
  addressline3: string;
  addressline4: string;
  postcode: string;
  posttown: string;
}

const LeadPassengerInformation: FunctionComponent<LeadPassengerInformationProps> = ({
  sessionId,
  isFormValid,
  leadCustomer,
  formHasBeenSubmit,
  onUpdate,
}) => {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  const [checked, setChecked] = useState<boolean>(false);

  const [postcode, setPostcode] = useState<string>('');
  const [errPostCode, setErrPostCode] = useState<boolean>(false)

  const [address1, setAddress1] = useState<string>('');
  const [errAddress1, setErrAddress1] = useState<boolean>(false)

  const [address2, setAddress2] = useState<string>('');

  const [address3, setAddress3] = useState<string>('');
  const [errAddress3, setErrAddress3] = useState<boolean>(false)

  const [address4, setAddress4] = useState<string>('');
  const [errAddress4, setErrAddress4] = useState<boolean>(false)

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [errEmail, setErrEmail] = useState<boolean>(false)

  const [emailAddressVerify, setEmailAddressVerify] = useState<string>('');
  const [errEmailVer, setErrEmailVer] = useState<boolean>(false)

  const [leadPax, setLeadPax] = useState<LeadCustomer>(leadCustomer || newLeadCustomer())

  const [addresses, setAddressOptions] = useState<any>(null)

  const [formSubmit, setFormSubmit] = useState<boolean>(formHasBeenSubmit);

  useEffect(() => {
    if (leadCustomer == null) {
      return;
    }
    setAddress1(leadCustomer.address1);
    setAddress2(leadCustomer.address2);
    setAddress3(leadCustomer.address3);
    setAddress4(leadCustomer.address4);
    setPostcode(leadCustomer.post_code);
    setEmailAddress(leadCustomer.email_address);
    setEmailAddressVerify(leadCustomer.email_address);

    if (leadCustomer.marketing_permission == 1) {
      setChecked(true);
    }

    setLeadPax(leadCustomer);

  }, [leadCustomer]);

  useEffect(() => {
    setFormSubmit(formHasBeenSubmit);
  }, [formHasBeenSubmit])

  useEffect(() => {

    if(!formSubmit){
      return
    }

    if(isStringEmpty(address1)){
      setErrAddress1(true);
    }
    else{
      setErrAddress1(false);
    }

    if(isStringEmpty(address3)){
      setErrAddress3(true);
    }
    else{
      setErrAddress3(false);
    }

    if(isStringEmpty(address4)){
      setErrAddress4(true);
    }
    else{
      setErrAddress4(false);
    }    
    
    if(isStringEmpty(postcode)){
      setErrPostCode(true);
    }
    else{
      setErrPostCode(false);
    }

    if(isStringEmpty(emailAddress)){
      setErrEmail(true);
    }
    else{
      setErrEmail(false);
    }

    if(isStringEmpty(emailAddressVerify)){
      setErrEmailVer(true);
    }
    else{
      setErrEmailVer(false);
    }

    if(!match(emailAddress, emailAddressVerify)){
      setErrEmail(true);
      setErrEmailVer(true);
    }
   
  }, [formSubmit])


  const handleEmailChange = (value: string) => {

    if(isStringEmpty(value)){
      setErrEmail(true);
    }
    else{
      setErrEmail(false);

      if(!match(value, emailAddressVerify)){
        setErrEmailVer(true);
        setErrEmail(true);
        leadPax.email_address = ''  
        onUpdate(leadPax)
        return;
      }
      else{
        setErrEmailVer(false);
        setErrEmail(false);
      }

    }
    setEmailAddress(value);

    leadPax.email_address = value;
    setLeadPax(leadPax);
    onUpdate(leadPax)
  };

  const handleEmailVerifyChange = (value: string) => {

    if(isStringEmpty(value)){
      setErrEmailVer(true);
    }
    else{
      setErrEmailVer(false);

      if(!match(emailAddress, value)){
        setErrEmailVer(true);
        setErrEmail(true);
        leadPax.email_address = ''  
        onUpdate(leadPax)
        return;
      }
      else{
        setErrEmailVer(false);
        setErrEmail(false);
      }
    }

    setEmailAddressVerify(value);
    leadPax.email_address = value;
    setLeadPax(leadPax);
    onUpdate(leadPax)
  };


  const handleMarketingChange = (value: boolean) => {

    setChecked(value);
    if(value){
      leadPax.marketing_permission = 1;
    }
    else{
      leadPax.marketing_permission = 0;
    }

    setLeadPax(leadPax);
    onUpdate(leadPax)
  }


  const handleAddress1Change = (option: string) => {

    if(isStringEmpty(option)){
      setErrAddress1(true);
    }
    else{
      setErrAddress1(false);
    }

    setAddress1(option);
    leadPax.address1 = option
    setLeadPax(leadPax);
    onUpdate(leadPax)
  };

  const handleAddress2Change = (option: string ) => {
    setAddress2(option);

    leadPax.address2 = option
    setLeadPax(leadPax);
    onUpdate(leadPax)
  };

  const handleAddress3Change = (option: string) => {

    if(isStringEmpty(option)){
      setErrAddress3(true);
    }
    else{
      setErrAddress3(false);
    }

    setAddress3(option);

    leadPax.address3 = option
    setLeadPax(leadPax);
    onUpdate(leadPax)
  };

  const handleAddress4Change = (option: string) => {

    if(isStringEmpty(option)){
      setErrAddress4(true);
    }
    else{
      setErrAddress4(false);
    }

    setAddress4(option);

    leadPax.address4 = option
    setLeadPax(leadPax);
    onUpdate(leadPax)
  };

  const handlePostCodeChange = (option: string) => {

    if(isStringEmpty(option)){
      setErrPostCode(true);
    }
    else{
      setErrPostCode(false);
    }

    setPostcode(option);

    leadPax.post_code = option
    setLeadPax(leadPax);
    onUpdate(leadPax)
  };

  const addressLookUp = async (postcode: string) => {
     const addressOptions = await getAddress(userToken, postcode);

     if(addressOptions == null){
        return
     }

     if(addressOptions.address_lookup.result == null){
      return
     }

     setAddressOptions(addressOptions.address_lookup.result);
  }

  const onBaseUpdate = async (person: Person) => {
    leadPax.title = person.title;
    leadPax.first_name = person.first_name;
    leadPax.second_name = person.second_name;
    leadPax.date_of_birth = person.date_of_birth;
    setLeadPax(leadPax);
    onUpdate(leadPax);
  }

  const onContactNumberUpdate = (contactNumber: string) => {
    leadPax.contact_telephone = contactNumber;
    setLeadPax(leadPax);
    onUpdate(leadPax);
  }

  const onAddressSelect = (selectedOption: any) => {

     if(selectedOption == null){
      return
     }
     if(selectedOption.value == null){
      return
     }

     if(selectedOption.value.addressline1 != null && selectedOption.value.addressline1 != ''){
      setAddress1(selectedOption.value.addressline1)
      leadPax.address1 = selectedOption.value.addressline1;
     }

     if(selectedOption.value.addressline2 != null && selectedOption.value.addressline2 != ''){
      setAddress2(selectedOption.value.addressline2)
      leadPax.address2 = selectedOption.value.addressline2;
     }

     if(selectedOption.value.posttown != null && selectedOption.value.posttown != ''){
      setAddress3(selectedOption.value.posttown)
      leadPax.address3 = selectedOption.value.posttown;
     }
     else if(selectedOption.value.addressline3 != null && selectedOption.value.addressline3 != ''){
      setAddress3(selectedOption.value.addressline3)
      leadPax.address3 = selectedOption.value.addressline3;
     }

     if(selectedOption.value.addressline4 != null && selectedOption.value.addressline4 != ''){
      setAddress4(selectedOption.value.addressline4)
      leadPax.address4 = selectedOption.value.addressline4;
     }
     else if(selectedOption.value.addressline3 != null && selectedOption.value.addressline3 != ''){
      setAddress4(selectedOption.value.addressline3)
      leadPax.address4 = selectedOption.value.addressline3;
      
     }

     if(selectedOption.value.postcode != null){
      setPostcode(selectedOption.value.postcode)
      leadPax.post_code = selectedOption.value.postcode;
     }

     onUpdate(leadPax)

  }

  const getLabel = (address: Address) => {
    return (
      <div>
        <div>{address.addressline1}</div>
        <div>{address.addressline2}</div>
        <div>{address.addressline3}</div>
        <div>{address.addressline4}</div>
        <div>{address.postcode}</div>
        <div>{address.posttown}</div>
      </div>
    );
  };

  const getOptionsFromAddresses = (addresses: any) => {
    return addresses.map((address: any) => {
      return { value: address, label: getLabel(address) };
    });
  };

  const cbStyle = {borderColor: configuration.border_colour_2, backgroundColor: ''}
  if(checked){
    cbStyle.backgroundColor = configuration.button_colour;
  }

  let personIco = personDefault;
  let attenIco = attentionDefault;
  if(configuration.icon_set == 'blue'){
    personIco = personIcon;
    attenIco = attention;
  }

  let emailClass = 'select-label passenger-email';
  if(errEmail){
    emailClass = 'select-label passenger-email error';
  }  
  
  let emailClaemailVerClassss = 'select-label passenger-email';
  if(errEmailVer){
    emailClaemailVerClassss = 'select-label passenger-email error';
  }

  let postCodeClass = 'select-label';
  if(errPostCode){
    postCodeClass = 'select-label error';
  }

  let address1Class = 'select-label passenger-email';
  if(errAddress1){
    address1Class = 'select-label passenger-email error';
  }

  let address3Class = 'select-label passenger-email';
  if(errAddress3){
    address3Class = 'select-label passenger-email error';
  }

  let address4Class = 'select-label passenger-email';
  if(errAddress4){
    address4Class = 'select-label passenger-email error';
  }
  return (
    <div>
      <div className='lead-passenger-title'>
        <img src={personIco} width='22' alt='person' />
        <span className='lead-passenger-title-text'>Lead Passenger Details</span>
      </div>
      <div className='passenger-attention'>
        <span className='passenger-attention-text'>
          Please ensure that all details match those on your passport
        </span>
        <img src={attenIco} width='22' alt='attention' />
      </div>
      <PassengerInformation
        sessionId={sessionId}
        index={0}
        pTitle={leadCustomer?.title || null}
        pFirstName={leadCustomer?.first_name || ''}
        pSecondName={leadCustomer?.second_name || ''}
        pDateOfBirth={leadCustomer?.date_of_birth || ''}
        pContactNumber={leadCustomer?.contact_telephone || ''}
        isFormValid={isFormValid}
        age={-1}
        isLead={true}
        isChild={false}
        onUpdate={(p) => onBaseUpdate(p)}
        onContactNumberUpdate={(cn) => onContactNumberUpdate(cn)}
        formHasBeenSubmit={formHasBeenSubmit}
    
      />

      <div className='passenger-main-info-desktop'>
        <label className={emailClass} aria-required>
          Email address*
          <div className='passenger-input-wrapper'>
            <input
              style={{borderColor: configuration.border_colour_2}}
              className='passenger-info-input'
              type='email'
              name='email'
              id='email'
              onChange={(e) => {
                handleEmailChange(e.target.value);
                setEmailAddress(e.target.value);
              }}
              required
              value={emailAddress}
            />
            <span className='error-message'>Please enter your email address</span>
          </div>
        </label>
        <label className={emailClaemailVerClassss} aria-required>
          Confirm email address*
          <div className='passenger-input-wrapper'>
            <input
              style={{borderColor: configuration.border_colour_2}}
              className='passenger-info-input'
              type='email'
              name='confirmEmail'
              id='confirmEmail'
              onChange={(e) => {
                handleEmailVerifyChange(e.target.value);
                setEmailAddressVerify(e.target.value);
              }}
              required
              onBlur={(e) => {
                capturePersonEmail(leadPax, sessionId, userToken);
              }}
              value={emailAddressVerify}
            />
          </div>
        </label>
      </div>
      <div className='checkbox-wrapper'>
        <div
          className='autocomplete-custom-checkbox-container'
          
          onClick={(e) => {
            handleMarketingChange(!checked);
          }}
        >
          <input
            style={cbStyle}
            className='autocomplete-custom-checkbox'
            type='checkbox'
            checked={checked}
            name='newsletter'
          />
          <span style={cbStyle} className='autocomplete-custom-checkbox-checkmark'></span>
        </div>
        <input
          type='hidden'
          id={'marketing'}
          value={checked ? checked.toString() : 'false'}
        ></input>

        <span className='text-above-checkbox'>
          Please tick, if you`d like to opt in to receive exclusive deals, offers and money off
          vouchers via our newsletter.
        </span>
      </div>
      <div className='postcode-wrapper'>
        <label className={postCodeClass} aria-required>
          Postcode*
          <div className='passenger-input-wrapper passenger-postcode'>
            <input
              style={{borderColor: configuration.border_colour_2}}
              className='passenger-info-input'
              type='text'
              value={postcode}
              onChange={(e) => {
                handlePostCodeChange(e.target.value);
                setPostcode(e.target.value);
              }}
              name='postcode'
              id='postcode'
              required
            />
            <span className='error-message'>Please enter your postcode</span>
            <Button primary label='Find address' onClick={() => addressLookUp(postcode)} borderColor={configuration.button_colour} backgroundColor={configuration.button_colour} />
          </div>
        </label>
      </div>

      {addresses? (
        <div className='addresses-select'>
          <Select
            menuShouldBlockScroll={false}
            options={getOptionsFromAddresses(addresses)}
            onChange={(option) => onAddressSelect(option as Option)}
            name='postcode2'
          />
        </div>
      ) : null}

      <div className='passenger-main-info-desktop'>
        <div className='address-fields-wrapper'>
          <label className={address1Class} aria-required>
            Address line 1*
            <div className='passenger-input-wrapper'>
              <input
                style={{borderColor: configuration.border_colour_2}}
                className='passenger-info-input'
                name='address1'
                id='address1'
                onChange={(e) => {
                  handleAddress1Change(e.target.value);
                }}
                value={address1}
                required
              />
              <span className='error-message'>Please enter your address1</span>
            </div>
          </label>
          <label className={address3Class} aria-required>
            Town / City*
            <div className='passenger-input-wrapper'>
              <input
                style={{borderColor: configuration.border_colour_2}}
                className='passenger-info-input'
                name='address3'
                id='address3'
                onChange={(e) => {
                  handleAddress3Change(e.target.value);
                }}
                value={address3}
                required
                autoComplete='off'
              />
              <span className='error-message'>Please enter your town / city</span>
            </div>
          </label>
        </div>
        <div className='address-fields-wrapper'>
          <label className='select-label passenger-email'>
            Address line 2 (optional)
            <div className='passenger-input-wrapper'>
              <input
                style={{borderColor: configuration.border_colour_2}}
                className='passenger-info-input'
                name='address2'
                id='address2'
                onChange={(e) => {
                  handleAddress2Change(e.target.value);
                }}
                value={address2}
              />
            </div>
          </label>
          <label className={address4Class} aria-required>
            County*
            <div className='passenger-input-wrapper'>
              <input
                style={{borderColor: configuration.border_colour_2}}
                className='passenger-info-input'
                name='address4'
                id='address4'
                onChange={(e) => {
                  handleAddress4Change(e.target.value);
                }}
                value={address4}
                required
              />
              <span className='error-message'>Please enter your county</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default LeadPassengerInformation;

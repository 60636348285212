import { FunctionComponent, useEffect, useMemo, useState  } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { noop } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    sessionRestore,
    markSessionStopComms
  } from '../../hooks/useRequest';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { isStringEmpty } from '../../services/str';
import { Modal } from '../../components/Modal';
import dayjs from 'dayjs';

interface SessionRestoredProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

export const LoaderSpinner = () => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  let bs = configuration.button_colour;
  if(configuration.spec == '6yk1y'){;
      bs = '#01447b';
  }

  return (
    <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
      <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
    </div>
  );
};

export const SessionRestorePage: FunctionComponent<SessionRestoredProps> = ({
  onLogin = noop,
  openMenu = noop,
}) => {


  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [isExpired, setExpired] = useState<boolean>(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }


  const query = useQuery();

  const navigate = useNavigate();

  useEffect(() => {

    const ini = async() => {

        sessionStorage.removeItem('session_cr');

        let sessionReference = '';
        let customerReference = ''
        if (query.get('s')) {
            sessionReference = query.get('s') || '';
        }
        if (query.get('crf')) {
            customerReference = query.get('crf') || '';
        }

        if(isStringEmpty(sessionReference) || isStringEmpty(customerReference)){
            //Fire expire message
            return;
        }

        const sd = await sessionRestore(userToken, sessionReference, customerReference);

        if(sd == null){
            //Fire expire message
            expireMessage();
            return;
        }

        if(sd.session_restore.result == null){
            //Fire expire message
            expireMessage();
            return;
        }

        // const x = 500;
        // if(x == 500){
        //     //Fire expire message
        //     setExpired(true);
        //     return;
        // }
        
        const sessionObj = sd.session_restore.result;
        const basket = sd.session_restore.result.basket;

        const people = basket.people;
        const leadPax = basket.lead_person;

        let flightId = 0;
        for(const p of basket.products){
            if(p.product_type.id == 5){
                //it is package
                //process package version
                break;
            }
            if(p.product_type.id == 1){
              // DP flight
              flightId = p.id;
            }
        }

        let selectPage = 'select';
        if(sd.session_restore.result.selections.trip_type == 2){
          // Package
          selectPage = 'package-select';
        }

        
        sessionStorage.setItem('session_id', sessionReference);
        sessionStorage.setItem('session_cr', dayjs().toISOString())

        if(people.length == 0){
            //direct to earlier step in the journey
            navigate('/' + selectPage + '?session=' + sessionReference+ '&ref=' + customerReference);
            return;
        }

        if(leadPax == null){
            //direct to earlier step in the journey
            navigate('/' + selectPage +'?session=' + sessionReference+ '&ref=' + customerReference);
            return;
        }

        //check people if all entered (all valid proceed to payment form
        if(isStringEmpty(leadPax.first_name) || isStringEmpty(leadPax.second_name) || isStringEmpty(leadPax.date_of_birth) || 
        isStringEmpty(leadPax.contact_telephone) || isStringEmpty(leadPax.email_address) || 
        isStringEmpty(leadPax.address1) || isStringEmpty(leadPax.address3) || isStringEmpty(leadPax.address4) || isStringEmpty(leadPax.post_code)){
            //direct to earlier step in the journey
            navigate('/' + selectPage + '?session=' + sessionReference+ '&ref=' + customerReference);
            return;

        }

        for(const p of people){
            if(isStringEmpty(leadPax.first_name) || isStringEmpty(leadPax.first_name) || isStringEmpty(leadPax.first_name))
            {
                //direct to earlier step in the journey
                navigate('/' + selectPage  + '?session=' + sessionReference+ '&ref=' + customerReference);
                return;
            }
        }

  
        navigate('/payment?session=' + sessionReference+ '&ref=' + customerReference);
    }
    ini();
  }, []);

  const expireMessage = async() => {

    let sessionId = '';
    if (query.get('si')) {
      sessionId = query.get('si') || '';
    }
    let customerReference = ''
    if (query.get('crf')) {
        customerReference = query.get('crf') || '';
    }

    await markSessionStopComms(userToken, Number(sessionId), customerReference);
    setExpired(true);
  }

  let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
  if(configuration != null){
    logoSrc = configuration.logo_file;
  }


if(isExpired){
  return(    <BaseLayout
    onSearchButtonClick={() => null}
    userToken={userToken}
    userConfiguration={configuration}
  >
    <div className='booking-confirmed-page booking-confirmed-page--failed'>
      <div className='booking-confirmed-page-wrapper'>
        <div className='booking-confirmed-page-wrapper--header'>
        <div style={{padding: '50px 50px 50px 50px'}}>
          Sorry about this ! <br></br><br></br>
          
          Unfortuantly it looks like wa can't recover your session. This is normally due to availability or the age of the session.<br></br><br></br>

          Click <a href="https://www.haystravel.co.uk">HERE</a> to return to our website or alternativly close this window.
      </div>
        </div>
      </div>
    </div>
  </BaseLayout>);
}

  return (
    <BaseLayout
      onSearchButtonClick={() => null}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='booking-confirmed-page booking-confirmed-page--failed'>
        <div className='booking-confirmed-page-wrapper'>
          <div className='booking-confirmed-page-wrapper--header'>
            <p className='booking-title booking-title--normal'>
              Just a moment ...
            </p>
            {LoaderSpinner()}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
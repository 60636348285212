import React, { FunctionComponent, useEffect, useState, ReactNode } from 'react';

import { stringArrayFromString } from '../../services/search';

import {
    getAvailableDeparturePoints
  } from '../../hooks/useRequest';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { Button } from '../Button';
import tick from '../../components/assets/tick.svg';

import { Modal } from '../Modal';

export const LoaderSpinner = () => {

    const {
      RootStore: {
        configuration,
      },
    } = useStores() as { RootStore: RootStore };
  
  
    let bs = configuration.button_colour;
  if(configuration.spec == '6yk1y'){;
    bs = '#01447b';
  }
  
    return (
      <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
        <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
      </div>
    );
  };



interface DeparturePointSelectorProps {
    isOpen: boolean;
    rooms: string, 
    selectedDeparturePoints: string[],
    duration: any, 
    boards: number[], 
    ratings: number[], 
    tripRatings: number[], 
    departureDate: string | null | undefined, 
    departureType: number,
    tripType: number,
    selectedDestinations: number[],
    selectedRegions: number[],
    selectedResorts: number[],
    accommodationID: number | null | undefined,
    onClose: () => void;
    onChange: (options: string[]) => void;
  }
  
  const DeparturePointSelector: FunctionComponent<DeparturePointSelectorProps> = ({
    isOpen = false,
    rooms, 
    selectedDeparturePoints,
    duration=7, 
    boards, 
    ratings, 
    tripRatings, 
    departureDate=null, 
    departureType=0,
    tripType=0,
    selectedDestinations,
    selectedRegions,
    selectedResorts,
    accommodationID = null,
    onClose,
    onChange
  }) => {
    
    const {
        RootStore: {
          userToken,
          configuration,
        },
      } = useStores() as { RootStore: RootStore };



    const [options, setOptions] = useState<any>(null);
    const [lastRun, setLastRun] = useState<any>(null);

    const [searchValue, setSearchValue] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    const [selectedSession, setSelectedSession] = useState<any>([]);
    const [selectedTops, setSelectedTops] = useState<any>([]);
    const [removedSession, setRemovedSession] = useState<any>([]);

    //a local state allows for instant update on selections
    const [selectedPoints, setSelectedPoints] = useState<string[]>(selectedDeparturePoints);

    
    useEffect(() =>{
        setSelectedPoints(selectedDeparturePoints);
    }, [selectedDeparturePoints])

    useEffect(() =>{


        const xEl = document.getElementById('cloudlink-chat-overlay-buttons');
        if(xEl != null){
            if(isOpen){
            
                if(xEl != null){
                xEl.style.display = 'none';
                }
            }
            else{
                if(xEl != null){
                    xEl.style.display = 'block';
                }
            }
        }
        
        if(!isOpen){
            setSearchValue('');
            setSelectedSession([]);
            setRemovedSession([]);
            return
        }


        const sd = selectedDestinations.join('-');
        const sg = selectedRegions.join('-');
        const ss = selectedResorts.join('-');
        const sr = ratings.join('-');
        const str = tripRatings.join('-');
        const sbb = boards.join('-');


        const params = { rooms, duration, boards: sbb, ratings: sr, 
            tripRatings: str, departureDate, departureType, tripType, selectedDestinations : sd, selectedRegions : sg, selectedResorts : ss }

 
        if(lastRun != null){
            if(lastRun.rooms == rooms && lastRun.duration == duration && lastRun.boards == sbb
                && lastRun.ratings == sr && lastRun.tripRatings == str && lastRun.departureDate == departureDate && lastRun.departureType == departureType && lastRun.tripType == tripType
                && lastRun.selectedDestinations == sd && lastRun.selectedRegions == sg  && lastRun.selectedResorts == ss){
                return;
            }
        }

        setIsFetching(true);

        getAvailableDeparturePoints(userToken, selectedDestinations, selectedRegions, selectedResorts, departureDate, duration, rooms, departureType, boards, ratings, tripRatings, tripType, accommodationID).then((d) => {
            setOptions(d.available_departure_points.result);
            setLastRun(params)
            setIsFetching(false);
        })

    }, [isOpen])

    const handleCloseButtonClick = () => {

        setSelectedSession([]);
        setRemovedSession([]);
        onClose();
    }

    const handleCancelButtonClick = () => {

        const xa = selectedDeparturePoints;
        const newxa = [];
        for(const sd of xa){
            let w = true;
            for(const x of selectedTops){
                if(sd == x){
                    w = false;
                    break;
                }
            }
            if(w){
                newxa.push(sd);
            }
        }

        for(const sd of removedSession){
            // let w = false;
            // for(const x of removedSession){
            //     if(sd.value == x.value){
            //         w = true;
            //         break;
            //     }
            // }
    
            newxa.push(sd);
            
        }
        onChange(newxa)

        setSelectedSession([]);
        setSelectedTops([]);
        setRemovedSession([]);
        
        onClose();


    }


    const selectOption = (code: string) => {



            let xa = selectedDeparturePoints;

            const ss = selectedSession;
            const rs = removedSession
            const st = selectedTops;
    
            const ie = xa.indexOf(code);
            if(ie!= -1){
                xa.splice(ie , 1)
            }
            else{
                xa.push(code);
                if(searchValue.trim() == ''){
                    ss.push(code);
                }
                st.push(code);
            }
    
            onChange(xa)
            setSelectedSession(ss);
            setRemovedSession(rs);
            setSelectedTops(st);
            setSearchValue('');
            setSelectedPoints(xa);
        

    }





    if(!isOpen){
        return null;
    }

    let bc = configuration.button_colour;

    let titleColour =  configuration.button_colour;
    let cancelText = configuration.button_colour;
    let primButtonColour = configuration.button_colour;
    if(configuration.button_icon == 'dark'){
      bc = configuration.border_colour_2;
      titleColour =  configuration.border_colour_2;
    }

    let selStyle = {backgroundColor: configuration.button_colour, borderColor:configuration.border_colour_2};
  
    if(configuration.spec == '6yk1y'){
      primButtonColour = configuration.button_colour;
      cancelText = '#01447b';
      bc = '#01447b';
      titleColour =  '#01447b';
      selStyle = {backgroundColor: '#01447b', borderColor:configuration.border_colour_2};
    }

    const modalHeader: ReactNode = (
        <div className='dest-header-section'>
          <h5 style={{color: configuration.text_colour}} className='dest-header-section-text'>Departure Airport </h5>
          <span className="dest-header-section-close" onClick={() => handleCloseButtonClick()} role="button"></span>
        </div>
      );

    const modalFooter: ReactNode = (
    <div className='dest-footer-section'>
        <Button label='Cancel'  backgroundColor={primButtonColour} isTransparent onClick={() => handleCancelButtonClick()} />
        <Button label='Apply' backgroundColor={primButtonColour} borderColor={primButtonColour} primary onClick={() => handleCloseButtonClick()} />
    </div>
    );
    

    const loader =<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div><div className='destsel-mobile-view'><div className='datesel-mobile-con'>{modalHeader}<div style={{display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div></div></>;

    if(isFetching){
        return(loader);
    }

    if(options == null){
        return(loader);
    }


    // return(loader);

   


    const depPoints: any[] = [];
    const htmlDests = [];

    const htmlSelDests =[];

    for(const x of options){
        // depPoints.push({'value': x.code, 'name': x.name, 'type': 'airports', 'hide': false,});
        depPoints.push(x);
    }



    depPoints.sort((a, b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
        }
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    })

    const chkStyle = {width: '24px', height: '24px', borderStyle:'solid', borderWidth: '1px', borderRadius: '2px', cursor: 'default', borderColor: '#99b4ca'};

    const lblStyle = {marginLeft: '10px', cursor:'pointer', color: configuration.text_colour};
    const chkInputStyle = {height: '0px', width: '0px', display: 'none'};
    const optionDivStyle = {paddingTop: '12px', paddingBottom: '12px', display:'flex', alignItems:'center'}


    const spanSelected = <span style={{display: 'flex', width: '24px', height: '24px', color: 'white', alignItems: 'center', justifyContent: 'center'}}><img src={tick} style={{color: 'white'}} height='20' width='20' /></span>

    for(const x of depPoints){

        let selTick = null;
        let selected = false;
        if(selectedPoints.indexOf(x.code) > -1){
            selTick = spanSelected
            selected = true;
        }

        let isSessionSel = false;
        for(const ss of selectedSession){
            if(ss == x.code){
                isSessionSel = true;
            }
        }

        if(selected && !isSessionSel){
            htmlSelDests.push(<div style={optionDivStyle}>
                <input onChange={(e) => {selectOption(x.code)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.code} name={'chkdst' + x.code} value={x.code} checked={selected} />
                <label style={chkStyle} htmlFor={'chkdst' + x.code}>{selTick}</label>
                <label style={lblStyle} htmlFor={'chkdst' + x.code}>{x.name}</label>
            </div>)
        }
        else{
            if(searchValue != ''){
                const strName = x.name.toLowerCase();
                if(strName.indexOf(searchValue.toLowerCase()) > -1){
                    htmlDests.push(<div style={optionDivStyle}>
                        <input onChange={(e) => {selectOption(x.code)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.code} name={'chkdst' + x.code} value={x.code} checked={selected} />
                        <label style={chkStyle} htmlFor={'chkdst' + x.code}>{selTick}</label>
                        <label style={lblStyle} htmlFor={'chkdst' + x.code}>{x.name}</label>
                    </div>)
                }
            }
            else{
                htmlDests.push(<div style={optionDivStyle}>
                        <input onChange={(e) => {selectOption(x.code)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.code} name={'chkdst' + x.code} value={x.code} checked={selected}  />
                        <label style={chkStyle} htmlFor={'chkdst' + x.code}>{selTick}</label>
                        <label style={lblStyle} htmlFor={'chkdst' + x.code} >{x.name}</label>
                </div>)
            }
        }
    }
    
    let inputPlace = 'Search departure airports';
    if(selectedDeparturePoints.length > 0){
        inputPlace = '+ Add another';
    }



    return(<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
        <div style={{width:'100%'}}>
            <div style={{width:'100%', marginBottom: '10px', border: '1px solid ' + configuration.border_colour_2, borderRadius: '6px'}}>
                <input autoFocus={true} style={{color: configuration.text_colour, border: 'none'}} className='datesel-input' placeholder={inputPlace} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type='text'></input>
            </div>
            {htmlSelDests}
            {htmlDests}
        </div>
    </div>
    </div>
    <div className='destsel-mobile-view'>

    <Modal
          isOpen={true}
        >
            
            <div className='datesel-mobile-con'>
            {modalHeader}
            <div style={{borderColor: configuration.border_colour_2, display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
            <div style={{width:'100%'}}>
                <div style={{width:'100%', marginBottom: '10px', border: '1px solid ' + configuration.border_colour_2, borderRadius: '6px'}}>
                    <input autoFocus={true} style={{color: configuration.text_colour, border: 'none'}} className='datesel-input' placeholder={inputPlace}  value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type='text'></input>
                </div>
                {htmlSelDests}
                {htmlDests}
            </div>
            </div>
            {modalFooter}
            </div>    

    </Modal>
    
    </div></>)



  };

  export default DeparturePointSelector;
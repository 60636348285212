import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import './DestinationSelect.scss';


import { Option } from '../Autocomplete';
import { Button } from '../Button';

import location from '../assets/location.svg';
import locationDefault from '../assets/icons/default/01/Location.svg';

import { useSearchProvider } from '../../context/search';
import classNames from 'classnames';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';

import {DestinationSelector} from '../../components/DestinationSelector'
import {DestinationSelectorPackage} from '../../components/DestinationSelectorPackage'
import { SearchSelections } from '../../hooks/types'

import { getDestination} from '../../geo_d';
import { getRegion } from '../../geo_g';
import { getResort } from '../../geo_s';

import { roomsToString } from '../../services/pax';
import { toTitleCase } from '../../services/str';

import { useOutsideClick } from '../../hooks/useOutsideClick';


interface DestinationSelectProps {
  clickApplyHandler: (selectedDestinations: number[], selectedRegions: number[], selectedResorts: number[]) => void;
  clickCancelHandler?: () => void;
  onActivate?: () => void;
  clickedSearchButtonItemName?: string;
  selectedSearchParams: SearchSelections;
  showIcons: boolean;
  showInnnerLabels?: boolean;
  validationError?: {valid: boolean, message: string};
}

const DestinationSelect: FunctionComponent<DestinationSelectProps> = ({
  clickApplyHandler,
  clickCancelHandler,
  clickedSearchButtonItemName,
  selectedSearchParams,
  onActivate,
  showIcons = false,
  showInnnerLabels = true,
  validationError = null,
}) => {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [options, setOptions] = useState<Option[]>([]);
  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);

  // const [selectedValues, setSelectedValues] = useState<any>(selectedValue); // TODO Fix typing

  // const [selectedDestinations, setSelectedDestinations] = useState<any>(selectedSearchParams.destinations);
  // const [selectedRegions, setSelectedRegions] = useState<any>(selectedSearchParams.regions);
  // const [selectedResorts, setSelectedResorts] = useState<any>(selectedSearchParams.resorts);

  const { state } = useSearchProvider();

  const sc = selectedSearchParams.destinations.length + selectedSearchParams.regions.length + selectedSearchParams.resorts.length;

  const [selectedCount, setSelectedCount] = useState<number>(sc);

  // const departureDate = getDepartureDateFromParams(selectedSearchParams, 'value');
  // const departureType = getDepartureDateFromParams(selectedSearchParams, 'label');

  let rooms = '2-'
  let duration = 7;
  let airports: string[] = [];
  let destinations: number[] = [];
  let regions: number[] = [];
  let resorts: number[] = [];
  const boards: number[] = [];
  const ratings: number[] = [];
  const tripRatings: number[] = [];

  if (selectedSearchParams != null) {
    if (selectedSearchParams.rooms != null) {
      rooms = roomsToString(selectedSearchParams.rooms);
    }

    if (selectedSearchParams.duration != null) {
      duration = selectedSearchParams.duration;
    }

    if (selectedSearchParams.boards != null) {
      for (const x of selectedSearchParams.boards) {
        boards.push(x);
      }
    }

    if (selectedSearchParams.departurePoints != null) {
      for (const x of selectedSearchParams.departurePoints) {
        airports.push(x);
      }
    }

    if (selectedSearchParams.destinations != null) {
      for (const x of selectedSearchParams.destinations) {
        destinations.push(x);
      }
    }

    if (selectedSearchParams.regions != null) {
      for (const x of selectedSearchParams.regions) {
        regions.push(x);
      }
    }

    if (selectedSearchParams.resorts != null) {
      for (const x of selectedSearchParams.resorts) {
        resorts.push(x);
      }
    }

    if (selectedSearchParams.ratings != null) {
      for (const x of selectedSearchParams.ratings) {
        ratings.push(x);
      }
    }

    if (selectedSearchParams.tripRatings != null) {
      for (const x of selectedSearchParams.tripRatings) {
        tripRatings.push(x);
      }
    }
  }

  const selectorRef = useRef<HTMLDivElement>(null);

  // const getAirportsFromParams = () => {
  //   const parsedAirports: string[] = [];

  //   if (!airports) return parsedAirports;

  //   airports.map((airport: Option, i: number) => {
  //     if (Array.isArray(airport.value)) {
  //       parsedAirports.push(...airport.value);
  //     } else {
  //       parsedAirports.push(airport.value as string);
  //     }
  //   });

  //   return [...new Set(parsedAirports)];
  // };

  const getParsedValues = (result: any, type: string) => {
    if (!result) return;

    return result.map((item: any) => {
      return { value: item.result, label: item.title, type: type, isCheck: true, imageIcon: '' };
    });
  };

  const getParsedValuesPak = (result: any, type: string) => {
    if (!result) return;

    return result.map((item: any) => {
      return { value: item.id, label: item.name, type: type, isCheck: true, imageIcon: '' };
    });
  };

  const getParsedAccValues = (result: any, type: string) => {
    if (!result) return;

    return result.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
        type: type,
        isCheck: false,
        imageIcon: 'hotel',
        resortName: item.resort.name,
        resortId: item.resort.id,
      };
    });
  };
  
  useOutsideClick([selectorRef], () => {
    setSelectExpanded(false);
  })


  useEffect(() => {
    setSelectedCount(destinations.length + regions.length + resorts.length);
  }, [destinations, regions, resorts]);

  useEffect(() => {
    if (clickedSearchButtonItemName === 'Destination' && !selectExpanded) {
      setSelectExpanded(true);
    }
  }, [clickedSearchButtonItemName]);

  // useEffect(() => {
  //   if (state.option == 2) {
  //     searchDestinationsPackage(inputText);
  //   }
  // }, [inputText]);

  const handleApplyValues = (selectedDestinations: number[], selectedRegions: number[], selectedResorts: number[], close?: boolean) => {
    // setSelectedValues(option);
    // clickApplyHandler(option, 'destinations', option.type || '');



    setSelectedCount(selectedDestinations.length + selectedRegions.length + selectedResorts.length);

    // setSelectedDestinations(selectedDestinations);
    // setSelectedRegions(selectedRegions);
    // setSelectedResorts(selectedResorts);

    destinations = selectedDestinations;
    regions = selectedRegions;
    resorts = selectedResorts;


    clickApplyHandler(selectedDestinations, selectedRegions, selectedResorts);
    if (close) setSelectExpanded(false);
  };

  const handleReset = () => {

    // setSelectedDestinations([]);
    // setSelectedRegions([]);
    // setSelectedResorts([]);

    destinations = [];
    regions = [];
    resorts = [];

    clickApplyHandler([], [], []);
    setSelectedCount(0);
  }

  const handleClose = () => {
    setSelectExpanded(false);
  }


  const getInputFieldText = () => {
    if (tripType == 2 || tripType == 3) {
      if (regions.length == 0 && resorts.length == 0) {
        return 'Select Destination';
      } else {

        if(regions.length > 0){
          let dName = toTitleCase(getRegion(regions[0]));
          return dName;
        }
        else{
          let dName = toTitleCase(getResort(resorts[0]));
          return dName;
        }
   
      }
    } else {

      let dName = 'Any Destination';

      // if (selectedDestinations && selectedDestinations.length > 0){
      //   dName = selectedDestinations[0].label;
      //   if(dName.toLowerCase() == 'usa'){
      //     dName = 'USA'
      //   }
      // }

      if(selectedCount == 0){
        return dName
      }

      if(destinations.length > 0){
        dName = toTitleCase(getDestination(destinations[0]));
        if(dName.toLowerCase() == 'usa'){
          dName = 'USA'
        }
      }
      else if(regions.length > 0){
        dName = toTitleCase(getRegion(regions[0]));
      }
      else if(resorts.length > 0){
        dName = toTitleCase(getResort(resorts[0]));
      }

      if(selectedCount == 1){
        return dName
      }
      else if(selectedCount == 2){
        return dName + ' ' + ' and 1 other';
      }
      else{
        return dName + ' + ' + (selectedCount - 1) + ' more';
      }

      // if (selectedDestinations && selectedDestinations.length === 1) {
      //   return dName;
      // } else if (selectedDestinations && selectedDestinations.length === 2) {
      //   return `${dName} and 1 other`;
      // } else if (selectedValues && selectedValues.length > 2) {
      //   return `${dName} + ${selectedValues.length - 1} More`;
      // } else {
      //   return 'Any Destination';
      // }
    }
  };

  let locIco = locationDefault;
  if(configuration.icon_set == 'blue'){
    locIco = location;
  }
  const icon = <img src={locIco} width='22' alt='destination' />;

  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Destination</h5>
    </div>
  );

  const modalFooter = (
    <div className='modal-mobile-footer'>
      <Button
        label='Cancel'
        isTransparent
        onClick={() => {
          setSelectExpanded(false);
        }}
        backgroundColor={configuration.button_colour}
      />
      <Button label='Apply' primary backgroundColor={configuration.button_colour} onClick={() => handleApplyValues(destinations, regions, resorts, true)} />
    </div>
  );

  for(const x of options){
    const lbl = x.label as string;
    let xName = lbl.split('-')[1];
    xName = toTitleCase(xName);
    if(xName == ''){
      continue
    }
    x.label = xName;
  }

  let destSelector;
  if(selectedSearchParams.searchType == 2 || selectedSearchParams.searchType == 3){
    let ap = null;
    if(airports.length > 0){
      ap = airports[0];
    }      
    
    let tripType = 0;
    if(selectedSearchParams.searchType == 3){
      tripType = 1;
    }

    destSelector = (
      <DestinationSelectorPackage isOpen={selectExpanded} rooms={rooms} selectedDeparturePoints={(airports)}  duration={duration}
      boards={boards} ratings={ratings} tripRatings={tripRatings} departureDate={selectedSearchParams.departureDate} selectedDestinations={selectedSearchParams.destinations} selectedDestinationID={[]} 
      selectedRegions={regions} selectedResorts={resorts} tripType={tripType} onChange={(g, s) => handleApplyValues([],g, s)}  onClose={() => handleClose()} />
    );
  }else{
    destSelector = (
      <DestinationSelector isOpen={selectExpanded} rooms={rooms} selectedDeparturePoints={(airports)} duration={duration}
      boards={(boards)} ratings={(ratings)} tripRatings={(tripRatings)} departureDate={selectedSearchParams.departureDate}
      departureType={selectedSearchParams.departureDateType} tripType={selectedSearchParams.searchType} selectedDestinations={destinations}
      selectedRegions={regions} selectedResorts={resorts} onChange={(d, g, s) => handleApplyValues(d, g, s)} onClose={() => handleClose()}  />
    );
  }

  let tripType = state.option;
  if (selectedSearchParams?.searchType != null) {
    tripType = selectedSearchParams.searchType;
  }

  let val = true;
  if(validationError != null){
    val = validationError.valid;
  }

  return (
    <>
      {selectExpanded && (
        <div
          onClick={() => {
            setSelectExpanded(false);
          }}
          className='destination-select-overlay'
        ></div>
      )}
      <div ref={selectorRef}
        className={classNames('search-bar-input-field-autocomplete', {
          'destination-select--wide': selectedSearchParams.searchType === 2 || selectedSearchParams.searchType == 3,
        })}
      >
        <SearchBarInputField
          valid={val}
          title='Destination'
          value={getInputFieldText()}
          focus={selectExpanded}
          configuration={configuration}
          handleReset={() => {
            handleReset()
          }}
          onClick={() => {
            setSelectExpanded(!selectExpanded);
            if (onActivate) {
              onActivate();
            }
          }}
          icon={showIcons ? icon : null}
          selectedCount={selectedCount}
          lblInside={showInnnerLabels}
        />

        {destSelector}
      </div>

    </>
  );
};

export default DestinationSelect;

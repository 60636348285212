/* eslint-disable */
export function geoDestinations() {
    return [{"id": 1, "name": "austria"}, {"id": 2, "name": "denmark"}, {"id": 3, "name": "montenegro"}, {"id": 4, "name": "norway"}, {"id": 5, "name": "sweden"}, {"id": 6, "name": "slovakia"}, {"id": 21, "name": "bulgaria"}, {"id": 48, "name": "cyprus"}, {"id": 58, "name": "egypt"}, {"id": 60, "name": "spain"}, {"id": 78, "name": "greece mainland"}, {"id": 84, "name": "croatia"}, {"id": 90, "name": "india"}, {"id": 114, "name": "morocco"}, {"id": 126, "name": "malta"}, {"id": 130, "name": "mexico"}, {"id": 152, "name": "portugal"}, {"id": 172, "name": "tunisia"}, {"id": 174, "name": "turkey"}, {"id": 186, "name": "balearics"}, {"id": 187, "name": "canaries"}, {"id": 188, "name": "greek islands"}, {"id": 189, "name": "united arab emirates"}, {"id": 190, "name": "florida"}, {"id": 191, "name": "cuba"}, {"id": 192, "name": "dominican republic"}, {"id": 193, "name": "italy"}, {"id": 195, "name": "gambia"}, {"id": 196, "name": "france"}, {"id": 197, "name": "united kingdom"}, {"id": 198, "name": "netherlands"}, {"id": 199, "name": "germany"}, {"id": 200, "name": "usa"}, {"id": 201, "name": "czech republic"}, {"id": 202, "name": "hungary"}, {"id": 203, "name": "iceland"}, {"id": 204, "name": "poland"}, {"id": 205, "name": "romania"}, {"id": 206, "name": "channel islands"}, {"id": 207, "name": "republic of ireland"}]
}

export function getDestination(id) {
    for(const x of geoDestinations()){
        if(x.id == id){
            return x.name;
        }
    }
    return ''
}
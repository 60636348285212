import React, { ReactNode, forwardRef, Ref } from 'react';
import cn from 'classnames';
import './SearchBarInputField.scss';
import { HeaderIcon } from '../HeaderIcon/HeaderIcon';

interface SearchBarInputFieldProps {
  title: string;
  additionalSearchBarInputFieldClassName?: string;
  children?: string | ReactNode;
  value?: string;
  focus?: boolean;
  onClick?: () => void;
  handleReset?: () => void;
  configuration: any;
  icon?: ReactNode;
  valid?: any;
  selectedCount?: number;
  lblInside?: boolean;
}

const SearchBarInputField = forwardRef(
  (
    {
      title,
      additionalSearchBarInputFieldClassName,
      children,
      value = '',
      focus = false,
      icon,
      configuration = null,
      handleReset,
      onClick,
      valid = null,
      selectedCount = 0,
      lblInside = true
    }: SearchBarInputFieldProps,
    ref: Ref<HTMLDivElement>,
  ) => {

    const borderStyle = {border: ''};
    const fontStyle = {color: ''}
    if(configuration != null){
      borderStyle.border = 'solid 1px ' + configuration.border_colour_2;
      fontStyle.color = configuration.text_colour;
    }

    const getValue = () =>
      value && value.length ? (
        <span style={fontStyle} className={'selected-value'}>{value}</span>
      ) : (
        <span style={fontStyle} className={'default-value'}>{children}</span>
      );

    const v = getValue();

    let classErr = ''
    let classErrHeader = '';
    let classErrText = '';

    if(valid != null){
      if(!valid){
        classErr = ' input-error'
        classErrHeader = ' input-error-head'
        classErrText = ' input-error-text'
      }
    }

    let rstBtn = null;
    if(selectedCount > 0 && handleReset != null){
      rstBtn = <span role='button' className='reset-button' onClick={handleReset}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke={configuration.border_colour_2}
            style={{ width: 24, height: 24 }}
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
          </svg>
        </span>
    }
      

    let lblExternal = null;
    let lblInner = null;

    let exConClass = '';
    if(lblInside){
      lblInner = <p className={'input-field--title' + classErrHeader}>{title}</p> ;
    }
    else{
      
      exConClass = ' exCls'
      let lblCol = 'white';
      if(configuration.search_style != null){
        lblCol = configuration.search_style.lbl_colour;

      }

      lblExternal = <p style={{color: lblCol, textTransform:'uppercase', lineHeight:'1.25rem', fontSize: '.75rem', marginBottom: '.25rem', fontWeight: '700'}} className={'external-lbl'}>{title}</p>;
    }


    return (
      <div
        ref={ref}
        className={cn({ focus: focus }, additionalSearchBarInputFieldClassName)}
        style={{ position: 'relative'}}
      >

       {lblExternal}

        <div onClick={onClick} style={borderStyle} className={'input-field-wrapper' + exConClass + classErr}>

        <div className='icon-wrapper'>{icon}</div>

          <div>
            {lblInner}
            <div className={'input-field--value' + classErrText}>{v}</div>
          </div>
  
        </div>

        {rstBtn}


       

      </div>
    );
  },
);

SearchBarInputField.displayName = 'SearchBarInputField';

export default SearchBarInputField;

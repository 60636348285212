import { FunctionComponent, useEffect, useMemo, useState  } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { noop } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    blockSessionComms,
  } from '../../hooks/useRequest';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { isStringEmpty } from '../../services/str';
import { Modal } from '../../components/Modal';
import dayjs from 'dayjs';

interface SessionBlockProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

export const LoaderSpinner = () => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  let bs = configuration.button_colour;
  if(configuration.spec == '6yk1y'){;
      bs = '#01447b';
  }

  return (
    <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
      <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
    </div>
  );
};

export const SessionBlockPage: FunctionComponent<SessionBlockProps> = ({
  onLogin = noop,
  openMenu = noop,
}) => {


  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }


  const query = useQuery();

  const [loading, setLoading] = useState<boolean>(false);
  const [block, setBlock] = useState<boolean>(false);


  useEffect(() => {
  }, []);

  const blockMail = async () => {
    
    setLoading(true);

    let sessionId = '';
    let customerReference = ''
    if (query.get('si')) {
      sessionId = query.get('si') || '';
    }
    if (query.get('crf')) {
        customerReference = query.get('crf') || '';
    }

    await blockSessionComms(userToken, Number(sessionId), customerReference)
    
    setBlock(true);
    setLoading(false);

  }

  let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
  if(configuration != null){
    logoSrc = configuration.logo_file;
  }

  let btnA =  <button className="button" onClick={() => blockMail()}>
  Confirm opt out
  </button>

  if(loading){

    btnA = LoaderSpinner();

  }
  else if(block){
    btnA = <p>You will no longer receive anymore of these emails. Thank you</p>
  }


  return (
    <BaseLayout
      onSearchButtonClick={() => null}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='booking-confirmed-page booking-confirmed-page--failed'>
        <div className='booking-confirmed-page-wrapper'>
          <div className='booking-confirmed-page-wrapper--header'>
            <p className='booking-title booking-title--normal'>
              Are you sure you want to stop us contacting you about your activity on our website ? <br></br><br></br>
              We'll only contact you if you've searched a product, sometimes we may offer discounts and promotions as well !<br></br><br></br>
              If you'd still like to opt out of these emails press the button below.
            </p>

            {btnA}

          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { bookAllProducts, handOffBooking, paymentAuth, siteConfiguration, setupPaymentPlan, } from '../../hooks/useRequest';
import { LoaderSpinner } from '../../components/Loader/Loader';
import { Logo } from '../../components/Logo/Logo';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

type PaymentResult = {
  payment_auth: {
    result: {
      completed: number;
      payment_result: string;
    };
  };
};
export function PaymentInterstitialPage() {

  const {
    RootStore: {
      userToken, 
      configuration
    },
  } = useStores() as { RootStore: RootStore };

  const query = useQuery();
  const navigate = useNavigate();
  const [status, setStatus] = useState<'loading' | 'no-params' | 'error' | 'success'>('loading');


  async function setUpDirectDebit(_bookingId: number, sessionId: string) {

    const ddDetail = sessionStorage.getItem('ddDetail');
    if(ddDetail == null){
      return
    }

    const ddDetailObj = JSON.parse(ddDetail)

    try {
      await setupPaymentPlan(
        userToken,
        sessionId,
        _bookingId,
        ddDetailObj.planID,
        ddDetailObj.accountHolder,
        '',
        ddDetailObj.accountNumber,
        ddDetailObj.sortCode,
        ddDetailObj.bankName,
        ddDetailObj.bankAddress,
        ddDetailObj.bankPostCode,
      );
    } catch (error) {
      console.log('Unable to set up direct debit', error);
    }
  }

  // Method to handle booking process
  const processBooking = async (bookingId: number, sessionId: string) => {
    try {

      const bookComs = await siteConfiguration('12', userToken)
      if(bookComs == 0){
        setStatus('success');
        navigate(`/booking-confirmed/?session=${sessionId}&bid=${bookingId}`);
        return;
      }

      await bookAllProducts(userToken, sessionId, bookingId);
      await handOffBooking(userToken, sessionId, bookingId);

      const ddDetail = sessionStorage.getItem('ddDetail');
      if(ddDetail != null){
        await setUpDirectDebit(bookingId, sessionId);
      }

    } catch (error) {
      console.log('Unable to process booking', error);
      navigate('/booking-failed?error=booking-failed');
    }
  };

  useEffect(() => {
    if (!query.get('bid') || !query.get('session') || !query.get('payment_session')) {
      setStatus('no-params');
      navigate('/');
    } else {
      const paymentSession = query.get('payment_session');

      if (paymentSession) {
        const bid = parseInt(query.get('bid') || '');
        const session = query.get('session') || '';

        paymentAuth(userToken, paymentSession)
          .then((res) => {
            const data = res as PaymentResult;
            // If we have a result...
            if (data?.payment_auth?.result?.payment_result) {
              const result = data?.payment_auth?.result?.payment_result;
              if (result === 'fail') {
                console.log('Redirect with ?error=payment-failed');
                setStatus('error');
                navigate('/booking-failed?error=payment-failed');
              } else {
                // Proceed with placing the booking, this needs moving into payment
                // once we know this works
                processBooking(bid, session).then(() => {
                  setStatus('success');
                  navigate(`/booking-confirmed/?session=${session}&bid=${bid}`);
                });
              }
            } else {
              console.log('Redirect with ?error=payment-failed');
              setStatus('error');
              navigate('/booking-failed?error=payment-failed');
            }
          })
          .catch((error) => {
            console.log('Here is the error', error);
            setStatus('error');
            navigate('/booking-failed?error=booking-failed');
          });
      }
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '2rem',
      }}
    >
      <div style={{ marginBottom: '2rem' }}>
        <Logo width={193} configuration={configuration} />
      </div>

      <LoaderSpinner />
      <p>Please wait...</p>
    </div>
  );
}

import React, { FunctionComponent, useEffect, useState, ReactNode } from 'react';
import './DestinationSelectorPackage.scss';

import {
    packageAvailableResortsRegions
  } from '../../hooks/useRequest';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { indexOf } from 'lodash';

import { Button } from '../Button';

import tick from '../../components/assets/tick.svg';

import { Modal } from '../Modal';

import { toTitleCase } from '../../services/str';

export const LoaderSpinner = () => {

    const {
      RootStore: {
        configuration,
      },
    } = useStores() as { RootStore: RootStore };
  
  
  let bs = configuration.button_colour;
  if(configuration.spec == '6yk1y'){;
    bs = '#01447b';
  }
  
    return (
      <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
        <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
      </div>
    );
  };


// export interface OptionObj {
//     value: string | number | string[];
//     label: string | number;
//     type?: string;
//     isChecked?: boolean;
//   }

interface DestinationSelectorPackageProps {
    isOpen: boolean;
    rooms: string, 
    duration: number, 
    boards: number[], 
    ratings: number[], 
    tripRatings: number[], 
    departureDate: string | null | undefined, 
    selectedDeparturePoints: string[],
    selectedDestinations: number[],
    selectedDestinationID: number[],
    selectedRegions: number[],
    selectedResorts: number[],
    tripType: number,
    onClose: () => void;
    onChange: (selectedRegions: number[], selectedResorts: number[]) => void;
  }
  
  const DestinationSelectorPackage: FunctionComponent<DestinationSelectorPackageProps> = ({
    isOpen = false,
    rooms, 
    duration=7, 
    boards=[], 
    ratings=[], 
    tripRatings=[], 
    departureDate=null, 
    selectedDeparturePoints=[],
    selectedDestinations=[],
    selectedDestinationID=[],
    selectedRegions=[],
    selectedResorts=[],
    tripType=0,
    onClose,
    onChange
  }) => {
    
    const {
        RootStore: {
          userToken,
          configuration,
        },
      } = useStores() as { RootStore: RootStore };



    const [options, setOptions] = useState<any>(null);
    const [lastRun, setLastRun] = useState<any>(null);
    // const [selectedOptions, setSelectedOptions] = useState<OptionObj[]>([]);
    const [searchValue, setSearchValue] = useState('');

    const [isFetching, setIsFetching] = useState(false);

    useEffect(() =>{


        const xEl = document.getElementById('cloudlink-chat-overlay-buttons');
        if(xEl != null){
            if(isOpen){
            
                if(xEl != null){
                xEl.style.display = 'none';
                }
            }
            else{
                if(xEl != null){
                    xEl.style.display = 'block';
                }
            }
        }
        
        if(!isOpen){
            setSearchValue('');
            return
        }

        const params = { rooms, selectedDeparturePoints, duration, boards, ratings, 
            tripRatings, departureDate}

 
        if(lastRun != null){
            if(lastRun.rooms == rooms && lastRun.selectedDeparturePoints == selectedDeparturePoints && lastRun.duration == duration && lastRun.boards == boards
                && lastRun.ratings == ratings && lastRun.tripRatings == tripRatings){
                return;
            }
        }
        
        setIsFetching(true);

        let departureAirport = null;
        if(selectedDeparturePoints.length > 0){
            departureAirport = selectedDeparturePoints[0];
        }

        packageAvailableResortsRegions(userToken, duration, departureAirport, departureDate, tripType).then((d) => {

            setOptions(d.package_available_resorts_regions.result);
            setLastRun(params)
            setIsFetching(false);

        });

    }, [isOpen])

    const handleCloseButtonClick = () => {
        onClose();
    }

    const handleCancelButtonClick = () => {
        onClose();
    }

    const selectRegion = (region: number) => {

        const xa = selectedRegions;
        const ie = xa.indexOf(region);
        if(ie!= -1){
            setSearchValue('');
            onChange([], []);
        }
        else{
            setSearchValue('');
            onChange([region], []);
            onClose();
        }
    }

    const selectResort = (resort: number) => {
        onChange([], [resort]);
        onClose();

        const xa = selectedResorts;
        const ie = xa.indexOf(resort);
        if(ie!= -1){
            setSearchValue('');
            onChange([], []);
        }
        else{
            setSearchValue('');
            onChange([], [resort]);
            onClose();
        }
    }

    // const selectOption = (isCheck: boolean, destType: string, destLbl:string, destID: string) => {

    //     if(isCheck){
    //         const o = {value: Number(destID), label: toTitleCase(destLbl), type: destType, isChecked: true, imageIcon: ''};
    //         onChange([o]);
    //         onClose();
    //     }
    //     else{

    //       const xa = selectedDestinations;
    //       let i = -1;
    //        for(const x of xa){
    //             i++;
    //             if(x == Number(destID)){
    //                 break;
    //             }
    //         }
    //         if(i != -1){
    //             xa.splice(i, 1)

    //         }

    //         onChange(xa)
            
    //     }

        
    // }

    if(!isOpen){
        return null;
    }

    let bc = configuration.button_colour;

    let titleColour =  configuration.button_colour;
    let cancelText = configuration.button_colour;
    let primButtonColour = configuration.button_colour;
    if(configuration.button_icon == 'dark'){
      bc = configuration.border_colour_2;
      titleColour =  configuration.border_colour_2;
    }

    let selStyle = {backgroundColor: configuration.button_colour, borderColor:configuration.border_colour_2};
  
    if(configuration.spec == '6yk1y'){
      primButtonColour = configuration.button_colour;
      cancelText = '#01447b';
      bc = '#01447b';
      titleColour =  '#01447b';
      selStyle = {backgroundColor: '#01447b', borderColor:configuration.border_colour_2};
    }

    const modalHeader: ReactNode = (
        <div className='dest-header-section'>
          <h5 style={{color: configuration.text_colour}} className='dest-header-section-text'>Destinations</h5>
          <span className="dest-header-section-close" onClick={() => handleCloseButtonClick()} role="button"></span>
        </div>
      );

    const modalFooter: ReactNode = (
    <div className='dest-footer-section'>
        <Button label='Cancel'  backgroundColor={primButtonColour} isTransparent onClick={() => handleCancelButtonClick()} />
        <Button label='Apply' backgroundColor={primButtonColour} borderColor={primButtonColour} primary onClick={() => handleCloseButtonClick()} />
    </div>
    );
    

    const loader =<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div><div className='destsel-mobile-view'><div className='datesel-mobile-con'>{modalHeader}<div style={{display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div></div></>;

    if(isFetching){
        return(loader);
    }

    if(options == null){
        return(loader);
    }

    const destinations: any[] = [];
    const capturedDest = [];

    const htmlDests = [];
    const htmlSelDests =[];

    // for(const x of options){
    //     destinations.push({'id': x.id, 'name': x.name, 'type': 'c', 'hide': false, 'regions': []});
    // }

    for(const x of options){
      const seg = x.split('-');
      const dstID = Number(seg[4]);
      if(indexOf(capturedDest, dstID) == -1){
          capturedDest.push(dstID);
          destinations.push({'id': dstID, 'name': seg[5], 'type': 'c', 'hide': false, 'regions': []});
      }
    }

    const capturedRegions = []
    for(const x of options){
        const seg = x.split('-');
        const gid = Number(seg[2]);
        for(const d of destinations){
            if(indexOf(capturedRegions, gid) == -1){
                if(seg[4] == d.id){
                    d.regions?.push({'id': gid, 'name': seg[3], 'type': 'g', 'hide': false, 'resorts': []})
                    capturedRegions.push(gid);
                }
            }
        }
    }

    const capturedResorts = []
    for(const x of options){
        const seg = x.split('-');
        const sid = Number(seg[0]);
        for(const d of destinations){
            for(const g of d.regions){
                if(indexOf(capturedResorts, sid) == -1){
                    if(seg[2] == g.id){
                        g.resorts?.push({'id': sid, 'name': seg[1], 'type': 's', 'hide': false})
                        capturedResorts.push(sid);
                    }
                }
            }
        
        }
    }
    destinations.sort((a, b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    })

    for(const x of destinations){
      x.regions.sort((a: any, b: any) => {
          if(a.name.toLowerCase() < b.name.toLowerCase()){
              return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
          }
          return 0;
      })
    }   
  
    for(const x of destinations){
        for(const g of x.regions){
            g.resorts.sort((a: any, b: any) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()){
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            })
        }
    }


    const chkStyle = {width: '24px', height: '24px', borderStyle:'solid', borderWidth: '1px', borderRadius: '2px', cursor: 'default', borderColor: '#99b4ca'};

    const lblStyle = {marginLeft: '10px', cursor:'pointer', color: configuration.text_colour};
    const chkInputStyle = {height: '0px', width: '0px', display: 'none'};
    const optionDivStyle = {paddingTop: '12px', paddingBottom: '12px', display:'flex', alignItems:'center'}


    const spanSelected = <span style={{display: 'flex', width: '24px', height: '24px', color: 'white', alignItems: 'center', justifyContent: 'center'}}><img src={tick} style={{color: 'white'}} height='20' width='20' /></span>

    for(const x of destinations){

    //   let selTick = null;
    //   let selected = false;
    //   if(selectedDestinationID.indexOf(Number(x.id)) > -1){
    //       selTick = spanSelected
    //       selected = true;
    //   }


    // if(searchValue != ''){
    //     const strName = x.name.toLowerCase();
    //     if(strName.indexOf(searchValue.toLowerCase()) > -1){
    //         htmlDests.push(<div style={optionDivStyle}>
    //             <input onChange={(e) => {selectRegion(0)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.id} name={'chkdst' + x.id} value={x.id} checked={selected} />
    //             <label style={chkStyle} htmlFor={'chkdst' + x.id}>{selTick} !!</label>
    //             <label style={lblStyle} htmlFor={'chkdst' + x.id}>{x.name} !!</label>
    //         </div>)
    //     }
    // }

      
      // if(searchValue == ''){
      //     continue
      // }

      for(const g of x.regions){

          let selTickRegion = null;
          let selectedReion = false;
          if(selectedRegions.indexOf(Number(g.id)) > -1){
              selTickRegion = spanSelected
              selectedReion = true;
          }


          if(selectedReion){
 
                  htmlSelDests.push(<div style={optionDivStyle}>
                  <input onChange={(e) => {selectRegion(g.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + g.id} name={'chkg' + g.id} value={g.id} checked={selectedReion} />
                  <label style={chkStyle} htmlFor={'chkg' + g.id}>{selTickRegion}</label>
                  <label style={lblStyle} htmlFor={'chkg' + g.id}>{g.name}<br></br><span style={{fontSize:'10px', color: 'grey'}}>{x.name}</span></label>
              </div>)
     
          }
          else{

              if(searchValue != ''){
                  const strDestName = x.name.toLowerCase();
                  const strName = g.name.toLowerCase();
                  if(strName.indexOf(searchValue.toLowerCase()) > -1){
                      htmlDests.push(<div style={optionDivStyle}>
                      <input onChange={(e) => {selectRegion(g.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + g.id} name={'chkg' + g.id} value={g.id} checked={selectedReion} />
                      <label style={chkStyle} htmlFor={'chkg' + g.id}>{selTickRegion}</label>
                      <label style={lblStyle} htmlFor={'chkg' + g.id}>{g.name} <br></br><span style={{fontSize:'10px', color: 'grey'}}>{x.name}</span></label>
                  </div>);
                 }
                 else{
                    if(strDestName.indexOf(searchValue.toLowerCase()) > -1){
                        htmlDests.push(<div style={optionDivStyle}>
                            <input onChange={(e) => {selectRegion(g.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + g.id} name={'chkg' + g.id} value={g.id} checked={selectedReion} />
                            <label style={chkStyle} htmlFor={'chkg' + g.id}>{selTickRegion}</label>
                            <label style={lblStyle} htmlFor={'chkg' + g.id}>{g.name} <br></br><span style={{fontSize:'10px', color: 'grey'}}>{x.name}</span></label>
                        </div>);
                    }
                 }
              }  
              else{
                htmlDests.push(<div style={optionDivStyle}>
                  <input onChange={(e) => {selectRegion(g.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + g.id} name={'chkg' + g.id} value={g.id} checked={selectedReion} />
                  <label style={chkStyle} htmlFor={'chkg' + g.id}>{selTickRegion}</label>
                  <label style={lblStyle} htmlFor={'chkg' + g.id}>{g.name} <br></br><span style={{fontSize:'10px', color: 'grey'}}>{x.name}</span></label>
              </div>)
            }
         
          }

          for(const s of g.resorts){

              let selTickResort = null;
              let selectedResort = false;
              if(selectedResorts.indexOf(Number(s.id)) > -1){
                  selTickResort = spanSelected
                  selectedResort = true;
              }

              if(selectedResort){
                  htmlSelDests.push(<div style={optionDivStyle}>
                      <input onChange={(e) => {selectResort(s.id)}} style={chkInputStyle} type="checkbox" id={'chks' + s.id} name={'chks' + s.id} value={s.id} checked={selectedResort} />
                      <label style={chkStyle} htmlFor={'chks' + s.id}>{selTickResort}</label>
                      <label style={lblStyle} htmlFor={'chks' + s.id}>{s.name}<br></br><span style={{fontSize:'10px', color: 'grey'}}>{x.name}, {g.name}</span></label>
                  </div>)
              }
              else{
                  if(searchValue != ''){
                      const strName = s.name.toLowerCase();
                      if(strName.indexOf(searchValue.toLowerCase())  > -1){
                          htmlDests.push(<div style={optionDivStyle}>
                              <input onChange={(e) => {selectResort(s.id)}} style={chkInputStyle} type="checkbox" id={'chks' + s.id} name={'chks' + s.id} value={s.id} checked={selectedResort} />
                              <label style={chkStyle} htmlFor={'chks' + s.id}>{selTickResort}</label>
                              <label style={lblStyle} htmlFor={'chks' + s.id}>{s.name}<br></br><span style={{fontSize:'10px', color: 'grey'}}>{x.name}, {g.name}</span></label>
                          </div>)
                      }
                  }
             
              }

      
          }
      }
  }

    return(<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
        <div style={{width:'100%'}}>
            <div style={{width:'100%', marginBottom: '10px', border: '1px solid ' + configuration.border_colour_2, borderRadius: '6px'}}>
                <input autoFocus={true} style={{color: configuration.text_colour, border: 'none'}} className='datesel-input'  placeholder='Search destinations' onChange={(e) => setSearchValue(e.target.value)} type='text'></input>
            </div>
            {htmlSelDests}
            {htmlDests}
        </div>
    </div>
    </div>
    <div className='destsel-mobile-view'>

    <Modal
          isOpen={true}
        >

        <div className='datesel-mobile-con'>{modalHeader}
        <div style={{borderColor: configuration.border_colour_2, display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
        <div style={{width:'100%'}}>
        <div style={{width:'100%', marginBottom: '10px', border: '1px solid ' + configuration.border_colour_2, borderRadius: '6px'}}>
                <input autoFocus={true} style={{color: configuration.text_colour, border: 'none'}} className='datesel-input' placeholder='Search destinations' onChange={(e) => setSearchValue(e.target.value)} type='text'></input>
            </div>
            {htmlSelDests}
            {htmlDests}
        </div>
    </div>
    </div>  
    </Modal>
    </div></>)

  };

  export default DestinationSelectorPackage;
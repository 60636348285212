import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import '../../../components/HolidayCard/HolidayDetails/HolidayDetails.scss';


import { FlightDetails } from '../../HolidaySummary/FlightDetails';

import calendarLinesDefault from '../../assets/icons/default/02/noun-calendar-2080587-222222.svg';
import calendarLines from '../../assets/calendarLines.svg';

import kitchenDefault from '../../assets/icons/default/02/noun-food-750655-222222.svg';
import kitchen from '../../assets/food.svg';

import flightDefault from '../../assets/icons/default/02/noun-flight-1606533-222222.svg';
import flight from '../../assets/flight.svg';

import luggageDefault from '../../assets/icons/default/02/Handluggage.svg';
import luggage from '../../assets/suitcase.svg';

import bagDefault from '../../assets/icons/default/02/Bag.svg';
import bag from '../../assets/bag.svg';

import busDefault from '../../assets/icons/default/02/Transfer.svg';
import bus from '../../assets/bus.svg';





import { useOutsideClick } from '../../../hooks/useOutsideClick';
// import OfferFlightDetails from '../../HolidayCard/HolidayDetails/OfferFlightDetails/OfferFlightDetails';
import { useStores } from '../../../hooks/useStore';
import { RootStore } from '../../../stores/RootStore';

interface PackDetailsProps {
  date: string;
  boardDescription: string;
  flightData: any;
  config: any;
}

const PackDetails: FunctionComponent<PackDetailsProps> = ({
  date,
  boardDescription,
  flightData,
  config,
}: PackDetailsProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  //   const roomsDescription = rooms?.map((room: Room) => room.board_description).join(', ');
  //   const airports = flights?.map((flight: Flight) => flight.departure_airport.name).join(', ');
  const [isFlightDetailsVisible, setIsFlightDetailsVisible] = useState<boolean>(false);

  const outsideClickCallback = () => {
    setIsFlightDetailsVisible(false);
  };

  const offerFlightRef = useRef<HTMLDivElement>(null);
  const flightsWisiblebtnRef = useRef<HTMLDivElement>(null);
  useOutsideClick([offerFlightRef, flightsWisiblebtnRef], outsideClickCallback);

  useEffect(() => {
    if (offerFlightRef.current) {
      offerFlightRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [offerFlightRef.current]);


  let calLinesIco = calendarLinesDefault;
  let kitchenIco = kitchenDefault;
  let flightIco = flightDefault;
  let bagIco = bagDefault;
  let luggageIco = luggageDefault;
  let busIco = busDefault;
  
  if(config.icon_set == 'blue'){
    calLinesIco = calendarLines;
    kitchenIco = kitchen;
    flightIco = flight;
    bagIco = bag;
    luggageIco = luggage;
    busIco = bus;
  }

  return (
    <div style={{borderColor: configuration.card_border_colour}} className='holiday-details-wrapper holiday-details-wrapper--grid'>
      <div>
        <div className='holiday-details--item'>
          <img src={calLinesIco} alt='' />
          <span>{date}</span>
        </div>
        <div className='holiday-details--item'>
          <img src={kitchenIco} alt='' />
          <span>{boardDescription}</span>
        </div>
        <div className='holiday-details--item'>
          <img className={'flight'} src={flightIco} alt='' />
          {isFlightDetailsVisible && (
            <div ref={offerFlightRef}>
         

     

<div className={'offer-flight-details-wrapper'}>
          <FlightDetails 
                flightType={'Outbound'}
                airline={'Jet2'}
                flightNumber={flightData.number}
                departureTime={flightData.outbound_departure}
                departurePlace={flightData.departure_point.name}
                arrivalPlace={flightData.arrival_airport.name}
                arrivalTime={flightData.outbound_arrival}
                outBound={true}
                config={config} />
           <FlightDetails flightType={'Inbound'}
                airline={'Jet2'}
                flightNumber={flightData.return_number}
                departureTime={flightData.inbound_departure}
                departurePlace={flightData.arrival_airport.name}
                arrivalPlace={flightData.departure_point.name}
                arrivalTime={flightData.inbound_arrival}
                outBound={false}
                config={config} />
          </div>


            </div>
          )}
          <span>
            {flightData.departure_point.name} -{' '}
            <span
              ref={flightsWisiblebtnRef}
              onClick={() => setIsFlightDetailsVisible(!isFlightDetailsVisible)}
              className='flight-details'
            >
              Flight Details
            </span>
          </span>
        </div>
      </div>

      <div>
        <div className='holiday-details--item'>
          <img src={bagIco} alt='' />
          <span>22kg Bag Allowance</span>
        </div>

        <div className='holiday-details--item'>
          <img src={luggageIco} alt='' />
          <span>10kg Hand Baggage</span>
        </div>
        <div className='holiday-details--item'>
          <img src={busIco} alt='' />
          <span>Coach Transfers</span>
        </div>
      </div>
    </div>
  );
};

export default PackDetails;

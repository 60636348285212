import React, { FunctionComponent, useRef, useEffect, useState } from 'react';
import './Datepicker.scss';
import { SearchBarInputField } from '../SearchBarInputField';
import { Toggle } from '../Toggle/Toggle';
import { MonthPicker } from '../MonthPicker/MonthPicker';
import { Modal } from '../Modal';

import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'

import calendar from '../assets/calendar.svg';
import calendarDefault from '../assets/icons/default/01/Calendar.svg';

import { PriceDatepicker } from '../PriceDatepicker';
import { Button } from '../Button';

import {
  availableDays,
  availableMonths,
  packageAvailableDays,
  packageAvailableMonths,
} from '../../hooks/useRequest';

import { useLocationQuery } from '../../hooks/useQuery';
import { useSearchProvider } from '../../context/search';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

import { SearchSelections } from '../../hooks/types'

import { roomsToString } from '../../services/pax'

import { useOutsideClick } from '../../hooks/useOutsideClick';


type Prices = {
  [date: string]: number;
};

interface DatepickerProps {
  datePrices?: Prices;
  onChange?: () => void;
  onActivate?: () => void;
  availableDates?: string[];
  searchItemType?: string;
  selectedParams: SearchSelections;
  clickedSearchButtonItemName?: string;
  clickApplyHandler: (option: string | null) => void;
  clickApplyHandlerMonth: (option: string | null) => void;
  showIcons: boolean;
  showInnnerLabels?: boolean;
  validationError?: {valid: boolean, message: string};
}

const Datepicker: FunctionComponent<DatepickerProps> = ({
  datePrices,
  selectedParams,
  clickedSearchButtonItemName,
  clickApplyHandler,
  clickApplyHandlerMonth,
  searchItemType,
  onActivate,
  showIcons = false,
  showInnnerLabels = true,
  validationError = null,
}) => {

  dayjs.extend(AdvancedFormat)

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const selectorRef = useRef<HTMLDivElement>(null);

  const query = useLocationQuery();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState<string | null>('');

  const [date, setDate] = useState<dayjs.Dayjs | null>();
  const [isCalendar, setIsCalendar] = useState(true);

  const [activeDate, setSelDate] = useState<dayjs.Dayjs | null>(null);

  const [availableDaysData, setAvailDays] = useState<any>(null);
  const [availableMonthsData, setAvailMonths] = useState<any>(null);

  const [selectedSearchParams, setSelectedSearchParams] = useState<SearchSelections>(selectedParams);


  useOutsideClick([selectorRef], () => {
    setIsCalendarOpen(false);
  })

   useEffect(() => {

    setSelectedSearchParams(selectedParams);

    if (selectedParams?.departureDate == null) {
      if (tripType == 2 || tripType == 3) {
        setSelectedDate('Select Date');
      } else {
        setSelectedDate('Any Date');
      }
    }
    else{
      const d = dayjs(selectedParams?.departureDate)
      setDate(d)
    }

  }, [selectedParams.departureDate])


  let rooms = '2-';
  let actDate = dayjs();
  let actDateStr = 'Any Date';
  let duration = 7;
  const airports: any[] = [];
  const destinations: any[] = [];
  const regions: any[] = [];
  const resorts: any[] = [];
  const boards: any[] = [];
  const ratings: any[] = [];
  const tripRatings: any[] = [];

  const { state } = useSearchProvider();

  if (selectedSearchParams != null) {
    if (activeDate == null) {
      if (selectedSearchParams.departureDate != null) {
        actDate = dayjs(selectedSearchParams.departureDate);
        if (selectedSearchParams.departureDateType == 0) {
          actDateStr = actDate.format('Do MMM YYYY');
        } else {
          actDateStr = actDate.format('MMM YYYY');
        }
      } else {
        actDate = dayjs();
      }
    } else {
      actDate = activeDate;
    }

    if (selectedSearchParams.rooms != null) {
      rooms = roomsToString(selectedSearchParams.rooms);
    }

    if (selectedSearchParams.duration != null) {
      duration = selectedSearchParams.duration;
    }

    if (selectedSearchParams.boards != null) {
      for (const x of selectedSearchParams.boards) {
        boards.push(x);
      }
    }

    if (selectedSearchParams.departurePoints != null) {
      for (const x of selectedSearchParams.departurePoints) {
        airports.push(x);
      }
    }

    if (selectedSearchParams.destinations != null) {
      for (const x of selectedSearchParams.destinations) {
          destinations.push(x);
      }
    }

    if (selectedSearchParams.regions != null) {
      for (const x of selectedSearchParams.regions) {
          regions.push(x);
      }
    }

    if (selectedSearchParams.resorts != null) {
      for (const x of selectedSearchParams.resorts) {
          resorts.push(x);
      }
    }

    if (selectedSearchParams.ratings != null) {
      for (const x of selectedSearchParams.ratings) {
        ratings.push(x);
      }
    }

    if (selectedSearchParams.tripRatings != null) {
      for (const x of selectedSearchParams.tripRatings) {
        tripRatings.push(x);
      }
    }
  }

  const getPackageAvailMonths = async () => {

    let tt = 0;
    if(tripType == 3){
      tt = 1;
    }

    return await packageAvailableMonths(userToken ,Number(duration), regions, resorts, airports[0], tt);
  };

  const getPackageAvailDays = async () => {
    let rg = -1;
    let res = -1;

    if (resorts.length > 0) {
      res = resorts[0];
    } else {
      if (regions.length > 0) {
        rg = regions[0];
      }
    }

    let tt = 0;
    if(tripType == 3){
      tt = 1;
    }

    return await packageAvailableDays(
      userToken,
      actDate.get('month') + 1,
      actDate.get('year'),
      Number(duration),
      regions,
      resorts,
      airports[0],
      tt
    );
  };

  const getAvailMonths = async () => {
    return await availableMonths(
      userToken,
      rooms,
      (airports),
      duration,
      (destinations),
      (regions),
      (resorts),
      (boards),
      (ratings),
      (tripRatings),
      selectedSearchParams.searchType,
    );
  };

  const getAvailDays = async () => {
    return await availableDays(
      userToken,
      rooms,
      actDate.get('month') + 1,
      actDate.get('year'),
      (airports),
      duration,
      (destinations),
      (regions),
      (resorts),
      (boards),
      (ratings),
      (tripRatings),
      selectedSearchParams?.searchType || state.option,
    );
  };


  useEffect(() => {
    if (clickedSearchButtonItemName === 'Date' && !isCalendarOpen) {
      setIsCalendarOpen(true);
    }
  }, [clickedSearchButtonItemName]);

  useEffect(() => {
    if (selectedSearchParams?.departureDate == null) {
      if (tripType == 2 || tripType == 3) {
        setSelectedDate('Select Date');
        setIsCalendar(true);
      } else {
        setSelectedDate('Any Date');
      }
      setDate(null);
    }
  }, [selectedSearchParams.searchType]);

  useEffect(() => {


      if(isCalendarOpen){
    const xEl = document.getElementById('cloudlink-chat-overlay-buttons');
    if(xEl != null){
      xEl.style.display = 'none';
    }
  }
  else{
    const xEl = document.getElementById('cloudlink-chat-overlay-buttons');
    if(xEl != null){
      xEl.style.display = 'block';
    }
  }


    const c = document.getElementById('cloudlink-chat-overlay-buttons') as HTMLElement;


    if (!isCalendarOpen) {

      if(c != null){
        c.hidden = false;
      }

      return;
    }

    if(c != null){
      c.hidden = true;
    }
 
    if (tripType == 2 || tripType == 3) {
      getPackageAvailMonths().then((d: any) => {
        setAvailMonths(d.package_available_months.result);

        const selMonth = actDate.get('month') + 1;
        const selYear = actDate.get('year');

        let foundD = false;
        for (const x of d.package_available_months.result) {
          if (x.month == selMonth && x.year == selYear) {
            foundD = true;
            break;
          }
        }

        if (!foundD) {
          const strDate =
            d.package_available_months.result[0].year +
            '-' +
            d.package_available_months.result[0].month +
            '-1';
          actDate = dayjs(strDate);
        }

        getPackageAvailDays().then((d: any) => {
          setAvailDays(d.package_available_days.result);
        });
      });
    } else {
      // The local storage has been cleared, reset the values

      if (selectedSearchParams != null) {
        if (selectedSearchParams.departureDate != null) {
          const d = selectedSearchParams.departureDate;
          const dc = selectedSearchParams.departureDateType;
          const dateObject = dayjs(selectedSearchParams.departureDate);


          if (dc == 1) {
            setDate(dateObject);
            setSelectedDate(generateSelectedDateMonth(dateObject));
          } else {
            setDate(dateObject);
            setSelectedDate(generateSelectedDate(dateObject));
          }
        }
      }

      // When the option changes, refetch data
      getAvailMonths().then((d) => {
        setAvailMonths(d.available_months?.result);
      });

      getAvailDays().then((d) => {
        setAvailDays(d.available_days.result);
      });
    }
  }, [isCalendarOpen]);

  useEffect(() => {
    if (!isCalendarOpen) {
      return;
    }
    if (tripType == 2 || tripType == 3) {
      getPackageAvailDays().then((d: any) => {
        setAvailDays(d.package_available_days.result);
      });
    } else {
      getAvailDays().then((d) => {
        setAvailDays(d.available_days.result);
      });
    }
  }, [activeDate]);


  const generateSelectedDate = (date: dayjs.Dayjs | null) => {

    if(date == null){
      return ''
    }

    const sDate = date.format('Do MMM YYYY');
    return `${sDate}`;
  };

  const generateSelectedDateMonth = (date: dayjs.Dayjs | null) => {

    if(date == null){
      return ''
    }
    const sDate = date.format('MMM YYYY');
    return `${sDate}`;
  };

  const onDatepickerChange = (date: dayjs.Dayjs | null) => {

    if(date == null){
      return 
    }
    // const d = dayjs(date)
    // setDate(d);
    setSelectedDate(generateSelectedDate(date));

    setDate(date);
    clickApplyHandler(date.format('YYYY-MM-DD 00:00'));
    setIsCalendarOpen(false);
    setIsCalendar(true);
  };

  const getToggleValue = (val: boolean) => {
    setIsCalendar(!val);
  };

  const setAnyDate = () => {

    setSelectedDate('Any Date');
    clickApplyHandler(null);
    setIsCalendarOpen(false);
    setIsCalendar(true);
    setDate(null);
  }

  const onMonthSelected = (date: string, label: string) => {
    setSelectedDate(null);
    // const departureDateOption = {
    //   value: dayjs(date).format('YYYY-MM-DD 00:00'),
    //   label: 1,
    // };
    clickApplyHandlerMonth(date + ' 00:00');
    setSelectedDate(label);
    setIsCalendarOpen(false);
  };

  let calIco = calendarDefault;
  if(configuration.icon_set == 'blue'){
    calIco = calendar;
  }
  const icon = <img src={calIco} width='22' alt='calendar' />;

  useEffect(() => {
      setSelectedDate(actDateStr);
  }, []);

  useEffect(() => {
    const dropdownMonth = document.getElementsByClassName(
      'react-datepicker__month-read-view--selected-month',
    );

    if (dropdownMonth && dropdownMonth[0]) {
      (dropdownMonth[0] as HTMLElement).style.visibility = 'hidden';
      (dropdownMonth[1] as HTMLElement).style.visibility = 'hidden';
    }

    const dropdownArrow = document.getElementsByClassName(
      'react-datepicker__month-read-view--down-arrow',
    );

    if (dropdownArrow && dropdownArrow[0]) {
      (dropdownArrow[0] as HTMLElement).style.visibility = 'visible !important';
      (dropdownArrow[1] as HTMLElement).style.visibility = 'visible !important';
    }
  });

  // useEffect(() => {
  //   if (searchItemType === 'Date' && !isCalendarOpen) {
  //     setIsCalendarOpen(!isCalendarOpen);

  //     if (resetItemType) {
  //       resetItemType('');
  //     }
  //   }
  // }, [searchItemType]);

  // useEffect(() => {
  //   if (query.get('params') !== null) {
  //     const data = JSON.parse(query.get('params') as string);

  //     if (data?.departure_date) {
  //       const dateObject = dayjs(data.departure_date.value).toDate();
  //       const dc = data.departure_date.label;

  //       if (dc == 1) {
  //         setDate(dateObject);
  //         setSelectedDate(generateSelectedDateMonth(data.departure_date.value));
  //       } else {
  //         setDate(dateObject);
  //         setSelectedDate(generateSelectedDate(data.departure_date.value));
  //       }
  //     }
  //   }
  // }, [query]);

  let bc = configuration.button_colour;  
  let titleColour =  configuration.text_colour;
  if(configuration.button_icon == 'dark'){
    bc = configuration.border_colour_2;
  }


  if(configuration.spec == '6yk1y'){
    bc = '#01447b';
    titleColour =  '#01447b';
  }


  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 style={{color: titleColour}} className='modal-mobile-header-text'>Select Departure Date</h5>
    </div>
  );

  const setActiveDate = (selectedDate: any) => {
    const sd = dayjs(selectedDate);
    setSelDate(sd);
  };

  let modalFooter = null;
  if(selectedSearchParams.searchType != 2 && selectedSearchParams.searchType != 3){
    modalFooter = (
      <div className='modal-mobile-footer'>
        <Button label='Any Date' backgroundColor={configuration.button_colour} isTransparent onClick={() =>  setAnyDate()} />
      </div>
    );
  }


  let tripType = 0;
  if (selectedSearchParams?.searchType != null) {
    tripType = selectedSearchParams.searchType;
  }

  let showToggle = true;
  let classPakOver = '';
  if (tripType == 2 || tripType ==3) {
    showToggle = false;
    classPakOver = ' dateNoMonthSel'
  }


  let val = true;
  if(validationError != null){
    val = validationError.valid;
  }

  let selectCount = 0;
  if(date != null){
    selectCount = 1;
  }



  return (
    <>
      {isCalendarOpen && (
        <div
          onClick={() => {
            setIsCalendarOpen(false);
          }}
          className='datepicker-overlay'
        />
      )}


      <div ref={selectorRef} style={{width:'100%'}}>
      <div  className='datepicker-wrapper'>
        <SearchBarInputField
          valid={val}
          icon={showIcons ? icon : null}
          title={'Date'}
          focus={isCalendarOpen}
          configuration={configuration}
          selectedCount={selectCount}
          handleReset={setAnyDate}
          lblInside={showInnnerLabels}
          onClick={() => {
            setIsCalendarOpen(true);

            if (onActivate) {
              onActivate();
            }
          }}
        >
          {selectedDate ? (
            <span className={'selected-date'}>{selectedDate.toString()}</span>
          ) : (
            <span></span>
          )}
        </SearchBarInputField>

        {isCalendarOpen && (
          <div style={{borderColor: configuration.border_colour_2}} className='datepicker-wrapper-inputs datepicker-large-view'>
            {showToggle && (
              <Toggle
                value={!isCalendar}
                firstLabel={'Specific date'}
                secondLabel={'Whole month'}
                onToggleChange={(toggleValue) => getToggleValue(toggleValue)}
                backgroundColor={configuration.button_colour_secondary}
                color={configuration.button_text_colour}

              />
            )}

            {isCalendar ? (
              <div style={{borderColor: configuration.border_colour_2}} className={'datepicker-component-wrapper' + classPakOver}>
                <PriceDatepicker
                  searchType={selectedSearchParams.searchType}
                  value={date}
                  selectedDate={date ? date : undefined}
                  availableDates={availableDaysData || []}
                  datePrices={datePrices}
                  disable={false}
                  onChange={(value: any) => onDatepickerChange(value)}
                  onMonthChange={(v: any) => setActiveDate(v)}
                  availableMonths={availableMonthsData || []}
                />
              </div>
            ) : (
              <MonthPicker
                selectedDate={date ? date : undefined}
                availableMonths={availableMonthsData || []}
                numberYears={3}
                onMonthClick={(month, label) => onMonthSelected(month, label)}
              />
            )}
          </div>
        )}
      </div>

      <div className='datepicker-mobile-view'>
        <Modal
          isOpen={isCalendarOpen}
          toggle={() => setIsCalendarOpen(!isCalendarOpen)}
          header={modalHeader}
          footer={modalFooter}
        >
          {showToggle && (
            <Toggle
              size={'medium'}
              value={!isCalendar}
              firstLabel={'Specific date'}
              secondLabel={'Whole month'}
              onToggleChange={(v) => getToggleValue(v)}
              backgroundColor={configuration.button_text_colour}
              color={configuration.button_text_colour}
            />
          )}

          {isCalendar ? (
            <div className={'datepicker-component-wrapper'}>
              <PriceDatepicker
                searchType={selectedSearchParams.searchType}
                value={date}
                selectedDate={date ? date : undefined}
                availableDates={availableDaysData || []}
                datePrices={datePrices}
                disable={false}
                onChange={(value: any) => onDatepickerChange(value)}
                onMonthChange={(v: any) => setActiveDate(v)}
                availableMonths={availableMonthsData || []}
              />
            </div>
          ) : (
            <div className={'datepicker-component-wrapper'}>
              <MonthPicker
                availableMonths={availableMonthsData || []}
                numberYears={3}
                onMonthClick={(month, label) => onMonthSelected(month, label)}
              />
            </div>
          )}
        </Modal>
      </div>
      </div>


    </>
  );
};

export default Datepicker;

import React, { FunctionComponent, useEffect, useState } from 'react';
import cn from 'classnames';

import AirportsSelect from './AirportsSelect';
import DestinationSelect from './DestinationSelect';
import RoomsSelect from './RoomsSelect';
import DurationSelect from './DurationSelect';
import { Datepicker } from '../Datepicker';
import './SearchBar.scss';
import { Button } from '../Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoardTypeSelect from './BoardType';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';


import flightHotelIcon from '../assets/flightHotel.svg';
import cityIcon from '../assets/city.svg';
import packageIcon from '../assets/pack.svg';

import { useLocationQuery } from '../../hooks/useQuery';

import search from '../../components/assets/search-icon.svg';
import searchDark from '../../components/assets/icons/dark/noun-magnifying-glass-202232-222222.svg';

import { fbqTrackSearch } from '../../hooks/fb';
import { searchAdd, siteConfiguration } from '../../hooks/useRequest';

import { LoaderSpinner } from '../Loader/jLoader'
import dayjs from 'dayjs';

import { SearchSelectionRoom, SearchSelections, Agent } from '../../hooks/types'

import { roomsFromPaxString } from '../../services/pax';
import { geoArrayFromString, departurePointArrayFromString, departurePointIdFromCode } from '../../services/geo';
import { sessionSetSearchParams, sessionGetSearchParams } from '../../services/search';
import { clearSessionKeys, getCookie } from '../../services/session';

interface SearchBarProps {
  isBoardType?: boolean;
  isHolidayPage?: boolean;
  isSearchPage?: boolean;
  searchItemType?: string;
  localSession?: string;
  accommodationId?: number;
  resultFilters?: any;
  showInnnerLabels?: boolean;
  packageOptions?: boolean;
  onClick?: () => any;
}



const SearchBar: FunctionComponent<SearchBarProps> = ({
  isBoardType = false,
  isSearchPage = false,
  isHolidayPage = false,
  searchItemType,
  accommodationId,
  showInnnerLabels = true,
  packageOptions = false,
  onClick
}) => {


  const {
    RootStore: {
      sessionStore: { userSession, createUserSession },
      userToken,
      configuration,
      showSearchTabs,
      searchMode,
      agent,
      affiliate,
    },
  } = useStores() as { RootStore: RootStore };

  const [searchParams] = useSearchParams();

  const [showPackageCityTab, setShowPackageCity] = useState<boolean>(true);

  let setSearchMode = -1;
  if(searchMode != -1){
    setSearchMode = searchMode;
  }

  if(configuration.search_mode != null){
    if(configuration.search_mode != -1){
      setSearchMode = configuration.search_mode;
    }
  }

  // const exA = getCookie('agt', true) as Agent;
  if(agent != -1){
    setSearchMode = 2;
  }

  if(packageOptions){
    setSearchMode = 2;    
  }



  useEffect(() => {

    let defaultSelections: SearchSelections = { 
    duration: 7, destinations: [], regions: [], resorts: [], 
    departurePoints: [], departureDate: null, departureDateType: 0, searchType: 0, ratings: [], tripRatings: [], boards: [], rooms : [{adults: 2, children: 0, childAges: []}]}

    let prev = localStorage.getItem('prevsrch');
    if(prev != null){

      let dos = localStorage.getItem('dos');
      let use = true;
      if(dos != null){
        let d = dayjs(dos);
        let diff = dayjs().diff(d, 'days');
        if(diff > 2){
          localStorage.removeItem('prevsrch');
          localStorage.removeItem('dos');
          use = false;
        }
      }

      if(use){
        defaultSelections = JSON.parse(prev) as SearchSelections;
        setSearchMode = defaultSelections.searchType;
      }
    }


    if(searchParams != null){
      if(searchParams.get('departing') != null){
        defaultSelections.departureDate = searchParams.get('departing') + ' 00:00';
      }   
      
      if(searchParams.get('departingType') != null){
        defaultSelections.departureDateType = Number(searchParams.get('departingType'));
      }
  
      if(searchParams.get('rooms') != null){
        defaultSelections.rooms = roomsFromPaxString(searchParams.get('rooms') || '');
      }
      if(searchParams.get('duration') != null){
        defaultSelections.duration = Number(searchParams.get('duration'));
      }
  
      if(searchParams.get('destinations') != null){
        defaultSelections.destinations = geoArrayFromString(searchParams.get('destinations'));
      }
  
      if(searchParams.get('regions') != null){
        defaultSelections.regions = geoArrayFromString(searchParams.get('regions'));
      }
  
      if(searchParams.get('resorts') != null){
        defaultSelections.resorts = geoArrayFromString(searchParams.get('resorts'));
      }
  
      if(searchParams.get('departurePoints') != null){
        defaultSelections.departurePoints = departurePointArrayFromString(searchParams.get('departurePoints'));
      }
  
      if(searchParams.get('type') != null){
        defaultSelections.searchType = Number(searchParams.get('type'));
      }
    }


    //use user defined
    if(setSearchMode != -1){
      defaultSelections.searchType = setSearchMode;
      if(setSearchMode == 1){
        defaultSelections.duration = 3;
      }

      if(setSearchMode == 3){
        defaultSelections.duration = 3;
      }
    }
    
    setSelectedSearchParams(defaultSelections);
    setReady(true);
  }, [searchParams])


  const [selectedSearchParams, setSelectedSearchParams] = useState<SearchSelections>()

  const [maxGuestsMessage, setMaxGuestsMessage] = useState<string | null>(null);

  const [formError, setFormError] = useState<{ key: string; value: string }>();
  const [depAirVal, setDepAirVal] = useState<{ valid: boolean; message: string }>();
  const [destVal, setDestVal] = useState<{ valid: boolean; message: string }>();
  const [dateVal, setDateVal] = useState<{ valid: boolean; message: string }>();
  const [paxVal, setPaxVal] = useState<{ valid: boolean; message: string }>();

  const [ready, setReady] = useState<boolean>(false);

  const [searchAtt, setSearchAtt] = useState<boolean>(false);

  const navigate = useNavigate();

  const query = useLocationQuery();


  function setZIndex(elem: string) {
    const child = document.getElementById(elem);

    if(child == null){
      return
    }

    const parentWithClass = child.closest('.stack') as HTMLElement;
    if(parentWithClass == null){
      return
    }

    parentWithClass.style.zIndex = '999';
  }

  function handleOptionSelection(searchType: number) {

    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;

    setDepAirVal({valid: true, message: ''});
    setDestVal({valid: true, message: ''});
    setPaxVal({valid: true, message: ''});
    setDateVal({valid: true, message: ''});

    setSearchAtt(false);
    // setSearchType(searchType);
    setFormError(undefined);

 
    sp.searchType = searchType;
    


 

    if(searchType == 1 || searchType == 3){
      sp.duration = 3;
    }
    else{
      sp.duration = 7;
    }

    sp.destinations.length = 0;
    sp.regions.length = 0;
    sp.resorts.length = 0;
    sp.departurePoints.length = 0;
    sp.departureDate = null;
    sp.departureDateType = 0;
    sp.rooms = [{adults: 2, children: 0, childAges: []}];
    sessionStorage.removeItem('roomSelections');


    setSelectedSearchParams(sp);

    setStorage(sp);
  }

  if(selectedSearchParams == null){
    return null;
  }


  const setStorage = (selectedParams: SearchSelections) => {
    localStorage.setItem('prevsrch',JSON.stringify(selectedParams));
    localStorage.setItem('dos',dayjs().toString());
  }

  function handleDeparturePointParams(selectedValues:string[]){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.departurePoints = selectedValues;
    setSelectedSearchParams(sp);
    validateInputs(false);
    setStorage(sp);
  }

  function handleDestinationParams(selectedDestinatons: number[], selectedRegions: number[], selectedResorts: number[]){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.destinations = selectedDestinatons;
    sp.regions = selectedRegions;
    sp.resorts = selectedResorts;
    setSelectedSearchParams(sp);
    validateInputs(false);
    setStorage(sp);
  }

  function handleDurationParam(option:any){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.duration = option
    setSelectedSearchParams(sp);
    setStorage(sp);
  }

  function handleDateParam(selectedValues: string | null){

 
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.departureDate = selectedValues;
    sp.departureDateType = 0;
    setSelectedSearchParams(sp);
    validateInputs(false);
    setStorage(sp);
  }

  function handleDateMonthParam(selectedValues: string | null){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.departureDate = selectedValues;
    sp.departureDateType = 1;
    setSelectedSearchParams(sp);
    setStorage(sp);
  }


  function handleRoomsParam(selectedValues: SearchSelectionRoom[]){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.rooms = selectedValues;
    setSelectedSearchParams(sp);
    setStorage(sp);
  }

  const beachOption = () => {
    return <div
        className={cn('search-option', {
          'search-option--active': selectedSearchParams.searchType === 0,
        })}
        role='button'
        onClick={() => handleOptionSelection(0)}
      >
        <img src={flightHotelIcon} alt='Beach icon' width={25} />
        <p>Flight & Hotel</p>
      </div>
  }
  const cityOption = () => {
    return  <div
    className={cn('search-option', {
      'search-option--active': selectedSearchParams.searchType === 1,
    })}
    role='button'
    onClick={() => handleOptionSelection(1)}
  >
    <img src={cityIcon} alt='City icon' width={25} />
    <p>City Breaks</p>
  </div>
  }

  const pakOption = () => {
      return <div
      className={cn('search-option', {
        'search-option--active': selectedSearchParams.searchType === 2,
      })}
      role='button'
      onClick={() => handleOptionSelection(2)}
    >
      <img src={packageIcon} alt='Package icon' width={25} />
      <p>Package Holidays</p>
    </div>
  }  

  const pakBeachOption = () => {
    return <div
    className={cn('search-option', {
      'search-option--active': selectedSearchParams.searchType === 4,
    })}
    role='button'
    onClick={() => handleOptionSelection(2)}
  >
    <img src={packageIcon} alt='Package icon' width={25} />
    <p>Beach Holidays</p>
  </div>
}

  const pakCityOption = () => {
    return <div
    className={cn('search-option', {
      'search-option--active': selectedSearchParams.searchType === 3,
    })}
    role='button'
    onClick={() => handleOptionSelection(3)}
  >
    <img src={cityIcon} alt='Package icon' width={25} />
    <p>City Breaks</p>
  </div>
}


  const handleOnSearchClick = async () => {
    
    setSearchAtt(true);

    const valDepAir = {valid: true, message: ''};
    const valDest = {valid: true, message: ''};
    const valDate = {valid: true, message: ''};
    const valPax = {valid: true, message: ''};
    let valid = true;

    if (Object.keys(selectedSearchParams).length > 0 && selectedSearchParams.rooms) {
      const { rooms } = selectedSearchParams;
      // Loop through each room object and add the number of adults and children together
      // If the total is greater than 4 then set valid to false
      rooms.forEach((room: any) => {
        if (room.adults + room.children > 5) {
          valid = false;
        }
      });
    }
    


    if (!valid) {
      setMaxGuestsMessage(
        'You may only have a maximum of 5 guests per room. Please amend your search and try again.',
      );
    } else {

      const strSelectedSearchParams = JSON.stringify(selectedSearchParams)

      if (!isSearchPage && !isHolidayPage) {
  
        clearSessionKeys(['f', 'aprm', 'js1', 'js2', 'js3', 'js4', 'js5', 'shosnt', 'gap']);
      }

      sessionStorage.removeItem('snpOff');

      const rooms = selectedSearchParams.rooms;
      let adults = 0;
      let children = 0;
      for(const x of rooms as any){
        adults += x.adults;
        children += x.children;
      }

      let strDepDate = '';
      if(selectedSearchParams.departureDate != null){
        const depDateObj = dayjs(selectedSearchParams.departureDate)
        strDepDate = depDateObj.format('YYYY-MM-DD');
      }

      const selectedDeparturePoints:any[] = []
      if(selectedSearchParams.departurePoints != null){
        for(const a of selectedSearchParams.departurePoints){
          selectedDeparturePoints.push(a);
        }
      }
      const d: number[] = [];
      const g: number[] = [];
      const s: number[] = [];
      if(selectedSearchParams.destinations != null){
        for(const x of selectedSearchParams.destinations){
            d.push(Number(x));
        }
      }

      if(selectedSearchParams.regions != null){
        for(const x of selectedSearchParams.regions){
          g.push(Number(x));
        }
      }

      if(selectedSearchParams.resorts != null){
        for(const x of selectedSearchParams.resorts){
          s.push(Number(x));
        }
      }

      const valid = validateInputs(true);

      const strRoom = [];
      for(const x of selectedSearchParams.rooms){
        const cas: any[] = [];
        for(const ca of x.childAges){
          cas.push(ca)
        }
        strRoom.push(x.adults + '-' + cas.join(','))
      }

      const qsRooms = strRoom.join('_')
      
      let queryString = 'rooms=' + qsRooms + '&duration=' + selectedSearchParams.duration;

      if(strDepDate != ''){
        queryString += '&departing=' + strDepDate;
        queryString += '&departingType=' + selectedSearchParams.departureDateType;
      }

      if(s.length > 0){
        queryString += '&resorts=' + s.join('-'); 
      }

      if(g.length > 0){
        queryString += '&regions=' + g.join('-'); 
      }
      if(d.length > 0){
        queryString += '&destinations=' + d.join('-'); 
      }

      if(selectedDeparturePoints.length > 0){
        queryString += '&departurePoints=' + selectedDeparturePoints.join('-'); 
      }

      queryString += '&type=' + selectedSearchParams.searchType; 


      sessionSetSearchParams(selectedSearchParams);

      const da: number[] = [];

      for(const x of selectedSearchParams.departurePoints){
        const dp = departurePointIdFromCode(x);
        if(dp == null){
          continue;
        }
        da.push(dp);
      }

      searchAdd(da, d, g, s, selectedSearchParams.duration, adults, children, rooms.length, strDepDate, '', selectedSearchParams.searchType, userToken);

  

      if (selectedSearchParams.searchType === 2 || selectedSearchParams.searchType === 3) {
        
        if (valid) {
          // Create a session before moving on (different from cache)
          const session = await createUserSession(userToken, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 2, undefined, agent);
          queryString += '&session=' + session;

          if (process.env.REACT_APP_BUILD_TARGET === 'widget') {
            let strAgent = '';
            if(agent != -1){
              strAgent = '&agt=' + agent;
            }
            window.location.href = `${configuration.search_url}/package-search?${queryString}${strAgent}&clrPrvSrch=1`;
          } else {
            navigate(`/package-search?${queryString}`);
          }

          if(onClick){
            onClick();
          }

        }
      } else {
        if(valid){
          const sessionRef = crypto.randomUUID();
          queryString += '&sort=0'; 
          if (process.env.REACT_APP_BUILD_TARGET === 'widget') {
            let strAgent = '';
            if(agent != -1){
              strAgent = '&agt=' + agent;
            }
            window.location.href = `${configuration.search_url}/search?${queryString}${strAgent}&clrPrvSrch=1&session=${sessionRef}`;
          } else {
            navigate(`/search?${queryString}&session=${sessionRef}`);
          }

          if(onClick){
            onClick();
          }
          
        }
      }
    }
  };




  const validateInputs = (searchAttempt: boolean) => {
    let valid = true;

    // const params = {
    //   ...selectedSearchParams,
    //   // ...parsedSearchParams,
    //   type: state.option,
    // };

    const valDepAir = {valid: true, message: ''};
    const valDest = {valid: true, message: ''};
    const valDate = {valid: true, message: ''};
    const valPax = {valid: true, message: ''};

    if (selectedSearchParams.searchType === 2 || selectedSearchParams.searchType === 3) {

      // Package specific validations
      if (!selectedSearchParams.departurePoints || selectedSearchParams.departurePoints?.length === 0) {
        valid = false;
        valDepAir.valid = false;
        valDepAir.message = 'Please select an airport';
      }
      else{
        valDepAir.valid = true;
        valDepAir.message = '';
      }

      if (selectedSearchParams.regions.length == 0 && selectedSearchParams.resorts.length == 0) {
        valid = false;
        valDest.valid = false;
        valDest.message = 'Please select a destination';
      }
      else{
        valDest.valid = true;
        valDest.message = '';
      }

      if (!selectedSearchParams.departureDate) {
        valid = false;
        valDate.valid = false;
        valDate.message = 'Please select a date';
      }else{
        valDate.valid = true;
        valDate.message = '';
      }
  }

  // Always validate children ages (for any type of search)
  if (selectedSearchParams.rooms) {
  let noChildAge = 0;
  const rooms = selectedSearchParams.rooms as any;
  for(const x of rooms){
    const childAgesArray = x.childAges;
    for(const ca of childAgesArray){
      if(Number(ca) < 0){
        noChildAge++;
        break;
      }
    }
  }
  if(noChildAge > 0){
    valid = false;
    valPax.valid = false
    if(noChildAge == 1){
      valPax.message = 'Please select your childs age on return';
    }
    else{
      valPax.message = 'Please select your childrens ages on return';
    }
  }
  else{
    valPax.valid = true
    valPax.message = '';
  }
  }
  


  if(searchAtt || searchAttempt){
    setDepAirVal(valDepAir);
    setDestVal(valDest);
    setPaxVal(valPax);
    setDateVal(valDate);
  }

  return valid
}

  let rooms = JSON.stringify(['2']);
  let numberOfRooms = 1;
  if (selectedSearchParams) {
    if (selectedSearchParams.rooms) {
      rooms = JSON.stringify(selectedSearchParams.rooms);
      numberOfRooms = selectedSearchParams.rooms.length;
    }
  }

  // const availableParams =
  //   Object.keys(parsedSearchParams).length > 0 ? parsedSearchParams : selectedSearchParams;

  setZIndex('root');

  const sOps = [];
  // if(process.env.REACT_APP_BUILD_TARGET === 'widget' && clSearchType != -1){
  //   if(clSearchType == 0 || clSearchType == -1){
  //     // All options please
  //     sOps.push(beachOption())
  //     sOps.push(cityOption())
  //     sOps.push(pakOption())

  //   }
  //   else if(clSearchType == 3){ 
  //     sOps.push(beachOption())
  //     sOps.push(cityOption())
  //   }
  // }
  // else{
  //   if(clSearchType == 0 || clSearchType == -1){
  //     // All options please
  //     sOps.push(beachOption())
  //     sOps.push(cityOption())
  //     sOps.push(pakOption())

  //   }
  //   else if(clSearchType == 3){ 
  //     sOps.push(beachOption())
  //     sOps.push(cityOption())
  //   }
  // }


  sOps.push(beachOption())
  sOps.push(cityOption())
  sOps.push(pakOption())

  if(!showSearchTabs){
    sOps.length = 0;
  } 


  else if(agent != -1){
    sOps.length = 0;
    sOps.push(pakOption())
    sOps.push(pakCityOption())
  }


  if(configuration.show_search_tabs != null){
    if(configuration.show_search_tabs != -1){
      if(configuration.show_search_tabs == 0){
        sOps.length = 0;
      }
    }
  }
  
  if(packageOptions){
    sOps.length = 0;
    sOps.push(pakOption())
    sOps.push(pakCityOption())  
  }





  let buttonIco = search;
  const buttonIcoMob = search;

  if(configuration.button_icon == 'dark'){
    buttonIco = searchDark;
  }

  buttonIco = searchDark;

  if(!ready){

    const loader =<><div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {LoaderSpinner()}</div></>;

    return loader;
  }

  let mTop = '10px';
  if(!showInnnerLabels){
    mTop = '35px';
  }


  let btnExClass = ''
  if(!showInnnerLabels){
    btnExClass = 'btnExt'
  }

  let buttonBorderColor = configuration.button_colour;
  let buttonTextColor = 'black';
  if(configuration.search_style != null){
    if(configuration.search_style.button_border != null){
      buttonBorderColor = configuration.search_style.button_border;
    }

    if(configuration.search_style.icon_dark != null){
      if(configuration.search_style.icon_dark == true){
        buttonIco = searchDark;
      }
      else{
        buttonIco = search;
      }
    }

    if(configuration.search_style.button_text_colour != null){
      buttonTextColor = configuration.search_style.button_text_colour;
    }

  }


  return (
    <>
      {!isBoardType && !isSearchPage && sOps.length > 0 && (
        <div className='search-options'>
            {sOps}
        </div>
      )}

      <div className='search-bar-container'>
        <div className='collapsed-search-options'>

          <AirportsSelect
            validationError={depAirVal}
            clickApplyHandler={handleDeparturePointParams}
            accommodationId={accommodationId}
            clickedSearchButtonItemName={searchItemType}
            selectedSearchParams={selectedSearchParams}
            showIcons={true}
            showInnnerLabels={showInnnerLabels}
            
          />

          {!isBoardType && (
            <>
              <DestinationSelect
                validationError={destVal}
                clickApplyHandler={handleDestinationParams}
                clickedSearchButtonItemName={searchItemType}
                selectedSearchParams={selectedSearchParams}
                showIcons={true}
                showInnnerLabels={showInnnerLabels}
              />
            </>
          )}
          {!isBoardType && (
            <Datepicker
              validationError={dateVal}
              selectedParams={selectedSearchParams}
              clickedSearchButtonItemName={searchItemType}
              clickApplyHandler={handleDateParam}
              clickApplyHandlerMonth={handleDateMonthParam}
              searchItemType={searchItemType}
              showIcons={true}
              showInnnerLabels={showInnnerLabels}
            />
          )}

          <DurationSelect
            clickApplyHandler={handleDurationParam}
            clickedSearchButtonItemName={searchItemType}
            selectedSearchParams={selectedSearchParams}
            accommodationId={accommodationId}
            showIcons={true}
            showInnnerLabels={showInnnerLabels}
          />

          {isBoardType && (
            <BoardTypeSelect
              selectedSearchParams={selectedSearchParams}
              accommodationId={accommodationId}
              clickApplyHandler={() => {return}}
              showIcons={isBoardType}
            />
          )}

          <RoomsSelect
            validationError={paxVal}
            clickApplyHandler={(value) => {
              if (value) {
                handleRoomsParam(value);
              }

              if (maxGuestsMessage) {
                setMaxGuestsMessage(null);
              }
            }}
            clickedSearchButtonItemName={searchItemType}
            accommodationId={accommodationId}
            selectedSearchParams={selectedSearchParams}
            showIcons={true}
            roomNumber={numberOfRooms}
            showInnnerLabels={showInnnerLabels}
          />

          {!isBoardType && (

                    <>
                    <span className={'btn btnsearch ' + btnExClass}>
                    <Button label='SEARCH' borderColor={buttonBorderColor}  
                    backgroundColor={configuration.button_colour} isSearchIcon icon={buttonIco} 
                    color={buttonTextColor} primary onClick={handleOnSearchClick} iconBefore={true} />
                    </span>

                    <span className='btn--mob btnsearch'>
                    <Button label='SEARCH' borderColor={buttonBorderColor}  
                    backgroundColor={configuration.button_colour} isSearchIcon icon={buttonIco} 
                    color={buttonTextColor} primary onClick={handleOnSearchClick} iconBefore={true}  />
                    </span></>
      
          )}
        </div>
      </div>

      {maxGuestsMessage && <p className='search-bar-error'>{maxGuestsMessage}</p>}
    </>
  );
};

export default SearchBar;

import { getDestination} from '../geo_d';
import  {getRegion } from '../geo_g';
import { getResort } from '../geo_s';


import {geoDeparturePoints} from '../geo_da';

export function destinationFromID(destinatiomID){
    return getDestination(Number(destinatiomID));
}

export function regionFromID(regionID){
    return getRegion(Number(regionID));
}

export function resortFromID(resortID){
    return getResort(Number(resortID));
}

export function departurePointIdFromCode(code){
    code = code.toUpperCase()
    const depPoints = geoDeparturePoints()
    for(const x of depPoints){
        if(x.code == code){
            return x.id;
        }
    }
    return null;
}

export function departurePointFromCode(code){
    code = code.toUpperCase()
    const depPoints = geoDeparturePoints()
    for(const x of depPoints){
        if(x.code == code){
            return x.name;
        }
    }
    return 'Unknown'
}

export function geoArrayFromString(str){
    const d = str.split('-');
    const arr = [];
    for(const x of d){
        arr.push(Number(x));
    }
    return arr;
}

export function departurePointArrayFromString(str){
    const d = str.split('-');
    const arr = [];
    for(const x of d){
        arr.push(x.toUpperCase());
    }
    return arr;
}
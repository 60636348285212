import dayjs from 'dayjs';
import { getSession, sessionNew } from '../hooks/useRequest';


export interface IUserSession {
  sessionId: string;
}

export class SessionStore {

  constructor() {
    // makeObservable(this, {
    //   userSession: observable,
    //   setUserSession: action,
    // });
    // sessionId: String




  }

  userSession: IUserSession = {
    sessionId: '',
  };

  setUserSession = (sessionId: string) => {
    this.userSession.sessionId = sessionId;
  };

  /**
   * Retrieve a session
   */
  getUserSession = async (usertoken: string, sessionId: string) => {
    if (sessionId) {
      const res = await getSession(usertoken, sessionId);

      if (res?.session?.result?.session) {
        const result = res?.session.result;

        this.userSession.sessionId = result.session;
        // runInAction(() => {
        //   this.userSession = {
        //     sessionId: result.session,
        //     ...result,
        //   };
        // });
        return result.session;
      }
      else{
        return null;
      }
    }
  };

  /**
   * Create a session
   */
  createUserSession = async (userToken: string, accommodation_id? :number, departureCode?: string, arrivalCode?: string, 
    departing?: string, duration?: number, board?: number, rooms?: string, tripType?: number, customVar?: string, agent_id?: number) => {
    const res = await sessionNew(userToken, accommodation_id, departureCode, arrivalCode, departing, duration, board, rooms, tripType, customVar, agent_id);

    if (res?.session_new?.result?.session) {
      const id = res?.session_new?.result?.session;
      sessionStorage.setItem('session_id', id);
      sessionStorage.setItem('session_cr', dayjs().toISOString())
      this.userSession.sessionId = id;
      return id;
    } else {
      return null;
    }
  };
}

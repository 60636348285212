import React, { FunctionComponent, useEffect, useState, ReactNode } from 'react';
import './DestinationSelector.scss';

import {
    availableResorts,
    availableAccommodation
  } from '../../hooks/useRequest';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { indexOf } from 'lodash';

import { Button } from '../Button';

import tick from '../../components/assets/tick.svg';
import hotelico from '../../components/assets/hotel.svg';

import { Modal } from '../Modal';

import { useNavigate } from 'react-router-dom';

import { toTitleCase } from '../../services/str';

export const LoaderSpinner = () => {

    const {
      RootStore: {
        configuration,
      },
    } = useStores() as { RootStore: RootStore };
  
  
    let bs = configuration.button_colour;
    if(configuration.spec == '6yk1y'){;
        bs = '#01447b';
    }
  
    return (
      <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
        <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
      </div>
    );
  };


// export interface OptionObj {
//     value: string | number | string[];
//     label: string | number;
//     type?: string;
//     isChecked?: boolean;
//   }

interface DestinationSelectorProps {
    isOpen: boolean;
    rooms: string, 
    selectedDeparturePoints: string[], 
    duration: number, 
    boards: number[], 
    ratings: number[], 
    tripRatings: number[], 
    departureDate: any, 
    departureType: any,
    tripType: any,
    selectedDestinations: number[],
    selectedRegions: number[],
    selectedResorts: number[],
    onClose: () => void;
    onChange: (selectedDestinations: number[], selectedRegions: number[], selectedResorts: number[]) => void;
  }
  
  const DestinationSelector: FunctionComponent<DestinationSelectorProps> = ({
    isOpen = false,
    rooms, 
    selectedDeparturePoints, 
    duration=7, 
    boards, 
    ratings, 
    tripRatings, 
    departureDate=null, 
    departureType=0,
    tripType=0,
    selectedDestinations=[],
    selectedRegions=[],
    selectedResorts=[],
    onClose,
    onChange
  }) => {
    const navigate = useNavigate();

    const {
        RootStore: {
          userToken,
          configuration,
        },
      } = useStores() as { RootStore: RootStore };

    useEffect(() =>{
        setSelectedD(selectedDestinations);
    }, [selectedDestinations])
    useEffect(() =>{
        setSelectedG(selectedRegions);
    }, [selectedRegions])
    useEffect(() =>{
        setSelectedS(selectedResorts);
    }, [selectedResorts])

    const [options, setOptions] = useState<any>(null);
    const [lastRun, setLastRun] = useState<any>(null);
    // const [selectedOptions, setSelectedOptions] = useState<OptionObj[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [isFetching, setIsFetching] = useState(false);


    const [selectedSessionDest, setSelectedSessionDest] = useState<any>([]);
    const [selectedSessionRegion, setSelectedSessionRegion] = useState<any>([]);
    const [selectedSessionResort, setSelectedSessionResort] = useState<any>([]);

    const [selectedTopsDest, setSelectedTopsDest] = useState<any>([]);
    const [selectedTopsRegion, setSelectedTopsRegion] = useState<any>([]);
    const [selectedTopsResort, setSelectedTopsResort] = useState<any>([]);

    const [removedSessionDest, setRemovedSessionDest] = useState<any>([]);
    const [removedSessionRegion, setRemovedSessionRegion] = useState<any>([]);
    const [removedSessionResort, setRemovedSessionResort] = useState<any>([]);



    const [selectedD, setSelectedD] = useState<number[]>(selectedDestinations);
    const [selectedG, setSelectedG] = useState<number[]>(selectedRegions);
    const [selectedS, setSelectedS] = useState<number[]>(selectedResorts);


    useEffect(() =>{



    const xEl = document.getElementById('cloudlink-chat-overlay-buttons');
    if(xEl != null){
        if(isOpen){
        
            if(xEl != null){
            xEl.style.display = 'none';
            }
        }
        else{
            if(xEl != null){
                xEl.style.display = 'block';
            }
        }
    }

        if(!isOpen){
            setSearchValue('');


            setSelectedSessionDest([]);
            setSelectedSessionRegion([]);
            setSelectedSessionResort([]);

            setRemovedSessionDest([]);
            setRemovedSessionRegion([]);
            setRemovedSessionResort([]);

            return
        }

        const sr = ratings.join('-');
        const str = tripRatings.join('-');
        const sbb = boards.join('-');
        const sdp = selectedDeparturePoints.join('-');


        const params = { rooms, duration, boards: sbb, ratings: sr, 
            tripRatings: str, departureDate, departureType, tripType, selectedDeparturePoints : sdp }

 
        if(lastRun != null){
            if(lastRun.rooms == rooms && lastRun.selectedDeparturePoints == sdp && lastRun.duration == duration && lastRun.boards == sbb
                && lastRun.ratings == sr &&  lastRun.departureDate == departureDate && lastRun.tripRatings == str && lastRun.departureType == departureType && lastRun.tripType == tripType){
                return;
            }
        }

        setIsFetching(true);

        availableAccommodation(userToken, rooms, selectedDeparturePoints, duration, boards, ratings, tripRatings, departureDate, departureType, tripType).then((d) => {
            setOptions(d.available_accommodation.result);
            setLastRun(params)
            setIsFetching(false);
        })

    }, [isOpen])

    const handleCloseButtonClick = () => {

        setSelectedSessionDest([]);
        setSelectedSessionRegion([]);
        setSelectedSessionResort([]);

        setSelectedTopsDest([]);
        setSelectedTopsRegion([]);
        setSelectedTopsResort([]);

        setRemovedSessionDest([]);
        setRemovedSessionRegion([]);
        setRemovedSessionResort([]);

        onClose();
    }

    const clearType = (input: any) => {
        input.value = '';
        setSearchValue('');
    }

    const goToAcc = (acc: any) => {

        if (process.env.REACT_APP_BUILD_TARGET === 'widget') {
            window.location.href = `${configuration.search_url}/flight-and-hotel/${acc.destination.replaceAll(' ', '-').toLowerCase()}/${acc.region.replaceAll(' ', '-').toLowerCase()}/${acc.resort.replaceAll(' ', '-').toLowerCase()}/${acc.name.replaceAll(' ', '-').toLowerCase()}/${acc.id}`
        }
        else{
            navigate(`/flight-and-hotel/${acc.destination.replaceAll(' ', '-').toLowerCase()}/${acc.region.replaceAll(' ', '-').toLowerCase()}/${acc.resort.replaceAll(' ', '-').toLowerCase()}/${acc.name.replaceAll(' ', '-').toLowerCase()}/${acc.id}`);
        }
      
    }

    const handleCancelButtonClick = () => {
        const xa = selectedDestinations;
        const newxa = [];
        for(const sd of xa){
            let w = true;
            for(const x of selectedTopsDest){
                if(sd == x){
                    w = false;
                    break;
                }
            }
            if(w){
                newxa.push(sd);
            }
        }

        for(const sd of removedSessionDest){
            newxa.push(sd);
        }

        const xag = selectedRegions;
        const newxag = [];
        for(const sd of xag){
            let w = true;
            for(const x of selectedTopsRegion){
                if(sd == x){
                    w = false;
                    break;
                }
            }
            if(w){
                newxag.push(sd);
            }
        }

        for(const sd of removedSessionRegion){
            newxag.push(sd);
        }

        const xas = selectedResorts;
        const newxas = [];
        for(const sd of xas){
            let w = true;
            for(const x of selectedTopsResort){
                if(sd == x){
                    w = false;
                    break;
                }
            }
            if(w){
                newxa.push(sd);
            }
        }

        for(const sd of removedSessionResort){
            newxas.push(sd);
        }
        onChange(newxa, newxag, newxas)

        setSelectedSessionDest([]);
        setSelectedSessionDest([]);
        setSelectedSessionDest([]);

        setSelectedTopsDest([]);
        setSelectedTopsRegion([]);
        setSelectedTopsResort([]);

        setRemovedSessionDest([]);
        setRemovedSessionRegion([]);
        setRemovedSessionResort([]);

        onClose();

    }

    const setAny = () => {

        const xa = selectedDestinations;
        const rs = removedSessionDest
        for(const x of xa){
            rs.push(x);
        }
        setRemovedSessionDest(rs);

        const xag = selectedRegions;
        const rsg = removedSessionDest
        for(const x of xag){
            rsg.push(x);
        }
        setRemovedSessionRegion(rsg);

        const xas = selectedResorts;
        const rss = removedSessionDest
        for(const x of xas){
            rss.push(x);
        }
        setRemovedSessionResort(rss);


        onChange([], [], [])
    }

    const selectDestination = (id: number) => {
        const xa = selectedDestinations;
        const xag = selectedRegions;
        const xas = selectedResorts;

        const ss = selectedSessionDest;
        const rs = removedSessionDest
        const st = selectedTopsDest;

        const ie = xa.indexOf(id);
        if(ie!= -1){
            xa.splice(ie , 1)
        }
        else{
            xa.push(id);
            if(searchValue.trim() == ''){
                ss.push(id);
            }
            st.push(id);
        }
        onChange(xa, xag, xas)
        setSelectedSessionDest(ss);
        setRemovedSessionDest(rs);
        setSelectedTopsDest(st);

        setSelectedD(xa);
    }

    const selectRegion = (id: number) => {
        const xa = selectedDestinations;
        const xag = selectedRegions;
        const xas = selectedResorts;

        const ss = selectedSessionRegion;
        const rs = removedSessionRegion;
        const st = selectedTopsRegion;

        const ie = xag.indexOf(id);
        if(ie!= -1){
            xag.splice(ie , 1)
        }
        else{
            xag.push(id);
            if(searchValue.trim() == ''){
                ss.push(id);
            }
            st.push(id);
        }
        onChange(xa, xag, xas)
        setSelectedSessionDest(ss);
        setRemovedSessionDest(rs);
        setSelectedTopsDest(st);

        setSelectedG(xag);
    }

    const selectResort = (id: number) => {
        const xa = selectedDestinations;
        const xag = selectedRegions;
        const xas = selectedResorts;

        const ss = selectedSessionResort;
        const rs = removedSessionRegion;
        const st = selectedTopsRegion;

        const ie = xas.indexOf(id);
        if(ie!= -1){
            xas.splice(ie , 1)
        }
        else{
            xas.push(id);
            if(searchValue.trim() == ''){
                ss.push(id);
            }
            st.push(id);
        }
        onChange(xa, xag, xas)
        setSelectedSessionDest(ss);
        setRemovedSessionDest(rs);
        setSelectedTopsDest(st);

        setSelectedS(xas);
    }



    if(!isOpen){
        return null;
    }

    let bc = configuration.button_colour;

    let titleColour =  configuration.button_colour;
    let cancelText = configuration.button_colour;
    let primButtonColour = configuration.button_colour;
    if(configuration.button_icon == 'dark'){
      bc = configuration.border_colour_2;
      titleColour =  configuration.border_colour_2;
    }

    let selStyle = {backgroundColor: configuration.button_colour, borderColor:configuration.border_colour_2};
  
    if(configuration.spec == '6yk1y'){
      primButtonColour = configuration.button_colour;
      cancelText = '#01447b';
      bc = '#01447b';
      titleColour =  '#01447b';
      selStyle = {backgroundColor: '#01447b', borderColor:configuration.border_colour_2};
    }

    const modalHeader: ReactNode = (
        <div className='dest-header-section'>
          <h5 style={{color: configuration.text_colour}} className='dest-header-section-text'>Destinations</h5>
          <span className="dest-header-section-close" onClick={() => handleCloseButtonClick()} role="button"></span>
        </div>
      );

    const modalFooter: ReactNode = (
    <div className='dest-footer-section'>
        <Button label='Cancel'  backgroundColor={primButtonColour} isTransparent onClick={() => handleCancelButtonClick()} />
        <Button label='Apply' backgroundColor={primButtonColour} borderColor={primButtonColour} primary onClick={() => handleCloseButtonClick()} />
    </div>
    );
    

    const loader =<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div><div className='destsel-mobile-view'><div className='datesel-mobile-con'>{modalHeader}<div style={{display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div></div></>;

    if(isFetching){
        return(loader);
    }

    if(options == null){
        return(loader);
    }

    const destinations: any[] = [];
    const accommoadtions: any[] = [];

    const capturedDest = [];
    const htmlDests = [];

    const htmlSelDests =[];

    for(const x of options){
        const seg = x.title.split('-');
        if(seg.length > 8){
            continue;
        }

        if(indexOf(capturedDest, seg[6]) > -1){
            continue
        }

        capturedDest.push(seg[6]);
        destinations.push({'id': Number(seg[6]), 'name': seg[7], 'type': 'c', 'hide': false, 'regions': []});
        
    }

    const capturedRegions = []
    for(const x of options){
        const seg = x.title.split('-');
        if(seg.length > 8){
            continue;
        }

        if(indexOf(capturedRegions, seg[4]) > -1){
            continue
        }

        for(const d of destinations){
            if(seg[6] == d.id){
                d.regions?.push({'id': Number(seg[4]), 'name': seg[5], 'type': 'g', 'hide': false, 'resorts': []})
                capturedRegions.push(seg[4]);
            }
        }
    }

    const capturedResorts = []
    for(const x of options){
        const seg = x.title.split('-');
        if(seg.length > 8){
            continue;
        }

        if(indexOf(capturedResorts, seg[2]) > -1){
            continue
        }

        for(const d of destinations){
            for(const g of d.regions){
                if(seg[4] == g.id){
                    g.resorts?.push({'id': Number(seg[2]), 'name': seg[3], 'type': 's', 'hide': false})
                    capturedResorts.push(seg[2]);
                }
            }
        }
    }

    for(const x of options){
        const seg = x.title.split('-');
        if(seg.length > 8){
            continue;
        }
        accommoadtions.push({'id': seg[0], 'name': seg[1], 'type': 'a', 'hide': false, 'destination': seg[7], 'region': seg[5], 'resort': seg[3]})
    }

    accommoadtions.sort((a, b) => {

        if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
        }
        
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    })

    destinations.sort((a, b) => {

        if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
        }
        
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    })

    for(const x of destinations){
        x.regions.sort((a: any, b: any) => {
            if(a.name.toLowerCase() < b.name.toLowerCase()){
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        })
    }   
    
    for(const x of destinations){
        for(const g of x.regions){
            g.resorts.sort((a: any, b: any) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()){
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            })
        }
    }

 

    const chkStyle = {width: '24px', height: '24px', borderStyle:'solid', borderWidth: '1px', borderRadius: '2px', cursor: 'pointer', borderColor: '#99b4ca'};

    const lblStyle = {marginLeft: '10px', cursor:'pointer', color: configuration.text_colour};

    const chkInputStyle = {height: '0px', width: '0px', display: 'none'};
    const optionDivStyle = {paddingTop: '12px', paddingBottom: '12px', display:'flex', alignItems:'center'}


    const spanSelected = <span style={{display: 'flex', width: '24px', height: '24px', color: 'white', alignItems: 'center', justifyContent: 'center'}}><img src={tick} style={{color: 'white'}} height='20' width='20' /></span>


    if(searchValue.trim() == ''){
        let isAnySelected = false;
        let anyTick = null;
        if(selectedResorts.length == 0 && selectedRegions.length == 0 && selectedDestinations.length == 0){
            isAnySelected = true;
            anyTick = spanSelected
        }
        htmlDests.push(<div style={optionDivStyle}>
            <input onChange={(e) => {setAny()}} style={chkInputStyle} type="checkbox" id={'chkdstany'} name={'chkdstany'} value={'chkany'} checked={isAnySelected} />
            <label style={chkStyle} htmlFor={'chkdstany'}>{anyTick}</label>
            <label style={lblStyle} htmlFor={'chkdstany'}>{'Any destination'}</label>
        </div>)
    }


    for(const x of destinations){

        let selTick = null;
        let selected = false;
        if(selectedD.indexOf(Number(x.id)) > -1){
            selTick = spanSelected
            selected = true;
        }

        let isSessionSel = false;
        for(const ss of selectedSessionDest){
            if(ss == Number(x.id)){
                isSessionSel = true;
            }
        }

        let destName = toTitleCase(x.name);
        if(destName.toLowerCase() == 'usa'){
            destName = 'USA';
        }


        if(selected && !isSessionSel){
            htmlSelDests.push(<div style={optionDivStyle}>
                <input onChange={(e) => {selectDestination(x.id)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.id} name={'chkdst' + x.id} value={x.id} checked={selected} />
                <label style={chkStyle} htmlFor={'chkdst' + x.id}>{selTick}</label>
                <label style={lblStyle} htmlFor={'chkdst' + x.id}>{destName}</label>
            </div>)
        }
        else{
            if(searchValue != ''){
                const strName = x.name.toLowerCase();
  
                if(strName.indexOf(searchValue.toLowerCase().trim()) > -1){
                    htmlDests.push(<div style={optionDivStyle}>
                        <input onChange={(e) => {selectDestination(x.id)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.id} name={'chkdst' + x.id} value={x.id} checked={selected} />
                        <label style={chkStyle} htmlFor={'chkdst' + x.id}>{selTick}</label>
                        <label style={lblStyle} htmlFor={'chkdst' + x.id}>{destName}</label>
                    </div>)
                }
            }
            else{
                htmlDests.push(<div style={optionDivStyle}>
                        <input onChange={(e) => {selectDestination(x.id)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.id} name={'chkdst' + x.id} value={x.id} checked={selected}  />
                        <label style={chkStyle} htmlFor={'chkdst' + x.id}>{selTick}</label>
                        <label style={lblStyle} htmlFor={'chkdst' + x.id} >{destName}</label>
                </div>)
            }
        }

        // if(searchValue == ''){
        //     continue
        // }

        for(const g of x.regions){

            let selTickRegion = null;
            let selectedReion = false;
            if(selectedG.indexOf(Number(g.id)) > -1){
                selTickRegion = spanSelected
                selectedReion = true;
            }


            let isSessionSelRegion = false;
            for(const ss of selectedSessionRegion){
                if(ss == Number(g.id)){
                    isSessionSelRegion = true;
                }
            }

            let regioName = toTitleCase(g.name);

            if(selectedReion && !isSessionSelRegion){
   
                    htmlSelDests.push(<div style={optionDivStyle}>
                    <input onChange={(e) => {selectRegion(g.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + g.id} name={'chkg' + g.id} value={g.id} checked={selectedReion} />
                    <label style={chkStyle} htmlFor={'chkg' + g.id}>{selTickRegion}</label>
                    <label style={lblStyle} htmlFor={'chkg' + g.id}>{regioName} <br></br><span style={{fontSize:'10px', color: 'grey'}}>{destName}</span></label>
                </div>)
       
            }
            else{

                if(searchValue != ''){
                    const strName = g.name.toLowerCase();
                    if(strName.indexOf(searchValue.toLowerCase().trim()) > -1){
                        htmlDests.push(<div style={optionDivStyle}>
                        <input onChange={(e) => {selectRegion(g.id)}} style={chkInputStyle} type="checkbox" id={'chkg' + g.id} name={'chkg' + g.id} value={g.id} checked={selectedReion} />
                        <label style={chkStyle} htmlFor={'chkg' + g.id}>{selTickRegion}</label>
                        <label style={lblStyle} htmlFor={'chkg' + g.id}>{regioName} <br></br><span style={{fontSize:'10px', color: 'grey'}}>{destName}</span></label>
                    </div>)
                }
                }
           
            }

            for(const s of g.resorts){

                let selTickResort = null;
                let selectedResort = false;
                if(selectedS.indexOf(Number(s.id)) > -1){
                    selTickResort = spanSelected
                    selectedResort = true;
                }

                let isSessionSelResort = false;
                for(const ss of selectedSessionResort){
                    if(ss == Number(s.id)){
                        isSessionSelResort = true;
                    }
                }

                let resortName = toTitleCase(s.name);
    

                if(selectedResort && !isSessionSelResort){
                    htmlSelDests.push(<div style={optionDivStyle}>
                        <input onChange={(e) => {selectResort(s.id)}} style={chkInputStyle} type="checkbox" id={'chks' + s.id} name={'chks' + s.id} value={s.id} checked={selectedResort} />
                        <label style={chkStyle} htmlFor={'chks' + s.id}>{selTickResort}</label>
                        <label style={lblStyle} htmlFor={'chks' + s.id}>{resortName}<br></br><span style={{fontSize:'10px', color: 'grey'}}>{destName}, {regioName}</span></label>
                    </div>)
                }
                else{
                    if(searchValue != ''){
                        const strName = s.name.toLowerCase();
                        if(strName.indexOf(searchValue.toLowerCase().trim())  > -1){
                            htmlDests.push(<div style={optionDivStyle}>
                                <input onChange={(e) => {selectResort(s.id)}} style={chkInputStyle} type="checkbox" id={'chks' + s.id} name={'chks' + s.id} value={s.id} checked={selectedResort} />
                                <label style={chkStyle} htmlFor={'chks' + s.id}>{selTickResort}</label>
                                <label style={lblStyle} htmlFor={'chks' + s.id}>{resortName}<br></br><span style={{fontSize:'10px', color: 'grey'}}>{destName}, {regioName}</span></label>
                            </div>)
                        }
                    }
               
                }

                // for(const a of s.acc){
                //     if(searchValue != ''){
                //         const strName = a.name.toLowerCase();
                //         if(strName.indexOf(searchValue.toLowerCase().trim())  > -1){
                //             htmlDests.push(<div style={optionDivStyle}>
                //                 <label style={lblStyle}>{a.name}<br></br><span style={{fontSize:'10px', color: 'grey'}}>{x.name}, {g.name} {s.name}</span></label>
                //             </div>)
                //         }

                //     }
                // }


            }
        }
    }

    if(searchValue != ''){
        const foundAcc = accommoadtions.filter(item => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
        if(foundAcc.length > 0){
            let c = 0;
            for(const x of foundAcc){
                if(c > 5){
                    break;
                }

                let destName = toTitleCase(x.destination);
                if(destName.toLowerCase() == 'usa'){
                    destName = 'USA';
                }

                htmlDests.push(<div onClick={() => goToAcc(x)} style={optionDivStyle}>
                    
                    <img src={hotelico} style={{width:'30px', cursor:'pointer'}}></img>
                    <label style={lblStyle}>{x.name}<br></br><span style={{fontSize:'10px', color: 'grey'}}>{destName}, {toTitleCase(x.region)}, {toTitleCase(x.resort)}</span></label>
                    
                    </div>)
                c++;
            }
        }

    }
  

    // for(const x of accommoadtions){
    //                  if(searchValue != ''){
    //                     const strName = x.name.toLowerCase();
    //                     if(strName.indexOf(searchValue.toLowerCase().trim())  > -1){
    //                         htmlDests.push(<div style={optionDivStyle}>
    //                             <label style={lblStyle}>{x.name}<br></br><span style={{fontSize:'10px', color: 'grey'}}>{x.destination}, {x.region} {x.resort}</span></label>
    //                         </div>)
    //                     }

    //     }
    // }

    let inputPlace = 'Search destinations';
    if(selectedDestinations.length > 0 || selectedRegions.length > 0 || selectedResorts.length > 0){
        inputPlace = '+ Add another';
    }

    return(<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
        <div style={{width:'100%'}}>
            <div style={{width:'100%', marginBottom: '10px', border: '1px solid ' + configuration.border_colour_2, borderRadius: '6px'}}>
                <input autoFocus={true} style={{color: configuration.text_colour, border: 'none'}} className='datesel-input' placeholder={inputPlace} value={searchValue} onClick={(e) => clearType(e.currentTarget)} onChange={(e) => setSearchValue(e.currentTarget.value)} type='text'></input>
            </div>
            {htmlSelDests}
            {htmlDests}
        </div>
    </div>
    </div>
    <div className='destsel-mobile-view'>

    <Modal
          isOpen={true}
        >

        <div className='datesel-mobile-con'>{modalHeader}
        <div style={{borderColor: configuration.border_colour_2, display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
        <div style={{width:'100%'}}>
            <div style={{width:'100%', marginBottom: '10px', border: '1px solid ' + configuration.border_colour_2, borderRadius: '6px'}}>
                <input autoFocus={true} style={{color: configuration.text_colour, border: 'none'}} className='datesel-input' placeholder={inputPlace} value={searchValue} onClick={(e) => clearType(e.currentTarget)} onChange={(e) => setSearchValue(e.currentTarget.value)} type='text'></input>
            </div>
            {htmlSelDests}
            {htmlDests}
        </div>
        </div>
        {modalFooter}
    </div>
    </Modal>
    </div></>)



  };

  export default DestinationSelector;